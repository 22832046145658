import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import moment from 'moment';
import history from '@history';
import CircularProgress from '@mui/material/CircularProgress';
import appWebsiteData from '../../query/appWebsite';
import {
  getDomainRequest,
  resetAppWebsite,
  getDomainStatus,
  createCloudfrontDistribution,
} from './store/appWebsiteSlice';
import reducer from './store';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function Users() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const usersData = useSelector(
    ({ appWebsiteReducer }) =>
      appWebsiteReducer?.appWebsite?.getDomainRequestData?.data?.getDomainRequest?.data || ''
  );

  const totalUsers = useSelector(
    ({ appWebsiteReducer }) =>
      appWebsiteReducer?.appWebsite?.getDomainRequestData?.data?.getDomainRequest?.totalCount || 0
  );

  const domainStatus = useSelector(
    ({ appWebsiteReducer }) =>
      appWebsiteReducer?.appWebsite?.getDomainStatusData?.data?.getDomainStatus?.data[0] || []
  );

  const createCloudfrontDistributionStatus = useSelector(
    ({ appWebsiteReducer }) =>
      appWebsiteReducer?.appWebsite?.createCloudfrontDistributionData?.data
        ?.createCloudfrontDistribution || ''
  );

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [publishLoading, setPublishLoading] = useState(false);
  const [publishUserId, setPublishUserId] = useState();
  const [actionValue, setActionValue] = useState('action');
  const [statusValue, setStatusValue] = useState('all');
  const [data, setData] = useState(usersData);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = usersData?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: appWebsiteData.getDomainRequest,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: '',
      },
    };
    dispatch(getDomainRequest(payload));

    const payloadStatus = {
      query: appWebsiteData.getDomainStatus,
    };
    dispatch(getDomainStatus(payloadStatus));

    return () => {
      unmounted = true;
      dispatch(resetAppWebsite());
    };
  }, [dispatch]);

  useEffect(() => {
    if (usersData && usersData?.length) {
      setData(usersData);
    } else {
      setData();
    }
  }, [usersData]);

  useEffect(() => {
    if (usersData || usersData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [usersData]);

  useEffect(() => {
    if (createCloudfrontDistributionStatus && createCloudfrontDistributionStatus.status === 200) {
      enqueueSnackbar(createCloudfrontDistributionStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setPublishLoading(false);
    } else if (
      (createCloudfrontDistributionStatus && createCloudfrontDistributionStatus.status === 400) ||
      createCloudfrontDistributionStatus.status === 401
    ) {
      enqueueSnackbar(createCloudfrontDistributionStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setPublishLoading(false);
    }
  }, [enqueueSnackbar, createCloudfrontDistributionStatus]);

  const commonGetData = async (searchValues = '') => {
    setSearchValue(searchValues);
    const payload = {
      query: appWebsiteData.getDomainRequest,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
    };
    if (statusValue !== 'all') {
      payload.variables.status = statusValue;
    }
    await dispatch(getDomainRequest(payload));
  };

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  function handleActionDropdownChange(event) {
    setActionValue(event.target.value);
  }

  function handleChangePage(event, value) {
    setPage(value + 1);
    const payload = {
      query: appWebsiteData.getDomainRequest,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
      },
    };
    if (statusValue !== 'all') {
      payload.variables.status = statusValue;
    }
    dispatch(getDomainRequest(payload));
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: appWebsiteData.getDomainRequest,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
      },
    };
    if (statusValue !== 'all') {
      payload.variables.status = statusValue;
    }
    dispatch(getDomainRequest(payload));
  }

  function handleStatusDropdownChange(event) {
    const statusData = event.target.value;
    setPage(1);
    setStatusValue(statusData);
    setLoading(true);
    const payload = {
      query: appWebsiteData.getDomainRequest,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: 1,
        searchText: searchValue,
      },
    };
    if (statusData !== 'all') {
      payload.variables.status = statusData;
    }
    dispatch(getDomainRequest(payload));
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }

  function handlePublish(userId) {
    setPublishLoading(true);
    const payload = {
      query: appWebsiteData.createCloudfrontDistribution,
      variables: {
        userId: parseInt(userId, 10),
      },
    };
    dispatch(createCloudfrontDistribution(payload));
  }

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all User',
              }}
            />
          </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon> {t('appWebsites.users.column.srNo')}</TableSortLabel>
          </TableCell>
          {/* <TableCell
              className="text-16 font-medium"
              align="left"
            >
              <TableSortLabel
                hideSortIcon
                direction={order}
                onClick={() => {
                  handleSorting('id');
                }}
              >
                Index
              </TableSortLabel>
            </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.name')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('appWebsites.users.column.businessEmail')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium">
            <TableSortLabel hideSortIcon>{t('appWebsites.users.column.domainName')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium">
            <TableSortLabel hideSortIcon>{t('common.status')}</TableSortLabel>
          </TableCell>
          {/* <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('common.action')}</TableSortLabel>
          </TableCell> */}
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  return (
    <div className="p-20 lg:p-24">
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {t('appWebsites.users.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('navigation.searchName')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon color="action" className="cursor-pointer" onClick={() => handleClearSearch()}>
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end flex">
          <Select
            className="rounded-full border border-darkgreen h-40 bg-white text-16 w-full min-w-192 leading-5 shadow-none capitalize"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
            value={statusValue}
            fullWidth
            onChange={handleStatusDropdownChange}
            IconComponent={() => (
              <Icon className="text-20 align-middle absolute right-14 text-black ml-2">
                filter_list
              </Icon>
            )}
          >
            <MenuItem value="all" className="capitalize">
              {t('menuItems.all')}
            </MenuItem>

            <MenuItem value={domainStatus?.published} className="capitalize">
              {domainStatus?.published}
            </MenuItem>
            <MenuItem value={domainStatus?.request} className="capitalize">
              {domainStatus?.request}
            </MenuItem>
          </Select>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        {usersData?.length > 0 ? (
          <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={usersData?.length}
                />
                <TableBody>
                  {data &&
                    data.length > 0 &&
                    data.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          {/* <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                          {/* <TableCell
                            component="th"
                            className="text-16 color-black font-semibold"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {index + 1}+{rowsPerPage * page}
                          </TableCell> */}
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {(page - 1) * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.users?.name}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.users?.email}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.domainName ? row?.domainName : '-'}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <span
                              className={`${
                                row?.domainPublishStatus === 'published'
                                  ? 'text-green'
                                  : row?.domainPublishStatus === 'request'
                                  ? 'text-red'
                                  : 'text-black'
                              } capitalize`}
                            >
                              {row?.domainPublishStatus ? row?.domainPublishStatus : '-'}
                            </span>
                          </TableCell>
                          {/* <TableCell className="text-16 color-black font-semibold" align="left">
                            <Button
                              variant="contained"
                              color="secondary"
                              className="md:text-16 h-28 px-16 bg-orange-100 text-orange font-semibold rounded hover:text-white disabled:bg-gry-400"
                              aria-label="view"
                              onClick={() => {
                                handlePublish(row?.users?.id);
                                setPublishUserId(row?.users?.id);
                              }}
                              disabled={publishLoading && publishUserId === row?.users?.id}
                              type="button"
                              size="small"
                            >
                              {t('appWebsites.users.publish')}
                              {publishLoading && publishUserId === row?.users?.id && (
                                <CircularProgress
                                  size={24}
                                  className="text-darkgreen absolute mx-auto"
                                />
                              )}
                            </Button>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              {t('appWebsites.users.noUsersMsg')}
            </Typography>
          </motion.div>
        )}
      </div>
      {usersData?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalUsers}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

export default withReducer('appWebsiteReducer', reducer)(Users);
