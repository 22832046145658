import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import { Search, Info, LocationOnOutlined } from '@mui/icons-material';
import PlaceIcon from '@mui/icons-material/Place';
import axios from 'axios';
import {
  Button,
  Typography,
  CircularProgress,
  TextField,
  Switch,
  Box,
  FormControlLabel,
  Checkbox,
  Slider,
  FormGroup,
  Chip,
  IconButton,
  Collapse,
  ListItem,
  List,
  Icon,
} from '@mui/material';
import { handleApiRequest } from 'src/app/main/common/common';
import { selectDashboardData } from 'app/store/userSlice';
import quickAdsQuery from 'src/app/main/query/quickAds';
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  Autocomplete,
} from '@react-google-maps/api';

function AudienceSegmentBudget({
  setActiveStep,
  addedAreas,
  setAddedAreas,
  addedInterests,
  setAddedInterests,
  ageRange,
  setAgeRange,
  selectedDays,
  setSelectedDays,
  timeRange,
  setTimeRange,
  createAndUpdateAdsCampaign,
  gender,
  setGender,
  budgetType,
  refreshIcon,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [interestName, setInterestName] = useState(null);
  const [placeOne, setPlaceOne] = useState(null);
  const [suggestedInterests, setSuggestedInterests] = useState([]);
  const [suggestedArea, setSuggestedArea] = useState([]);
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const lib = ['places'];
  const [currentLocation, setCurrentLocation] = useState([
    { name: 'Pune', lat: 18.5204303, lng: 73.8567437 },
  ]);
  const [autocompleteMap, setAutocompleteMap] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMapFullAddress, setSelectedMapFullAddress] = useState('');
  const [cityLongLat, setCityLongLat] = useState([]);
  const [regionLongLat, setRegionLongLat] = useState([]);
  const [prevCityKeysLength, setPrevCityKeysLength] = useState(0);
  const [prevRegionKeysLength, setPrevRegionKeysLength] = useState(0);

  const totalDay = [
    {
      label: 'Sunday',
      value: 0,
    },
    {
      label: 'Monday',
      value: 1,
    },
    {
      label: 'Tuesday',
      value: 2,
    },
    {
      label: 'Wednesday',
      value: 3,
    },
    {
      label: 'Thursday',
      value: 4,
    },
    {
      label: 'Friday',
      value: 5,
    },
    {
      label: 'Saturday',
      value: 6,
    },
  ];

  useEffect(() => {
    if ((cityLongLat && cityLongLat?.length > 0) || (regionLongLat && regionLongLat?.length > 0)) {
      setCurrentLocation([
        ...(Array?.isArray(cityLongLat)
          ? cityLongLat.map(({ name, latitude, longitude }) => ({
              name,
              lat: Number(latitude),
              lng: Number(longitude),
            }))
          : []),
        ...(Array?.isArray(regionLongLat)
          ? regionLongLat.map(({ name, latitude, longitude }) => ({
              name,
              lat: Number(latitude),
              lng: Number(longitude),
            }))
          : []),
      ]);
    }
  }, [cityLongLat, regionLongLat]);

  const cityKeys = addedAreas?.filter((area) => area?.type === 'city')?.map((area) => area?.key);

  const regionKeys = addedAreas
    ?.filter((area) => area?.type === 'region')
    ?.map((area) => area?.key);

  const getMetaAdGeolocationRegion = useCallback(async () => {
    try {
      const payload = {
        query: quickAdsQuery.getMetaAdGeolocation,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          type: 'regions',
          values: regionKeys,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getMetaAdGeolocation?.status === 200) {
        setRegionLongLat(result?.getMetaAdGeolocation?.data);
      }
    } catch {
      console.error('An error occurred');
    }
  }, [regionKeys, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (
      regionKeys?.length !== prevRegionKeysLength &&
      regionKeys?.length > 0 &&
      userCurrentBusinessDetails?.id
    ) {
      getMetaAdGeolocationRegion();
      setPrevRegionKeysLength(regionKeys?.length);
    }
  }, [
    getMetaAdGeolocationRegion,
    prevRegionKeysLength,
    regionKeys?.length,
    userCurrentBusinessDetails?.id,
  ]);

  const getMetaAdGeolocation = useCallback(async () => {
    try {
      const payload = {
        query: quickAdsQuery.getMetaAdGeolocation,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          type: 'cities',
          values: cityKeys,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getMetaAdGeolocation?.status === 200) {
        setCityLongLat(result?.getMetaAdGeolocation?.data);
      }
    } catch {
      console.error('An error occurred');
    }
  }, [cityKeys, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (
      cityKeys?.length !== prevCityKeysLength &&
      cityKeys?.length > 0 &&
      userCurrentBusinessDetails?.id
    ) {
      getMetaAdGeolocation();
      setPrevCityKeysLength(cityKeys?.length);
    }
  }, [cityKeys?.length, userCurrentBusinessDetails?.id, getMetaAdGeolocation, prevCityKeysLength]);

  useEffect(() => {
    const searchAdsGeolocation = async () => {
      try {
        const payload = {
          query: quickAdsQuery.searchAdsGeolocation,
          variables: {
            searchText: placeOne,
            userBusinessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.searchAdsGeolocation?.status === 200) {
          setSuggestedArea(result?.searchAdsGeolocation?.data);
        }
      } catch {
        console.error('An error occurred');
      }
    };
    if (placeOne !== null && userCurrentBusinessDetails?.id) {
      searchAdsGeolocation();
    }
  }, [placeOne, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const searchAdsTargetingInterest = async () => {
      try {
        const payload = {
          query: quickAdsQuery.searchAdsTargetingInterest,
          variables: {
            searchText: interestName,
            userBusinessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.searchAdsTargetingInterest?.status === 200) {
          const data = result?.searchAdsTargetingInterest?.data;
          const { interests, behaviors } = data;
          setSuggestedInterests([...interests, ...behaviors]);
        }
      } catch {
        console.error('An error occurred');
      }
    };
    if (interestName && userCurrentBusinessDetails?.id) {
      searchAdsTargetingInterest();
    }
  }, [interestName, userCurrentBusinessDetails?.id]);

  const handleInterestClick = (interest) => {
    setAddedInterests((prevAreas) => {
      const exists = prevAreas?.some((existingInterest) => existingInterest?.id === interest?.id);
      if (!exists) {
        return [...prevAreas, interest];
      }
      return prevAreas;
    });
  };

  const handlePlaceClick = (area) => {
    setAddedAreas((prevAreas) => {
      const exists = prevAreas?.some((existingArea) => existingArea?.key === area?.key);
      if (!exists) {
        return [...prevAreas, area];
      }
      return prevAreas;
    });
  };

  if (loading) {
    return <FuseLoading />;
  }

  const onPlacesChanged = async () => {
    setDataLoading(true);
    if (autocompleteMap !== null) {
      const place = autocompleteMap?.getPlace();
      const newLocation = {
        name: place?.name,
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
      };
      setCurrentLocation((prev) => {
        const isDuplicate = prev?.some(
          (loc) => loc?.lat === newLocation?.lat && loc?.lng === newLocation?.lng
        );
        if (!isDuplicate) {
          return [...prev, newLocation];
        }
        return prev;
      });
      if (!place?.place_id) {
        enqueueSnackbar(t('manageBusiness.addBusinessSteps.businessLocation.selectData'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setDataLoading(false);
        return;
      }
      setSelectedMapFullAddress(place?.formatted_address);
      let formattedAddress = '';
      try {
        formattedAddress = place?.formatted_address;
      } catch (error) {
        console.error('An error occurred', error);
      }
      setDataLoading(false);
    }
  };

  const onPlaceSearch = (event) => {
    setSelectedMapFullAddress(event.target.value);
  };

  const onLoad = (autocompleteData) => {
    setAutocompleteMap(autocompleteData);
  };

  const handleAgeRangeChange = (event, newValue) => {
    setAgeRange(newValue);
  };

  const handleDayClick = (day) => {
    setSelectedDays((prev) =>
      prev?.includes(day) ? prev?.filter((d) => d !== day) : [...prev, day]
    );
  };

  const handleToggleDays = () => {
    if (selectedDays?.length === totalDay?.length) {
      setSelectedDays([]);
    } else {
      setSelectedDays(totalDay?.map((day) => day?.value));
    }
  };

  const handleToggleTime = () => {
    if (timeRange[0] === 1 && timeRange[1] === 24) {
      setTimeRange([7, 18]);
    } else {
      setTimeRange([1, 24]);
    }
  };

  const handleChange = (value) => {
    setGender(value);
  };

  const handleTimeChange = (event, newValue) => {
    setTimeRange(newValue);
  };

  return (
    <div className="quick-social">
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 mb-24">
        <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
          <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
            {t('quickAds.audience')}
          </Typography>
          <div>
            <FormGroup className="flex flex-row">
              <FormControlLabel
                className="mr-68 text-18"
                control={<Checkbox checked={gender === 3} onChange={() => handleChange(3)} />}
                label={<span className="text-18 text-black font-medium">{t('menuItems.all')}</span>}
              />
              <FormControlLabel
                className="mr-68 text-18"
                control={
                  <Checkbox
                    checked={gender === 1 || gender === 3}
                    onChange={() => handleChange(1)}
                  />
                }
                label={<span className="text-18 text-black font-medium">{t('quickAds.male')}</span>}
              />
              <FormControlLabel
                className="text-18"
                control={
                  <Checkbox
                    checked={gender === 2 || gender === 3}
                    onChange={() => handleChange(2)}
                  />
                }
                label={
                  <span className="text-18 text-black font-medium">{t('quickAds.female')}</span>
                }
              />
            </FormGroup>
          </div>
        </div>

        <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
          <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
            {t('quickAds.audience')}{' '}
            <span className="text-16 font-medium text-quick-social">{`${ageRange[0]} - ${ageRange[1]} years`}</span>
          </Typography>
          <Slider
            value={ageRange}
            onChange={handleAgeRangeChange}
            valueLabelDisplay="auto"
            min={18}
            max={65}
            sx={{
              color: '#219EBC',
              paddingBottom: '0',
            }}
          />
          <Box display="flex" justifyContent="space-between">
            <Typography className="text-16 font-medium">18</Typography>
            <Typography className="text-16 font-medium">65</Typography>
          </Box>
        </div>
      </div>
      <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
        <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
          {t('quickAds.targetAreas')}
        </Typography>
        <Box display="flex" alignItems="center" gap={2} mt={2}>
          <TextField
            value={placeOne}
            onChange={(e) => setPlaceOne(e.target.value)}
            variant="outlined"
            placeholder="Search Targeted Areas"
            InputProps={{
              endAdornment: (
                <IconButton className="p-0">
                  <Search />
                </IconButton>
              ),
            }}
            fullWidth
          />
        </Box>
        {placeOne?.length > 0 && (
          <div className=" ml-48 left-0 right-14 bg-white max-h-160  max-w-4xl overflow-y-auto z-20 rounded-8 absolute p-7">
            {suggestedArea &&
              suggestedArea?.length > 0 &&
              suggestedArea?.map((area, index) => (
                <div
                  key={index}
                  className="h-60 p-4 flex font-medium items-center border-b-2 border-b-gray-A100 last:border-b-0"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handlePlaceClick(area);
                    setPlaceOne('');
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handlePlaceClick(area);
                    }
                  }}
                >
                  <div>
                    <LocationOnOutlined className="text-red-500 mr-8" />
                  </div>
                  <div className="flex">
                    {area?.name && <p>{area?.name}, </p>} {area?.region && <p> {area?.region},</p>}
                    {area?.country_name && <p> {area?.country_name}</p>}
                  </div>
                </div>
              ))}
          </div>
        )}
        <div className="w-full relative mt-20">
          <LoadScript
            id="script-loader"
            googleMapsApiKey={key}
            libraries={lib}
            loadingElement={<div>Loading...</div>}
          >
            <GoogleMap
              mapContainerStyle={{
                height: '300px',
                width: '100%',
                position: 'relative',
              }}
              className="custom-height rounded-lg"
              zoom={8}
              center={
                currentLocation[
                  currentLocation?.length > 1 ? Number(currentLocation?.length) - 1 : 0
                ]
              }
              options={{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                zoomControl: false,
              }}
            >
              {currentLocation?.map((marker, index) => (
                <Marker key={index} position={marker} />
              ))}
              <Autocomplete onLoad={onLoad} onPlaceChanged={onPlacesChanged} zIndex="999">
                <StandaloneSearchBox zIndex="999">
                  <input
                    type="text"
                    value={selectedMapFullAddress}
                    onChange={(e) => onPlaceSearch(e)}
                    hidden
                    placeholder={t(
                      'manageBusiness.addBusinessSteps.businessLocation.searchYourName'
                    )}
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: `300px`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `5px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: 'absolute',
                      margin: '0 auto',
                      top: '14px',
                      left: '0',
                      right: '0',
                      zIndex: '1',
                    }}
                  />
                </StandaloneSearchBox>
              </Autocomplete>
            </GoogleMap>
          </LoadScript>
          {/* {googleData?.businessName && (
            <div className="border-1 left-60 bg-white absolute top-48 sm:w-320 h-max-content m-auto md:w-3/4 rounded-md p-8 border-solid !border-darkgreen">
              <img className="rounded-md h-112 w-full" src={googleData?.businessImage} alt="" />
              <div className="flex justify-between pt-8">
                <Typography className="font-bold w-full text-12 max-w-160">
                  {googleData?.businessName}
                </Typography>
                <div className="flex">
                  {googleData?.rating > 0 && (
                    <Typography className="font-medium text-12">
                      <Icon className="text-14 text-yellow-800 align-middle mr-2 -mt-2">star</Icon>{' '}
                      {googleData?.rating}{' '}
                      <span className="font-normal pl-4 text-12">
                        ({googleData?.userTotalRating} reviews)
                      </span>
                    </Typography>
                  )}
                </div>
              </div>
              <Typography className="text-12 py-8">{googleData?.address}</Typography>
            </div>
          )} */}
        </div>

        <Typography className="font-semibold mb-16 text-14 lg:text-18 mt-20">
          {t('quickAds.addedAreas')}
        </Typography>
        <div className="flex-wrap items-center gap-x-7">
          {addedAreas?.map((area, index) => (
            <Chip
              key={index}
              className="rounded-md text-black text-14 font-semibold mr-5"
              variant="outlined"
              label={area?.name}
              avatar={<PlaceIcon style={{ fontSize: 14, color: '#f71959', width: 16 }} />}
              onDelete={() => setAddedAreas((prev) => prev?.filter((_, i) => i !== index))}
            />
          ))}
        </div>
      </div>
      {budgetType === 'lifetime_budget' && (
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 mb-24">
          <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
            <div className="flex items-center justify-between mb-10">
              <Typography className="font-semibold mb-0 mt-0 text-14 lg:text-18">
                {t('common.days')}
              </Typography>
              <span>
                <span className="inline-block text-16 font-medium">{t('quickAds.allDays')}</span>
                <Switch
                  checked={selectedDays?.length === totalDay?.length}
                  className="P-0 -mr-12"
                  onChange={() => handleToggleDays()}
                  color="quickSocial"
                />
              </span>
            </div>

            <div className="flex items-center justify-between">
              {totalDay?.map((day, i) => (
                <Button
                  key={i}
                  className={`rounded-md w-44 h-36 min-w-44 min-h-36 p-0 inline-block font-semibold ${
                    selectedDays?.includes(day?.value)
                      ? 'bg-quick-social text-white'
                      : 'bg-white border-gray-A500 text-black'
                  }`}
                  color="quickSocial"
                  variant={selectedDays?.includes(day?.value) ? 'contained' : 'outlined'}
                  onClick={() => handleDayClick(day?.value)}
                >
                  {day?.label?.slice(0, 3)}
                </Button>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
            <div className="flex items-center justify-between mb-10">
              <Typography className="font-semibold mb-0 mt-0 text-14 lg:text-18">
                {t('sendMessage.time')}{' '}
                <span className="text-16 font-medium text-quick-social ml-40">{`${timeRange[0]} to ${timeRange[1]}`}</span>
              </Typography>
              <span>
                <span className="inline-block text-16 font-medium">{t('quickAds.wholeDays')}</span>
                <Switch
                  checked={timeRange[0] === 1 && timeRange[1] === 24}
                  className="P-0 -mr-12"
                  onChange={() => handleToggleTime()}
                  color="quickSocial"
                />
              </span>
            </div>
            <Slider
              value={timeRange}
              onChange={handleTimeChange}
              min={1}
              max={24}
              valueLabelDisplay="auto"
              sx={{
                color: '#219EBC',
                paddingBottom: '0',
              }}
            />
            <Box display="flex" justifyContent="space-between">
              <Typography className="text-16 font-medium">12am</Typography>
              <Typography className="text-16 font-medium">11:59pm</Typography>
            </Box>
          </div>
        </div>
      )}
      <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
        <Typography className="font-semibold mb-16 mt-0 text-14 lg:text-18">
          {t('quickAds.interest&Behaviors')}
        </Typography>
        <div className="relative mb-12">
          <TextField
            className="w-full"
            value={interestName}
            size="medium"
            placeholder="(ex: Fitness)"
            onChange={(e) => {
              setInterestName(e.target.value);
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#b7b7b7',
                },
                '&:hover fieldset': {
                  borderColor: '#219EBC',
                },
              },
              '& .MuiInputBase-input': {
                fontWeight: '600',
                lineHeight: '22px',
                fontSize: 16,
              },
            }}
          />
          {interestName?.length > 0 && (
            <div className=" left-0 right-14 bg-white max-h-160  min-w-4xl overflow-y-auto z-20 rounded-8 absolute p-7">
              {suggestedInterests && suggestedInterests?.length > 0 ? (
                suggestedInterests?.map((interest, index) => (
                  <div
                    key={index}
                    className="h-60 p-4 flex font-medium items-center justify-between border-b-2 border-b-gray-A100 last:border-b-0"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      handleInterestClick(interest);
                      setInterestName('');
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleInterestClick(interest);
                      }
                    }}
                  >
                    <p>{interest?.name} </p>
                    <p className="text-black capitalize">{interest?.type}</p>
                  </div>
                ))
              ) : (
                <div className=" left-0 right-14 bg-white h-160  w-4xl overflow-y-auto z-20 rounded-8 absolute p-7 text-black">
                  {' '}
                  No data Available{' '}
                </div>
              )}
            </div>
          )}
          {/* <Button
            size="small"
            color="quickSocial"
            onClick={() => {
              setAddedInterests((prev) =>
                prev?.includes(interestName) ? prev : [...prev, interestName]
              );
              setInterestName('');
            }}
            variant="contained"
            className="rounded-md text-18 font-medium min-h-32 h-32 absolute right-12 top-12"
            disabled={!interestName}
          >
            + Add
          </Button> */}
        </div>
        {/* <div className="flex items-center gap-x-7 border-b-1 border-solid border-grey-300 pb-12 mb-12 flex-wrap">
          <span className="inline-block text-16 font-medium text-grey-600">
            {t('quickAds.suggested')} :
          </span>
          {suggestedInterests?.map((interest) => (
            <Chip
              key={interest}
              color="quickSocial"
              className="bg-quick-social-100 text-16 font-medium text-black mb-5"
              variant="outlined"
              label={interest?.name}
              onClick={() =>
                setAddedInterests((prev) => (prev.includes(interest) ? prev : [...prev, interest]))
              }
            />
          ))}
        </div> */}
        <Typography className="font-medium mb-10 mt-0 text-14 lg:text-18">
          {t('quickAds.added')} {t('quickAds.interest&Behaviors')}
        </Typography>
        <div>
          <div className="flex-wrap items-center gap-x-7">
            {addedInterests?.map((interest, index) => (
              <Chip
                key={index}
                label={interest?.name}
                color="quickSocial"
                className="bg-quick-social-200 text-16 font-medium text-black mb-5 mr-5"
                variant="outlined"
                onDelete={() => setAddedInterests((prev) => prev?.filter((_, i) => i !== index))}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <Button
          className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
          variant="outlined"
          color="quickSocial"
          disabled={refreshIcon}
          onClick={() => createAndUpdateAdsCampaign('Draft')}
        >
          {t('quickAds.saveDraft')}
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
        <Button
          className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
          variant="contained"
          color="quickSocial"
          disabled={
            addedAreas?.length === 0 ||
            addedInterests?.length === 0 ||
            refreshIcon ||
            (budgetType === 'lifetime_budget' && selectedDays?.length === 0)
          }
          onClick={() => {
            createAndUpdateAdsCampaign();
            setActiveStep(2);
          }}
        >
          {t('quickAds.proceed')}
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
      </div>
    </div>
  );
}
export default AudienceSegmentBudget;
