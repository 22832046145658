import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { json, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';
import Avatar from '@mui/material/Avatar';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import './QuickChatEditor.css';
import { publicIpv4 } from 'public-ip';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@mui/material';
import { connectChatSocket } from '../../../socket';
import quickChatQuery from '../query/quickChat';
import { formatDateWithTimeZone, handleApiRequest } from '../common/common';

function QuickChat() {
  const chatSocket = connectChatSocket();
  const chatContainerRef = useRef(null);
  const routeParams = useParams();
  const chatScroll = useRef(null);
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [messageText, setMessageText] = useState('');
  const [lastMessage, setLastMessage] = useState({});
  const [chatInfo, setChatInfo] = useState({});
  const [messages, setMessages] = useState([]);
  const [getAllMessages, setGetAllMessages] = useState([]);
  const [isBotTyping, setIsBotTyping] = useState(true);
  const [tags, setTags] = useState([]);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayMenuForChips, setDisplayMenuForChips] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [isConnected, setIsConnected] = useState(chatSocket.connected); // chatSocket.connected
  const [openDialog, setOpenDialog] = useState(false);
  const [doneByBot, setDoneByBot] = useState(false);
  const [botSatisfactionMsg, setBotSatisfactionMsg] = useState('');
  const currentTime = moment().tz(timeZone).toISOString();
  const [ipAddress, setIpAddress] = useState(null);
  const [rating, setRating] = useState();
  const [cachedIp, setCachedIp] = useState(null);
  const [subscribeChannelByClientUserStatus, setSubscribeChannelByClientUserStatus] =
    useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [prevPage, setPrevPage] = useState(0);
  const [wasLastList, setWasLastList] = useState(false);
  const [isHumanInteraction, setIsHumanInteraction] = useState(false);

  const talkBusinessUserMessage = 'I would like to talk to someone';

  useEffect(() => {
    // Fetch IP address only once
    const fetchIp = async () => {
      try {
        const ip = await publicIpv4();
        setIpAddress(ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIp();
  }, []);

  useEffect(() => {
    if (chatScroll.current) {
      chatScroll.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    try {
      const fetchAgentSupportStatus = async () => {
        const payloadData = {
          query: quickChatQuery.getQuickChat,
          variables: {
            domain: `${routeParams?.location}/${routeParams?.business}`,
          },
        };
        const resultData = await handleApiRequest(payloadData);

        if (resultData?.getQuickChat?.status === 200) {
          setChatInfo(resultData?.getQuickChat?.data);
          if (resultData?.getQuickChat?.data) {
            setTags(JSON?.parse(resultData?.getQuickChat?.data?.quickChatSuggestion));
          }
        }
      };
      fetchAgentSupportStatus();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [routeParams]);

  useEffect(() => {
    const handleChat = async () => {
      try {
        const payload = {
          query: quickChatQuery.getQuickChatHumanInteraction,
          variables: {
            quickChatId: Number(chatInfo?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQuickChatHumanInteraction?.status === 200) {
          setIsHumanInteraction(result?.getQuickChatHumanInteraction?.data?.isHumanInteraction);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };
    handleChat();
  }, [chatInfo?.id]);

  const initialSetData = useCallback(
    (data) => {
      if (data?.length === 0 && chatInfo) {
        setTimeout(() => {
          setMessages([
            {
              message: `${t('quickChat.createBot.defaultMessage')}`,
              messageType: 'sender',
              time: moment().tz(timeZone).toISOString(),
            },
            {
              message: `I’m a ${chatInfo?.name}, what do you want to know?`,
              messageType: 'sender',
              time: moment().tz(timeZone).toISOString(),
            },
          ]);
          setIsBotTyping(false);
          setDisplayMenu(true);
        }, 2000);
      } else {
        setMessages(data);
        setIsBotTyping(false);
        setDisplayMenu(true);
      }
    },
    [chatInfo, timeZone]
  );

  useEffect(() => {
    const fetchIp = async () => {
      const ip = await ipAddress;
      setCachedIp(ip);
    };

    if (!cachedIp) {
      fetchIp();
    }
  }, [cachedIp, ipAddress]);

  const fetchAgentSupportStatus = useCallback(async () => {
    if (chatInfo?.id) {
      const payloadData = {
        query: quickChatQuery.getAllQuickChatRoomMessage,
        variables: {
          ipAddress: cachedIp,
          quickChatId: chatInfo?.id,
          sortBy: 'cts',
          sortOrder: 'desc',
          pageNumber: currPage,
          pageSize: 20,
        },
      };
      const resultData = await handleApiRequest(payloadData);

      if (resultData?.getAllQuickChatRoomMessage?.status === 200) {
        setHasMore(true);
        if (resultData?.getAllQuickChatRoomMessage?.data?.length === 0) {
          setWasLastList(true);
          setHasMore(false);
          if (currPage === 1) {
            initialSetData([]);
          }
          return;
        }
        setPrevPage(currPage);
        setMessages([
          ...(resultData?.getAllQuickChatRoomMessage?.data.reverse() || []),
          ...messages,
        ]);
        if (currPage === 1) {
          initialSetData(resultData?.getAllQuickChatRoomMessage?.data);
        }

        const previousScrollHeight = chatContainerRef.current?.scrollHeight;
        setTimeout(() => {
          const newScrollHeight = chatContainerRef.current?.scrollHeight;
          chatContainerRef.current.scrollTop += newScrollHeight - previousScrollHeight;
        }, 0);
      }
    }
  }, [chatInfo?.id, cachedIp, currPage, messages, initialSetData]);

  useEffect(() => {
    if (!wasLastList && prevPage !== currPage) {
      fetchAgentSupportStatus();
    }
  }, [currPage, wasLastList, prevPage, getAllMessages, fetchAgentSupportStatus, hasMore]);

  useEffect(() => {
    const handleSubscribeChatData = async () => {
      if (!chatSocket || !chatSocket.connected) {
        console.log('Socket is not connected');
        return;
      }

      if (!chatInfo?.id) {
        console.log('chatInfo.id is not available');
        return;
      }

      try {
        const subscribeChatData = {
          ipAddress,
          quickChatId: chatInfo?.id,
        };

        chatSocket.emit(
          'subscribeChannelByClientUser',
          JSON.stringify(subscribeChatData),
          (response) => {
            if (response && response.success) {
              console.log('Subscription successful:', response);
              setSubscribeChannelByClientUserStatus(true);
            } else {
              console.error('Subscription failed:', response);
              setIsConnected(false);
            }
          }
        );
      } catch (error) {
        console.error('Error fetching IP address or connecting to chat:', error);
        setIsConnected(false);
      }
    };

    if (!subscribeChannelByClientUserStatus) {
      handleSubscribeChatData();
      console.log('Executing subscribeChannelByClientUser with valid conditions');
    }
  }, [chatInfo?.id, chatSocket, ipAddress, isConnected, subscribeChannelByClientUserStatus]);

  useEffect(() => {
    const handleMessage = async (message) => {
      if (message?.data?.isEnterOwner) {
        setIsBotTyping(false);
      }
      if (message?.data?.answer || message?.data?.message) {
        const status = 'delivered';
        const newMessage = {
          message: message?.data?.answer || message?.data?.message,
          messageType: 'sender',
          chips: message?.data?.chips,
          cts: currentTime,
          status,
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        const payload = JSON.stringify({
          quickChatRoomMessagesId: message?.data?.quickChatRoomMessagesId,
          status,
        });
        chatSocket.emit('messageStatusFromClient', payload);
        setIsBotTyping(false);

        if (message?.data?.chips?.length > 0) {
          setDisplayMenuForChips(true);
        } else {
          setDisplayMenuForChips(false);
        }
        const previousScrollHeight = chatContainerRef.current?.scrollHeight;
        setTimeout(() => {
          const newScrollHeight = chatContainerRef.current?.scrollHeight;
          chatContainerRef.current.scrollTop += newScrollHeight - previousScrollHeight;
        }, 0);
      }
      if (message?.eventType === 'messageToClient') {
        if (message?.data?.showPopUp) {
          // setDisplayMenu(true);
          setShowPopUp(true);
          setDoneByBot(true);
          setIsBotTyping(false);
        }
      }
      if (message?.eventType === 'humanInteractionStatus') {
        setIsHumanInteraction(message?.isHumanInteraction);
      }
    };

    const handleConnect = async () => {
      try {
        // const ipAddressValue = '125.369.255';
        // const subscribeChatData = JSON.stringify({
        //   ipAddress: ipAddressValue,
        //   quickChatId: chatInfo?.id,
        // });

        // // Emit subscription event only after socket connects
        // chatSocket.emit('subscribeChannelByClientUser', subscribeChatData);
        console.log('Socket connected from event');
        setSubscribeChannelByClientUserStatus(false);
        setIsConnected(true);
      } catch (error) {
        console.error('Error connecting to chat:', error);
        setIsConnected(false);
      }
    };

    const handleDisconnect = () => {
      setIsConnected(false);
    };

    // Attach event listeners
    chatSocket.on('connect', handleConnect);
    chatSocket.on('disconnect', handleDisconnect);
    chatSocket.on('QUICK_CHAT_MESSAGES', handleMessage);

    // Cleanup function to remove the event listeners when component unmounts or re-renders
    return () => {
      chatSocket.off('connect', handleConnect);
      chatSocket.off('disconnect', handleDisconnect);
      chatSocket.off('QUICK_CHAT_MESSAGES', handleMessage);
    };
  }, [chatInfo?.id, chatSocket, currentTime, subscribeChannelByClientUserStatus]);

  useEffect(() => {
    const clearLocalStorageOnRefresh = () => {
      localStorage.removeItem('botReplayStatusValue');
    };

    window.addEventListener('beforeunload', clearLocalStorageOnRefresh);

    return () => {
      window.removeEventListener('beforeunload', clearLocalStorageOnRefresh);
    };
  }, []);

  const sendMessage = async (e) => {
    const message = messageText || e;
    const botReplayStatus = message !== talkBusinessUserMessage;

    if (!localStorage.getItem('botReplayStatusValue')) {
      localStorage.setItem('botReplayStatusValue', botReplayStatus);
    }

    if (message?.trim()) {
      const messageData = JSON.stringify({
        message,
        ipAddress: await ipAddress,
        quickChatRoomId: getAllMessages[0]?.quickChatRoomId,
        quickChatId: chatInfo?.id,
        isReplyByBot: localStorage.getItem('botReplayStatusValue'),
        ...(botReplayStatus && { businessId: chatInfo?.userBusinessesId }),
      });
      chatSocket.emit('messageFromClient', messageData); // for send message
      const newMessages = [...messages, { message, messageType: 'delivered', cts: currentTime }];
      setMessages(newMessages);
      if (botReplayStatus) {
        setIsBotTyping(true);
      }
      setMessageText('');

      setTimeout(() => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 0);
    }
  };
  const onMessageSubmit = async (e) => {
    e.preventDefault();
    sendMessage();
    setDisplayMenu(false);
  };

  const handleTagClick = async (tag) => {
    setMessageText(tag);
    sendMessage(tag);
    setDisplayMenu(false);
    if (tag !== talkBusinessUserMessage) {
      setDoneByBot(true);
    }
  };

  const handleTagClickBot = async (tag) => {
    setBotSatisfactionMsg(tag);
    const botReplayStatus = tag !== talkBusinessUserMessage;

    if (!localStorage.getItem('botReplayStatusValue')) {
      localStorage.setItem('botReplayStatusValue', botReplayStatus);
    }
    const messageData = JSON.stringify({
      // message,
      ipAddress: await ipAddress,
      quickChatRoomId: getAllMessages[0]?.quickChatRoomId,
      quickChatId: chatInfo?.id,
      yesIAmDone: botReplayStatus,
      isReplyByBot: botReplayStatus,
      ...(botReplayStatus && { businessId: chatInfo?.userBusinessesId }),
      ...(tag === talkBusinessUserMessage && { message: talkBusinessUserMessage }),
    });
    chatSocket.emit('messageFromClient', messageData);

    setDoneByBot(false);
    setDisplayMenuForChips(false);
  };

  const closeWindow = () => {
    setOpenDialog(true);
  };

  const groupMessagesByDate = (messagesData) => {
    return messagesData.reduce((groups, message) => {
      const date = formatDateWithTimeZone(message?.time || message?.cts, 'YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const submitRating = async () => {
    try {
      const payload = {
        query: quickChatQuery.saveQuickChatClientRating,
        variables: {
          quickChatId: parseInt(chatInfo?.id, 10),
          ipAddress: cachedIp,
          rating: parseInt(rating, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.saveQuickChatClientRating?.status === 200) {
        setOpenDialog(false);
        setRating();
        window.open('about:blank', '_self');
        window.close();
      }
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const handleClick = (value) => {
    setRating(value);
  };
  const onScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
      if (scrollTop === 0) {
        setHasMore(false);
        setCurrPage((prevPageNumber) => prevPageNumber + 1);
      }
      // if (scrollTop <= 4 && !wasLastList && hasMore) {
      //   setHasMore(false);
      //   setCurrPage((prevPageNumber) => prevPageNumber + 1);
      // }
    }
  };

  return (
    <>
      <style>
        {`
          .receiver-color {
            background-color: ${chatInfo?.headerColor || '#EBB32F'};
          }
        `}
      </style>
      {chatInfo && chatInfo !== null ? (
        <>
          {chatInfo?.headerColor && (
            <AppBar
              className={`shadow-md sticky mb-48 bg-[${chatInfo?.headerColor}]`}
              style={{ backgroundColor: chatInfo?.headerColor }}
            >
              <Toolbar className="px-4">
                <div className="flex flex-1 items-center px-12">
                  <div className="bg-white w-48 h-48 flex justify-center items-center relative rounded-full overflow-hidden">
                    <img
                      className="w-full h-full object-cover"
                      name="logo"
                      src={chatInfo?.avatar}
                      alt="bell notification icon"
                    />
                  </div>
                  <span className="w-10 h-10 bg-green inline-block rounded-full -ml-8 z-10 mt-20" />
                  <Typography className="mx-16 text-16" color="inherit">
                    {chatInfo?.name}
                  </Typography>
                </div>

                <div className="flex px-4">
                  <IconButton onClick={() => closeWindow()} color="inherit" size="large">
                    <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>
          )}

          <div className="client-chat-height mb-76 px-12">
            <div
              className="flex-grow overflow-auto p-20 pb-10"
              onScroll={onScroll}
              ref={chatContainerRef}
            >
              {Object.entries(groupMessagesByDate(messages)).map(([date, messagesValue], index) => {
                return (
                  <div key={index}>
                    <div className="text-center">
                      <div className="text-center text-grey-600 text-12 font-medium mb-12 bg-white inline-block mx-auto rounded-md shadow px-10 py-4">
                        {moment(date !== 'null' ? date : new Date().toISOString())
                          .tz(timeZone)
                          .calendar(null, {
                            sameDay: '[Today]',
                            lastDay: '[Yesterday]',
                            lastWeek: 'dddd',
                            sameElse: 'MMM DD, YYYY',
                          })}
                      </div>
                    </div>
                    {messagesValue?.map((msg, i) => (
                      <div
                        key={i}
                        className={`block max-w-[1050px] relative mx-auto ${
                          msg?.messageType === 'sender' ? 'text-left' : `text-right`
                        }`}
                      >
                        {msg.messageType === 'sender' && (
                          <div className="float-left md:mr-28 mr-12 bg-darkgreen-100 w-48 h-48 flex justify-center items-center relative rounded-full overflow-hidden">
                            <img
                              className="w-full h-full object-cover"
                              name="logo"
                              src={chatInfo?.avatar}
                              alt="bell notification icon"
                            />
                          </div>
                        )}
                        <div>
                          <div className="sm:max-w-480 max-w-256 inline-block">
                            <div
                              key={index}
                              className={`transition ease-in-out delay-150 md:p-16 p-10 my-5 mx-auto rounded-2xl message md:text-16 font-semibold ${
                                msg?.messageType === 'sender'
                                  ? 'bg-darkgreen-100 text-left rounded-tl-none'
                                  : `bg-[${chatInfo?.headerColor}] text-right text-white rounded-tr-none receiver-color`
                              } `}
                              dangerouslySetInnerHTML={{ __html: msg?.message }}
                            />
                            <Typography
                              className="time w-full text-12 font-medium text-grey-700 mt-8 whitespace-nowrap"
                              color="text.secondary"
                            >
                              {formatDateWithTimeZone(msg?.cts, 'hh:mm A')}
                            </Typography>

                            {displayMenuForChips &&
                              i === Number(messagesValue?.length) - 1 &&
                              msg?.chips?.map(
                                (item, ind) =>
                                  (isHumanInteraction ||
                                    item !== 'I would like to talk to someone') && (
                                    <div
                                      key={ind}
                                      className="tag bg-white font-medium md:text-14 text-12 text-darkgreen border border-darkgreen rounded-full md:px-16 md:py-8 px-12 py-2 m-2 cursor-pointer inline-block"
                                      aria-hidden="true"
                                      onClick={() => handleTagClick(item)}
                                    >
                                      {item}
                                    </div>
                                  )
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}

              {displayMenu && (
                <div className="tags block max-w-[1050px] relative mx-auto text-left mt-8 md:pl-80 pl-36">
                  {tags?.map(
                    (tag, i) =>
                      (isHumanInteraction || tag !== 'I would like to talk to someone') && (
                        <div
                          key={i}
                          className="tag bg-white font-medium md:text-14 text-12 text-darkgreen border-1 border-solid border-darkgreen rounded-full md:px-16 md:py-8 px-12 py-2 m-2 cursor-pointer inline-block"
                          aria-hidden="true"
                          onClick={() => handleTagClick(tag)}
                        >
                          {tag}
                        </div>
                      )
                  )}
                </div>
              )}

              {/* {doneByBot && !isBotTyping && (
                <div className="tags block max-w-[1050px] relative mx-auto text-left mt-8 max-w-[1050px] md:pl-80 pl-36">
                  <div
                    className="tag bg-white font-medium md:text-14 text-12 text-darkgreen border-1 border-solid border-darkgreen rounded-full md:px-16 md:py-8 px-12 py-2 m-2 cursor-pointer inline-block"
                    aria-hidden="true"
                    onClick={() => handleTagClickBot(talkBusinessUserMessage)}
                  >
                   {talkBusinessUserMessage}
                  </div>
                  <div
                    className="tag bg-white font-medium md:text-14 text-12 text-darkgreen border-1 border-solid border-darkgreen rounded-full md:px-16 md:py-8 px-12 py-2 m-2 cursor-pointer inline-block"
                    aria-hidden="true"
                    onClick={() => handleTagClickBot('yes i am done')}
                  >
                    yes i am done
                  </div>
                </div>
              )} */}

              {doneByBot && !isBotTyping && (
                <div className="tags block max-w-[1050px] relative mx-auto text-left mt-8 md:pl-80 pl-36">
                  {showPopUp ? (
                    isHumanInteraction && (
                      <div
                        className="tag bg-white font-medium md:text-14 text-12 text-darkgreen border-1 border-solid border-darkgreen rounded-full md:px-16 md:py-8 px-12 py-2 m-2 cursor-pointer inline-block"
                        aria-hidden="true"
                        onClick={() => handleTagClickBot(talkBusinessUserMessage)}
                      >
                        {talkBusinessUserMessage}
                      </div>
                    )
                  ) : (
                    <>
                      {isHumanInteraction && (
                        <div
                          className="tag bg-white font-medium md:text-14 text-12 text-darkgreen border-1 border-solid border-darkgreen rounded-full md:px-16 md:py-8 px-12 py-2 m-2 cursor-pointer inline-block"
                          aria-hidden="true"
                          onClick={() => handleTagClickBot(talkBusinessUserMessage)}
                        >
                          {talkBusinessUserMessage}
                        </div>
                      )}
                      <div
                        className="tag bg-white font-medium md:text-14 text-12 text-darkgreen border-1 border-solid border-darkgreen rounded-full md:px-16 md:py-8 px-12 py-2 m-2 cursor-pointer inline-block"
                        aria-hidden="true"
                        onClick={() => handleTagClickBot('yes i am done')}
                      >
                        yes i am done
                      </div>
                    </>
                  )}
                </div>
              )}

              {isBotTyping && (
                <div className="block max-w-[1050px] relative mx-auto text-left">
                  <Avatar
                    src={chatInfo?.avatar}
                    className="float-left md:mr-28 mr-12 md:w-60 md:h-60 w-28 h-28 bg-darkgreen-100 md:p-10 p-5"
                  />
                  <div className="max-w-480 inline-block">
                    <div className="py-16 my-5 mx-auto rounded-2xl message md:text-16 font-semibold bg-white text-left rounded-tl-none">
                      <div className="typing-indicator">
                        <div className="dot" style={{ backgroundColor: chatInfo?.headerColor }} />
                        <div className="dot" style={{ backgroundColor: chatInfo?.headerColor }} />
                        <div className="dot" style={{ backgroundColor: chatInfo?.headerColor }} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div ref={chatScroll} className="h-20" />
          </div>

          <form
            onSubmit={onMessageSubmit}
            className="pb-16 px-8 bg-white bottom-0 fixed left-0 right-0 mx-auto max-w-[1050px] pt-24"
          >
            <Paper className="rounded-16 flex items-center relative shadow-none border-1 border-solid border-grey-500">
              <InputBase
                autoFocus={false}
                id="message-input"
                size="small"
                className="flex flex-1 grow shrink-0 px-16"
                placeholder="Ask Me anything"
                onChange={(e) => {
                  setMessageText(e.target.value);
                }}
                onFocus={() => {
                  const payload = JSON.stringify({
                    quickChatRoomMessagesId: messages[messages.length - 1]?.id,
                    quickChatRoomId: messages[messages.length - 1]?.quickChatRoomId,
                    status: 'seen',
                  });
                  chatSocket.emit('messageStatusFromClient', payload);
                }}
                value={messageText}
              />
              <IconButton className="absolute right-10 top-3" type="submit" size="small">
                <FuseSvgIcon className="rotate-90" color="action">
                  heroicons-outline:paper-airplane
                </FuseSvgIcon>
              </IconButton>
            </Paper>
          </form>
        </>
      ) : (
        <div className="">
          <Toolbar className="px-20 py-14 bg-[#323952]">
            <div className="flex flex-1 items-center px-12">
              <img
                src="/assets/images/icon/avatarQuickChat.png"
                className="bg-white md:p-10 p-5 md:w-60 rounded-full md:h-60 w-48 h-48"
                alt=""
              />
              <CloseIcon className="w-14 h-14 bg-red text-white inline-block rounded-full -ml-8 z-10 mt-20" />
              <Typography className="mx-16 text-white text-16 sm:text-20 font-600" color="inherit">
                {t('quickChat.title')}
              </Typography>
            </div>
          </Toolbar>
          <div className="h-3xl flex flex-col items-center justify-center mx-10">
            <div className=" max-w-screen-md lg:py-60 py-20 lg:px-96 px-20 text-center mx-auto ">
              <div>
                <img
                  className="m-auto max-w-96"
                  src="/assets/images/icon/chatUnavailable.png"
                  alt=""
                />
                <h3 className="sm:text-28 text-24 font-600 mb-16 mt-20 text-black">
                  {t('quickChat.chatbotUnavailable')}
                </h3>
                <Typography className="text-20 font-500 mx-auto max-w-screen-md lg:px-96">
                  {t('quickChat.chatbotUnavailableDesc')}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog
        classes={{
          paper: 'm-24 p-28',
        }}
        open={openDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-24">
          <Icon
            onClick={() => {
              setOpenDialog(false);
              setRating();
              window.open('about:blank', '_self');
              window.close();
            }}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
        </DialogTitle>

        <DialogContent className="p-0 mb-24">
          <Typography className="text-20 font-medium text-center">
            {t('quickChat.shareExp')}
          </Typography>
          <div className="text-center mt-8">
            {[...Array(5)]?.map((item, i) => {
              return (
                <Icon
                  className={`text-48 cursor-pointer ${
                    i < rating ? 'text-yellow-700' : 'text-gray-400'
                  }`}
                  key={i}
                  onClick={() => handleClick(i + 1)}
                >
                  star
                </Icon>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions className="p-0 justify-center">
          <Button
            className="md:text-18 font-semibold disabled:text-black rounded-md px-48"
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => submitRating()}
            disabled={!rating}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QuickChat;
