const getAllBusinessType = `
	query ($isActive: Boolean, $pageNumber: Int, $pageSize: Int, $searchText: String, $sortBy: String, $sortOrder: OrderDirection) {
		getAllBusinessType(isActive: $isActive, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText, sortBy: $sortBy, sortOrder: $sortOrder) {
               totalCount
      data {
          bType
          cts
          id
          isActive
      } 
               message
               status 
		}
	}
`;

const addBusinessType = `
     mutation ($businessType: String!) {
		addBusinessType(businessType: $businessType) {
               message
               status
		}
	}
`;

const updateBusinessType = `
    mutation ($businessType: String!, $businessTypeId: Int!) {
		updateBusinessType(businessType: $businessType, businessTypeId: $businessTypeId) {
      message
      status
		}
	}
`;

const getAllBusinessAttributes = `
	query ($isActive: Boolean, $pageNumber: Int, $pageSize: Int, $searchText: String, $sortBy: String, $sortOrder: OrderDirection) {
		getAllBusinessAttributes(isActive: $isActive, pageNumber: $pageNumber, pageSize: $pageSize, searchText: $searchText, sortBy: $sortBy, sortOrder: $sortOrder) {
      totalCount
      data {
        bAttribute
        businessAttributeValueId
        type
        icon
        cts
        id
        isActive
      } 
      message
      status 
		}
	}
`;

const addBusinessAttributes = `
mutation ($bAttributes: String!, $icon: String, $businessAttributeValueId: Int!) {
		addBusinessAttributes(bAttributes: $bAttributes, icon: $icon, businessAttributeValueId: $businessAttributeValueId) {
      message
      status 
		}
	}
`;

const updateBusinessAttribute = `
mutation ($bAttributes: String!, $icon: String, $bAttributeId: Int!, $businessAttributeValueId: Int) {
  updateBusinessAttribute(bAttributes: $bAttributes, icon: $icon, bAttributeId: $bAttributeId, businessAttributeValueId: $businessAttributeValueId) {
      message
      status 
		}
	}
`;

const getAllBusinessTypeAttributes = `
query(
   $pageNumber: Int
   $pageSize: Int
   $sortBy: String
   $sortOrder: OrderDirection
   $searchText: String
   $isActiveFilter: Boolean
 ) {
   getAllBusinessTypeAttributes(
     pageNumber: $pageNumber
     pageSize: $pageSize
     sortBy: $sortBy
     sortOrder: $sortOrder
     searchText: $searchText
     isActiveFilter: $isActiveFilter
   ) {
     totalCount
     data {
       id
       businessTypeId
       business_attributes {
         bAttribute
         id
       }
       business_types {
         isActive
         bType
         id
       }
       cts
     }
     totalCount
     message
     status
   }
 }
 
`;

const addBusinessTypeAttribute = `
mutation ($bAttributeId: [Int!]!, $bTypeId: Int!) {
   addBusinessTypeAttribute(bAttributeId: $bAttributeId, bTypeId: $bTypeId) {
      message
      status 
		}
	}
`;

const updateBusinessAttributeType = `
mutation ($bAttributeId: [Int!]!, $bAttributeTypeId: Int!, $bTypeId: Int) {
   updateBusinessAttributeType(bAttributeId: $bAttributeId, bAttributeTypeId: $bAttributeTypeId, bTypeId: $bTypeId) {
      message
      status 
		}
	}
`;

const enableDisableBusinessType = `
mutation ($businessId: Int!, $isEnable: Boolean!) {
   enableDisableBusinessType(businessId: $businessId, isEnable: $isEnable) {
      message
      status 
		}
	}
`;

const getBusinessList = `
query ($pageNumber: Int, $pageSize: Int, $countryId: Int,$sortBy: String, $sortOrder: OrderDirection, $businessName: String,$businessType:String,$category:String, $status: Boolean) {
  getBusinessList(pageNumber: $pageNumber, countryId: $countryId, pageSize: $pageSize,  sortBy: $sortBy, sortOrder: $sortOrder,businessName: $businessName,businessType:$businessType,category:$category, status: $status) {
      message
      status 
      totalCount
      id
      data{
        business_types{
          bType
          id
          isActive
        }
        countries{
          id
          name
        }
        cts
        email
        id
        name
        userId
      }
		}
	}
`;

const deleteBusiness = `
mutation ($businessId: Int!) {
  deleteBusiness(businessId: $businessId) {
      message
      status 
		}
	}
`;

const getSendCampaignMessages = `query getSendCampaignMessages($messageType: String,$isCallFromHomePage: Boolean, $userBusinessId: Int, $campaignServicesId:Int) {
  getSendCampaignMessages(messageType: $messageType, isCallFromHomePage: $isCallFromHomePage, userBusinessId: $userBusinessId,campaignServicesId:$campaignServicesId) {
    totalCount
    data {
      cts
      id
      totalCustomers
      userId
      campaignTitle
      status
      user_businesses {
        id
        name
      }
      campaign_platform {
        name
        id
      }
      campaign_services_queue {
        messages
        id
        status
        campaignPlatformId
        name
        scheduleDate
        phoneNumber
        email
        messages
        countryCode
      }
      campaign_services_standard_messages {
        id
        messages
        htmlTemplate
        htmlSubject
        campaignPlatform
        title
      }
    }
    message
    status
  }
}`;

const getBusinessBasedOnUserId = `
query{
  getBusinessBasedOnUserId{
    message
    status
    data{
      id
      name
      businssQrId
      address
      address2
    }
  }
}
`;

const campaignServiceValidation = `
query($campaignPlatformId: Int!, $userBusinessId: Int!){
  campaignServiceValidation(campaignPlatformId: $campaignPlatformId, userBusinessId:$userBusinessId){
    remainingCount
    totalAccessCount
    sendCount
    available
    message
    status
  }
}
`;

const getCampaignStandardMessages = `
query ($userBusinessId: Int, $campaignPlatformId:Int, $pageNumber: Int, $messagesBusinessSubTypeId:Int, $pageSize: Int, $messagesBusinessTypeId: Int, $callFrom: String){
  getCampaignStandardMessages(userBusinessId: $userBusinessId, campaignPlatformId: $campaignPlatformId, messagesBusinessSubTypeId: $messagesBusinessSubTypeId pageNumber: $pageNumber, pageSize: $pageSize, messagesBusinessTypeId: $messagesBusinessTypeId, callFrom: $callFrom ){
    data{
      id
      cts
      title
      messages
      htmlTemplate
      isActive
      campaignPlatformId
      messagesBusinessType
      htmlSubject
    }
    status
    message
  }
}
`;

const getAllQueueMessages = `
query ($campaignServicesId: Int!, $pageNumber:Int, $pageSize: Int){
  getAllQueueMessages(campaignServicesId:$campaignServicesId, pageNumber:$pageNumber, pageSize:$pageSize){
    data {
      campaignPlatformId
      servicesType
      messagesType
      scheduleDate
      campaign_services_standard_messages {
        htmlSubject
        htmlTemplate
        messages
      }
      campaign_services_queue {
        id
        email
        name
        countryCode
        phoneNumber
        status
        messages
      }
    }
    totalCount
    message
    status
  }
}
`;

const saveUserCSV = `
mutation saveUserCSV($file: Upload, $callFrom: String, $userBusinessId: Int, $defaultSave: Boolean) {
  saveUserCSV(file: $file, callFrom: $callFrom, userBusinessId: $userBusinessId, defaultSave: $defaultSave) {
    message
    status
  }
}
`;

const getUserCSV = `
query($callFrom: String, $userBusinessId: Int, $defaultSave:Boolean!, $searchText: String, $pageNumber: Int, $pageSize: Int){
  getUserCSV(callFrom: $callFrom, userBusinessId:$userBusinessId, defaultSave:$defaultSave, searchText: $searchText, pageNumber: $pageNumber, pageSize: $pageSize){
    data{
      allContact {
        firstName
        lastName
        countryCode
        cts
        dataFrom
        email
        id
        name
        phoneNumber
      }
      userCSVData{
        fileName
        csvContactCount
        csvImportDate
        id
      }
    }
    message
    status
    totalCount
  }
}
`;

const getUserCampaignContact = `
query ($callFrom: String
$defaultSave: Boolean!
$pageNumber: Int
$pageSize: Int
$searchText: String
$userBusinessId: Int){
getUserCampaignContact(callFrom:$callFrom
pageNumber:$pageNumber
pageSize:$pageSize
searchText:$searchText
userBusinessId:$userBusinessId
defaultSave:$defaultSave){
message
status
totalCount
data{
countryCode
cts
dataFrom
email
firstName
id
lastName
name
phoneNumber}

}}`;

const deleteCampaignContacts = `mutation($businessId: Int
$contactIds: [Int!]){deleteCampaignContacts(businessId:$businessId contactIds:$contactIds){
status
message}}`;

const getUserCSVOnCsvId = `
query($csvId: Int!){
  getUserCSVOnCsvId(csvId: $csvId){
    data{
      countryCode
      email
      name
      phoneNumber
      dataFrom
      id
    }
    message
    status
  }
}
`;

const deleteUserCsv = `
mutation ($businessId:Int! $csvIds:[Int!]!) {
  deleteUserCsv(businessId:$businessId csvIds:$csvIds){
    message
    status
  }
}
`;

const getCampaignPlatformDemoCsv = `
{
  getCampaignPlatformDemoCsv {
    status
    message
    data {
      demoCsv
    }
  }
}
`;

const getBusinessAttributeValue = `
query {
      getBusinessAttributeValue{
        message
        status
        data{
          id
          type
          reaction  
        }
    
		}
	}
`;

const createCampaignServicesStandardMessages = `
mutation($userBusinessId: Int!, $htmlTemplate: String, $title: String, $messages: String, $htmlSubject: String, $campaignPlatformId: Int!, $messagesBusinessTypeId: Int!) {
  createCampaignServicesStandardMessages(userBusinessId:$userBusinessId, htmlTemplate:$htmlTemplate,
    title: $title, messages:$messages, htmlSubject: $htmlSubject,
    campaignPlatformId:$campaignPlatformId, messagesBusinessTypeId: $messagesBusinessTypeId){
    status
    message
    data{
      id
    }
  }
}
`;

const getCampaignServicesMessagesBusinessType = `
{
  getCampaignServicesMessagesBusinessType {
    status
    message
    data {
       id
      type
      icon
      isSubCategory
    }
  }
}
`;

const getAppSetupCard = `
query ($businessId: Int!, $type: String!) {
  getAppSetupCard(businessId : $businessId, type: $type){
    appSetUpCardId
    cts
    data {
      backgroundColor
      portalURL
      button
      buttonAction
      buttonColor
      cardName
      cardType
      cts
      description
      id
      image
      isActive
      isAdded
      isOfflineSupport
      isOnlineSupport
      physicalIndex
      onlineIndex
      title
      uts
      videoUrl
    }
    id
    message
    status
  }
}
`;

const getBusinessWhatsNewCardV1 = `
query ($businessId: Int, $domain: String, $pageNumber: Int, $pageSize: Int, $productType: String, $productTypeId: Int) {
  getBusinessWhatsNewCardV1(businessId : $businessId, domain : $domain, pageNumber : $pageNumber, pageSize: $pageSize, productType: $productType, productTypeId: $productTypeId){
    cts
    data {
      cardJson
      cts
      id
      quickTypeId
    }
    id
    message
    status
  }
}
`;

const searchCampaignContact = `query ($searchText: String!, $callFrom: String) {
  searchCampaignContact(
    searchText: $searchText
    callFrom: $callFrom
  ) {
    status
    message
    data {
      name
      email
      phoneNumber
      countryCode
    }
  }
}`;

const getOnlineProductDefaultTemplate = `
query getOnlineProductDefaultTemplate($genericName: String!, $productUrl: String!, $profileUrl: String, $type: String!, $reviewPlatformsId: Int) {
  getOnlineProductDefaultTemplate(genericName: $genericName, productUrl: $productUrl, profileUrl: $profileUrl, type: $type, reviewPlatformsId: $reviewPlatformsId){
    data {
    htmlTemplate
    id
    isActive
    text
    type
    }
    id
    message
    status
  }
}
`;

const sendOnlineMessageAndEmail = `mutation sendOnlineMessageAndEmail($amazonProductId: Int, $campaignPlatformId: Int!, $countryCode: String, $email: String, $emailTemplate: String, $flipkartProductId: Int, $phoneNumber: Float, $smsTemplate: String, $userBusinessId: Int) {
sendOnlineMessageAndEmail(amazonProductId: $amazonProductId, campaignPlatformId: $campaignPlatformId, countryCode: $countryCode, email: $email, emailTemplate: $emailTemplate, flipkartProductId: $flipkartProductId, phoneNumber: $phoneNumber, smsTemplate: $smsTemplate, userBusinessId: $userBusinessId) {
message
status
}}`;

const deleteQuickSocialSchedulePost = `mutation deleteQuickSocialSchedulePost($schedulePostId: Int!) {
deleteQuickSocialSchedulePost(schedulePostId: $schedulePostId) {
message
status
}}`;

const saveAndUpdateCampaignDraft = `mutation saveAndUpdateCampaignDraft(
  $businessId: Int!
  $campaignName: String!
  $isSchedule: Boolean
  $scheduleDate: DateTime
  $campaignPlatformId: Int
  $campaignServicesStandardMessageId: Int
  $queue: [CampaignDraftQueue!]
  $csvIds: [Int!]
  $campaignDraftId: Int,
  $updatedTemplate: UpdatedCampaignTemplat
){
  saveAndUpdateCampaignDraft(
    businessId: $businessId
    campaignName: $campaignName
    isSchedule: $isSchedule
    scheduleDate: $scheduleDate
    campaignPlatformId: $campaignPlatformId
    campaignServicesStandardMessageId: $campaignServicesStandardMessageId,
    queue: $queue,
    csvIds: $csvIds,
    campaignDraftId: $campaignDraftId,
    updatedTemplate: $updatedTemplate
  ) {
    status
    message
    data {
      id
    }
  }
}
`;

const sendCampaignMessagesV1 = `mutation sendCampaignMessagesV1(
  $businessId: Int!,
  $queue: [QueueList!],
  $campaignPlatformId: Int!,
  $isSchedule: Boolean,
  $campaignServicesStandardMessageId: Int,
  $campaignServicesId: Int,
  $isFreeTrial: Boolean
) {
  sendCampaignMessagesV1(
    businessId: $businessId,
    queue: $queue,
    campaignPlatformId: $campaignPlatformId,
    isSchedule: $isSchedule,
    campaignServicesStandardMessageId: $campaignServicesStandardMessageId,
    campaignServicesId: $campaignServicesId,
    isFreeTrial: $isFreeTrial
  ) {
    message
    status
  }
}`;

const sendCampaignWhatsAppMessagesV1 = `mutation sendCampaignWhatsAppMessagesV1(
  $businessId: Int!,
  $queue: [QueueList!],
  $campaignPlatformId: Int!,
  $isSchedule: Boolean,
  $whatsAppTemplateId: Int,
  $campaignServicesId: Int,
  $isFreeTrial: Boolean
) {
  sendCampaignWhatsAppMessagesV1(
    businessId: $businessId,
    queue: $queue,
    campaignPlatformId: $campaignPlatformId,
    isSchedule: $isSchedule,
    whatsAppTemplateId: $whatsAppTemplateId,
    campaignServicesId: $campaignServicesId,
    isFreeTrial:$isFreeTrial,
  ) {
    message
    status
  }
}
`;

const getCampaignServicesMessagesBusinessSubType = `query getCampaignServicesMessagesBusinessSubType (
   $campaignBusinessTypeId: Int!
   $countryId: Int!
  ) {
    getCampaignServicesMessagesBusinessSubType(campaignBusinessTypeId: $campaignBusinessTypeId, countryId: $countryId){
      message
      status
      data{
       icon
       id
       isSubCategory
       type
    }  
  }
}
`;

const checkCampaignNameAvailable = `query checkCampaignNameAvailable(
  $businessId: Int!
  $campaignName: String!
){
  checkCampaignNameAvailable(
    businessId: $businessId
    campaignName: $campaignName
  ) {
    status
    message
    data {
      isAvailable
    }
  }
}`;

const uploadImage = `
mutation ($input: [UploadImageDto!]) {
  uploadImage(input: $input) {
      message
      status 
      data {
        imageUrl
        imageName
      }
		}
	}
`;

const updateCampaignServicesStandardMessages = `mutation ($campaignPlatformId: Int!, $title: String, $messagesBusinessTypeId: Int!, $htmlTemplate: String,
$htmlSubject: String, $userBusinessId: Int!, $campaignStandardMessageId: Int!, $messages: String){
 updateCampaignServicesStandardMessages(
   campaignPlatformId: $campaignPlatformId
   title: $title
   messagesBusinessTypeId: $messagesBusinessTypeId
   htmlTemplate: $htmlTemplate
   htmlSubject: $htmlSubject
   userBusinessId: $userBusinessId
   campaignStandardMessageId: $campaignStandardMessageId
   messages: $messages
 ) {
   message
   status
 }
}`;

const deleteCampaignServicesStandardMessages = `mutation ($userBusinessId: Int!, $campaignStandardMessageId: Int!){
 deleteCampaignServicesStandardMessages(
   userBusinessId: $userBusinessId
   campaignStandardMessageId: $campaignStandardMessageId
 ) {
   message
   status
 }
}`;

const businessData = {
  getAllBusinessType,
  addBusinessType,
  updateBusinessType,
  getAllBusinessAttributes,
  addBusinessAttributes,
  updateBusinessAttribute,
  getAllBusinessTypeAttributes,
  addBusinessTypeAttribute,
  updateBusinessAttributeType,
  enableDisableBusinessType,
  getBusinessList,
  deleteBusiness,
  getSendCampaignMessages,
  getBusinessBasedOnUserId,
  campaignServiceValidation,
  getCampaignStandardMessages,
  getAllQueueMessages,
  saveUserCSV,
  getUserCSV,
  getUserCampaignContact,
  getUserCSVOnCsvId,
  deleteUserCsv,
  getCampaignPlatformDemoCsv,
  getBusinessAttributeValue,
  createCampaignServicesStandardMessages,
  getCampaignServicesMessagesBusinessType,
  getAppSetupCard,
  getBusinessWhatsNewCardV1,
  searchCampaignContact,
  sendCampaignWhatsAppMessagesV1,
  getOnlineProductDefaultTemplate,
  sendOnlineMessageAndEmail,
  deleteQuickSocialSchedulePost,
  sendCampaignMessagesV1,
  saveAndUpdateCampaignDraft,
  deleteCampaignContacts,
  checkCampaignNameAvailable,
  getCampaignServicesMessagesBusinessSubType,
  uploadImage,
  deleteCampaignServicesStandardMessages,
  updateCampaignServicesStandardMessages,
};

export default businessData;
