const getAdsCampaign = `query ($quickHubAdsAccountId: Int  $campaignStatusFilter: String
$pageNumber: Int
$pageSize: Int $searchText: String){
    getAdsCampaign(quickHubAdsAccountId:$quickHubAdsAccountId campaignStatusFilter:$campaignStatusFilter pageNumber:$pageNumber pageSize:$pageSize searchText: $searchText) {
      status
      message
      totalCount
      data {
      ads{
        id

        cts
        uts
        name
        status
        title
        description
        quickHubAdsAccountId
        quickHubAdsObjectiveId
        quickHubAdsAudienceSegment
        cta
        destinationId
        destinationDetails {
          link
          phoneNumber
          appId
          appStoreUrl
          adsInstantFormId
        }
        adFreqCap
        budget {
          budgetType
          totalBudget
          bidAmount
          bidStrategy
        }
        scheduleDate
        endDate
        mediaUrl
        shareOnFacebook
        shareOnInstagram
        campaignOverview {
          objective
          genderSelected
          maxAge
          minAge
          geoLocation {
            countries
            cities {
              key
              name
            }
          }
          interest {
            id
            name
          }
        }
        adsSchedule {
          days
          start_minute
          end_minute
        }}
      }
    }
  }`;

const getAdsConnectApis = `query ($businessId:Int)
  { getAdsConnectApis (businessId:$businessId){
        message,
        status,
        data 
          {
            name
            title
            isConnect
            image
            bgImage
            buttonName
          }
      }}`;

const verifyMetaAdsCode = `mutation ($accessToken: String!) {
        verifyMetaAdsCode(
          accessToken: $accessToken
        ) {
          status
          message
          data
          errorMessage
          errorCode
        }
      }`;

const checkInstagramConnectivity = `mutation ($accessToken: String! $pageId: String! $userBusinessId:Int!) {
        checkInstagramConnectivity(
          pageId:$pageId
          accessToken: $accessToken
          userBusinessId:$userBusinessId
        ) {
          status
          message
          isConnected
          errorMessage
          errorCode
        }
      }`;

const saveMetaAdsDetails = `mutation ($data: String!){
  saveMetaAdsDetails(data:$data){
  status
  message
  }
  }`;

const getQuickAdsObjective = `query {
    getQuickAdsObjective {
      status
      message
      data {
        id
        name
        value
        icon
        description
        objectiveDestinationIds
      }
    }
  }`;

const getQuickAdsObjectiveDestination = `query ($objectiveDestinationIds: [Int!]!) {
  getQuickAdsObjectiveDestination(objectiveDestinationIds: $objectiveDestinationIds) {
    status
    message
    data {
      id
      name
      icon
      destinationCta {
        label
        action
        isDefault
      }
    }
  }
}`;

const searchAdsTargetingInterest = `query ($searchText: String!
$userBusinessId: Int!) {
  searchAdsTargetingInterest(searchText:$searchText, userBusinessId: $userBusinessId) {
    status
    message
     data {
      interests{
        id
        name
        type
      }
       behaviors{
        id
        name
        type
      }
    }
  }
}`;

const searchAdsGeolocation = `query ($searchText: String!
$userBusinessId: Int!){
  searchAdsGeolocation(searchText:$searchText, userBusinessId:$userBusinessId){
    status
    message
    data {
      id
      key
      name
      type
      country_code
      country_name
      region
    }
  }
}`;

const createAndUpdateAdsCampaign = `mutation ($userBusinessId: Int!, $adDraftId: Int, $campaign: QuickAdsCampaign, $adSets: QuickAdsSet, $adCreative: QuickAdsCreative) {
  createAndUpdateAdsCampaign(
    userBusinessId: $userBusinessId
    adDraftId: $adDraftId
    campaign: $campaign
    adSets: $adSets
    adCreative: $adCreative
  ) {
    status
    message
    errorMessage
    url
    data {
      adDraftId
    }
  }
}`;

const getAdsPreview = `query ($adsCampaignId: Int!
$viewFor: String!) {
  getAdsPreview(adsCampaignId:$adsCampaignId , viewFor:$viewFor){
    status
    message
    data {
      previewUrl
      adFormat
    }
  }
}`;

const publishAds = `mutation ($adsCampaignId: Int!) {
  publishAds(adsCampaignId: $adsCampaignId) {
    status
    message
  }
}`;

const getQuickAdsLeads = `query ($businessId:Int, $status:[String!], $quickHubAdsCampaignId:Int, $pageSize:Int, $pageNumber:Int, $searchText:String, $campaignStatus:[String!]) {
  getQuickAdsLeads(businessId: $businessId, status:$status, quickHubAdsCampaignId:$quickHubAdsCampaignId, pageSize:$pageSize, pageNumber:$pageNumber, searchText:$searchText, campaignStatus:$campaignStatus) {
    data {
      id
      createdTime
      shareOnFacebook
      shareOnInstagram
      status{
        key
        viewName
      }
      mediaUrl
      formData {
        name
        value
      }
    }
    totalCount
    message
    status
  }
}
   `;

const getQuickAdsLeadsByLeadId = `query($leadId: Int!) {
  getQuickAdsLeadsByLeadId(leadId: $leadId) {
    data {
      id
      shareOnFacebook
      shareOnInstagram
       status{
        key
        viewName
      }
      mediaUrl
      formData {
        name
        value
      }
      campaignName
      followUp
      sendNotification
      activity {
        activity
        cts
        id
      }
      notes {
        note
        cts
        id
      }
    }
    message
    status
  }
}`;

const updateQuickAdsLeadsStatus = `mutation (
    $leadId: Int!
    $status: String
    $followUp: DateTime
    $sendNotification: Boolean! = false
  ) {
  updateQuickAdsLeadsStatus(
    leadId: $leadId 
    status: $status
    followUp: $followUp
    sendNotification: $sendNotification
  ) {
    message
    status
  }
}`;

const createOrUpdateLeadNote = `mutation ($note:String, $leadId:Int, $leadNoteId:Int) {
  createOrUpdateLeadNote(note:$note, leadId:$leadId, leadNoteId:$leadNoteId){
    message
    status
  }
}`;

const deleteQuickAdsLeadNote = `mutation ($leadNoteId:Int){
  deleteQuickAdsLeadNote(leadNoteId:$leadNoteId){
    message
    status
  }
}`;

const getQuickAdsStatus = `query{
  getQuickAdsStatus{
    data{
      key
     viewName 
    }
    message
    status
  }
}`;

const getAdsOverallMetrics = `query ($userBusinessId:Int!) {
  getAdsOverallMetrics(userBusinessId:$userBusinessId)
  {
    data{
      brandHealthData{
        budget{
          key
          value
          viewName
          
        }
        gender{
          key
          value
        }
        metrics{
          icon
          key
          value
          viewName
        }
        topCampaign{
          clicks
          name
        }
        topPlacement{
          key
          percent
          value
        }
      }
    }
    message
    status
  }
}`;

const disconnectAds = `mutation($quickHubAdsAccountId:Int!) {
  disconnectAds(quickHubAdsAccountId: $quickHubAdsAccountId){
    status
    message
  }
}`;

const getMetaAdsDetails = `query ($userBusinessId:Int!){
  getMetaAdsDetails(userBusinessId:$userBusinessId){
    status
    message
    data
  }
}`;

const verifyWhatsAppNumberForDestination = `mutation($countryCode:String! $phoneNumber:String! $quickHubAdsAccount:Int! $verificationCode:String) {
  verifyWhatsAppNumberForDestination(
    countryCode: $countryCode
    phoneNumber: $phoneNumber
    quickHubAdsAccount: $quickHubAdsAccount
    verificationCode:$verificationCode
  ) {
    status
    message

  }
}`;

const pauseOrDeleteAdsCampaign = `mutation ($adsCampaignId:Int! $operation:String!){
  pauseOrDeleteAdsCampaign(adsCampaignId: $adsCampaignId, operation:$operation) {
    status
    message
  }
}`;

const getMetaAdGeolocation = `query (
    $userBusinessId: Int!
    $type:  String!
    $values: [String!]!
  ) {
  getMetaAdGeolocation(
    userBusinessId: $userBusinessId
    type: $type
    values: $values
  ) {
    status
    message
    data {
      countryCode
      countryName
      key
      latitude
      longitude
      name
      region
      regionId
      supportsCity
      supportsRegion
      type
    }
  }
}`;

const quickAdsQuery = {
  getAdsCampaign,
  getAdsConnectApis,
  verifyMetaAdsCode,
  checkInstagramConnectivity,
  saveMetaAdsDetails,
  getQuickAdsObjective,
  searchAdsTargetingInterest,
  searchAdsGeolocation,
  createAndUpdateAdsCampaign,
  getQuickAdsObjectiveDestination,
  getAdsPreview,
  publishAds,
  getQuickAdsLeads,
  getQuickAdsLeadsByLeadId,
  updateQuickAdsLeadsStatus,
  createOrUpdateLeadNote,
  deleteQuickAdsLeadNote,
  getQuickAdsStatus,
  getAdsOverallMetrics,
  disconnectAds,
  getMetaAdsDetails,
  verifyWhatsAppNumberForDestination,
  pauseOrDeleteAdsCampaign,
  getMetaAdGeolocation,
};
export default quickAdsQuery;
