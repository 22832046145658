import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { selectDashboardData } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import ShareIcon from '@mui/icons-material/Share';
import SharePost from '../quick-post/SharePost';

const OverallReports = () => {
  const location = useLocation();
  const { state } = location || {};
  const [openShare, setOpenShare] = useState(false);
  const [pdfUrl, setPdfUrl] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const formatTimestampToPDF = (timestamp) => {
    const date = new Date(timestamp * 1000);
    date.setMonth(date.getMonth() - 1);
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();
    return `${month}-${year}.pdf`;
  };

  const handleViewPDF = (url) => {
    window.open(url, '_blank');
  };

  const handleCloseDeleteDialog = () => {
    setOpenShare(false);
  };

  const sortedReports = state?.monthlyBusinessReport?.sort((a, b) => b.timestamp - a.timestamp);

  return (
    <div className="lg:p-24 p-16 l-padding bg-gray-A500 h-full">
      <h1 className="text-24 md:text-28 font-bold mb-32">
        {t('waba.dashboard.businessOverallReports')}
      </h1>
      {sortedReports?.length > 0 ? (
        <div>
          <h1 className="text-16 md:text-20 font-bold mb-20">{t('waba.dashboard.latestReport')}</h1>
          <div className="bg-white rounded-md max-w-586 items-center mb-20 px-14 py-6 flex justify-between">
            <div className="flex gap-10 items-center">
              <img src="/assets/images/icon/pdf.svg" className="w-48" alt="pdf" />
              <Typography className="text-20 font-600">
                {formatTimestampToPDF(sortedReports[0]?.timestamp)}
              </Typography>
            </div>
            <div className="flex gap-28">
              <Typography
                className="bg-gray-A500 rounded-full p-5 cursor-pointer"
                onClick={() => handleViewPDF(sortedReports[0]?.url)}
              >
                <VisibilityOutlinedIcon className="text-grey-600" />
              </Typography>
              <Typography
                className="bg-gray-A500 rounded-full p-5"
                onClick={() => {
                  setPdfUrl(sortedReports[0]?.url);
                  setOpenShare(true);
                }}
              >
                <ShareIcon className="text-grey-600 cursor-pointer" />
              </Typography>
            </div>
          </div>

          <h1 className="text-16 md:text-20 font-bold mb-20">
            {t('waba.dashboard.previousReports')}
          </h1>
          <div className="flex flex-col gap-20">
            {sortedReports?.map((item) => (
              <div
                key={item?.timestamp}
                className="bg-white max-w-586 items-center px-14 py-6 flex rounded-md justify-between"
              >
                <div className="flex gap-10 items-center">
                  <img className="w-48" src="/assets/images/icon/pdf.svg" alt="pdf" />
                  <Typography className="text-20 font-600">
                    {formatTimestampToPDF(item?.timestamp)}
                  </Typography>
                </div>
                <div className="flex gap-28">
                  <Typography
                    className="bg-gray-A500 rounded-full p-5 cursor-pointer"
                    onClick={() => handleViewPDF(item?.url)}
                  >
                    <VisibilityOutlinedIcon className="text-grey-600" />
                  </Typography>
                  <Typography
                    onClick={() => {
                      setPdfUrl(item?.url);
                      setOpenShare(true);
                    }}
                    className="bg-gray-A500 rounded-full p-5"
                  >
                    <ShareIcon className="text-grey-600 cursor-pointer" />
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="w-full h-fit mt-120 gap-10 flex justify-center items-center flex-col">
          <img src="/assets/images/business/noOverallReport.svg" width={200} alt="Not Found" />
          <Typography className="text-24 md:text-28 font-bold">
            {t('waba.dashboard.noReportTitle')}
          </Typography>
          <Typography className="text-20 max-w-2xl text-center md:text-24 text-grey-500">
            {t('waba.dashboard.noReportDesc')}
          </Typography>
        </div>
      )}
      {openShare && pdfUrl && (
        <SharePost
          open={openShare}
          onClose={handleCloseDeleteDialog}
          image={pdfUrl}
          businessId={userCurrentBusinessDetails?.id}
          plateFormID={location?.state}
        />
      )}
    </div>
  );
};

export default OverallReports;
