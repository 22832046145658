import React, { useCallback, useEffect, useRef, useState } from 'react';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import history from '@history';
import { useSnackbar } from 'notistack';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from 'react-redux';
import { getAllUserBusinessSubscription, selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useLocation, useParams } from 'react-router-dom';
import TitleIcon from '@mui/icons-material/Title';
import SatelliteOutlinedIcon from '@mui/icons-material/SatelliteOutlined';
import {
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Slider,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { ChromePicker } from 'react-color';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import FormatAlignRightOutlinedIcon from '@mui/icons-material/FormatAlignRightOutlined';
import FormatAlignCenterOutlinedIcon from '@mui/icons-material/FormatAlignCenterOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import FormatBoldOutlinedIcon from '@mui/icons-material/FormatBoldOutlined';
import FormatUnderlinedOutlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatItalicOutlinedIcon from '@mui/icons-material/FormatItalicOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import FormatSizeOutlinedIcon from '@mui/icons-material/FormatSizeOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FuseLoading from '@fuse/core/FuseLoading';
import { display } from '@mui/system';
import AddDescription from './AddDescription';
import manageBusinessData from '../../query/manageBusiness';
import { handleApiRequest, storeImageInServer } from '../../common/common';
import SchedulePopUp from './SchedulePopUp';
import SharePost from '../quick-post/SharePost';
import businessData from '../../query/business';
import quickSocialQuery from '../../query/quickSocial';
import quickPostQuery from '../../query/quickPost';

const editable = [
  {
    key: 'review',
    value: '',
  },
  {
    key: 'rating',
    value: '',
  },
  {
    key: 'reviewer',
    value: '',
  },
  {
    key: 'location',
    value: '',
  },
  {
    key: 'platformLogo',
    value: '',
  },
];

const Post = () => {
  const params = useParams();
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [isBtLoading, setIsBtLoading] = useState(false);
  const [suggestion, setSuggestion] = useState('');
  const [url, setURL] = useState('');
  const iframeRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [tabs, setTabs] = useState('text');
  const [extractedData, setExtractedData] = useState(null);
  const fileInputRefLogo = useRef([]);
  const [lastUpdatedItem, setLastUpdatedItem] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [editableDes] = useState('');
  const [openDialogueForSchedule, setOpenDialogueForSchedule] = useState(false);
  const [openTemplateChangePopup, setOpenTemplateChangePopup] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const location = useLocation();
  const { data, socialId, reviewTemplate, PFlogo, plateFormID, from } = location?.state || {};
  const [showNextDiv, setShowNextDiv] = useState(false);
  const [editableTexts, setEditableTexts] = useState(
    data?.editableText || data?.share_post_template?.editableText || editable || []
  );
  const [template, setTemplate] = useState(
    data?.template ??
      decodeURIComponent(data?.html) ??
      data?.htmlTemplate ??
      data?.share_post_template?.template ??
      ''
  );
  const [styling, setStyling] = useState();
  const [modifiedCards, setModifiedCards] = useState(new Array(editableTexts?.length).fill(false));
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [socialData, setSocialData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isChecked, setIsChecked] = useState(socialData?.map((platform) => platform?.isConnected));
  const [isHovered, setIsHovered] = useState(false);
  const [color, setColor] = useState('#ff0000');
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);
  const [scheduledDate, setScheduledDate] = useState(
    data?.scheduledDate ? new Date(data?.scheduledDate) : null
  );
  const [scheduledTime, setScheduledTime] = useState(
    data?.scheduledDate ? new Date(data?.scheduledDate) : null
  );
  const [refresh, setRefresh] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [description, setDescription] = useState(data?.postData?.caption || '');
  const [imageUrl, setImageUrl] = useState();
  const imagePath = url;

  useEffect(() => {
    setIsNextEnabled(description?.length > 0);
  }, [description]);

  const getSelectedReviewPlatformIds = () => {
    return socialData
      ?.filter(
        (platform, index) => isChecked[index] && (template || platform?.reviewPlatformId !== 10)
      )
      ?.map((platform) => platform?.reviewPlatformId);
  };

  useEffect(() => {
    if (!url && socialData?.length) {
      setIsChecked((prevChecked) => {
        const instagramIndex = socialData.findIndex((p) => p?.reviewPlatformId === 10);
        if (instagramIndex !== -1 && prevChecked[instagramIndex]) {
          const updatedChecked = [...prevChecked];
          updatedChecked[instagramIndex] = true;
          return updatedChecked;
        }
        return prevChecked;
      });
    }
  }, [url, socialData]);

  useEffect(() => {
    const socialMediaData = async () => {
      try {
        const payload = {
          query: quickSocialQuery.getQuickSocialChannelWiseData,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            pageNumber: 1,
            pageSize: 10,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQuickSocialChannelWiseData?.status === 200) {
          setSocialData(result?.getQuickSocialChannelWiseData?.data);
        }
      } catch (error) {
        console.error('Error regenerating suggestion:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      socialMediaData();
    }
  }, [userCurrentBusinessDetails]);

  useEffect(() => {
    const initialCheckedState = socialData?.map((platform) => platform?.isConnected);
    setIsChecked(initialCheckedState);
  }, [socialData]);

  const handleFileInputClick = (item) => {
    if (item?.type === 'image') {
      fileInputRefLogo.current[item?.key]?.click();
    }
  };

  const handleEditClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const updateTemplateStyles = useCallback(
    (htmlTemplate, currentEditObject) => {
      if (!htmlTemplate || !currentEditObject?.key) return htmlTemplate;

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlTemplate, 'text/html');
      const element = doc.getElementById(currentEditObject.key);

      if (element) {
        const newData = styling[currentEditObject.key];

        if (element.tagName === 'IMG') {
          element.src = newData?.src || '';
          element.style.width = `${
            newData?.width || editableTexts?.find((item) => item?.key === 'width')?.value
          }px`;
          // element.style.height = newData?.height
          //   ? `${newData.width}px`
          //   : editableTexts?.find((item) => item?.key === 'height')?.value;
          element.style.left = newData?.left
            ? `${newData.left}px`
            : editableTexts?.find((item) => item?.key === 'left')?.value;
          element.style.visibility = newData?.visibility || 'visible';
          element.style.display = newData?.display || 'flex';
          element.style.transform = `translate(${newData?.xPosition || 0}px, ${
            newData?.yPosition || 0
          }px)`;
        } else {
          element.style.fontSize = `${
            newData?.fontSize || editableTexts?.find((item) => item?.key === 'fontSize')?.value
          }px`;
          element.style.fontWeight = `${
            newData?.fontWeight || editableTexts?.find((item) => item?.key === 'fontWeight')?.value
          }`;
          element.style.fontStyle =
            newData?.fontStyle || editableTexts?.find((item) => item?.key === 'fontStyle')?.value;
          const textDecoration = [];
          if (newData?.underLine === 'underline') textDecoration.push('underline');
          if (newData?.lineThrough === 'line-through') textDecoration.push('line-through');
          element.style.textAlign = newData?.textAlign || 'left';
          element.style.textDecoration = textDecoration.join(' ') || 'none';
          element.style.textTransform =
            newData?.textTransform ||
            editableTexts?.find((item) => item?.key === 'textTransform')?.value;
          element.style.color =
            newData?.color || editableTexts?.find((item) => item?.key === 'color')?.value;
          element.style.backgroundColor =
            newData?.backgroundColor ||
            editableTexts?.find((item) => item?.key === 'backgroundColor')?.value;
          element.style.backgroundImage =
            `url(${newData?.src})` ||
            `url(${editableTexts?.find((item) => item?.key === 'backgroundImage')?.value})`;
          element.style.width = `${
            newData?.width || editableTexts?.find((item) => item?.key === 'width')?.value
          }px`;
          element.style.height = newData?.height
            ? `${newData.height}px`
            : editableTexts?.find((item) => item?.key === 'height')?.value;
          element.style.left = newData?.left
            ? `${newData.left}px`
            : editableTexts?.find((item) => item?.key === 'left')?.value;
          element.style.visibility = newData?.visibility || 'visible';
          element.style.display = newData?.display || 'flex';
          const translateX = newData?.translateX || 0;
          const translateY = newData?.translateY || 0;
          element.style.transform = `translate(${newData?.xPosition || 0}px, ${
            newData?.yPosition || 0
          }px)`;
        }

        return doc.documentElement.outerHTML;
      }

      return htmlTemplate;
    },
    [editableTexts, styling]
  );

  const updateStyling = (key, property, value) => {
    setStyling((prevStyling) => {
      const updatedStyling = {
        ...prevStyling,
        [key]: {
          ...prevStyling[key],
          [property]: value,
        },
      };

      setLastUpdatedItem({ key });

      const updatedTemplate = updateTemplateStyles(template, {
        key,
        ...updatedStyling[key],
        textAlign: value,
      });
      setTemplate(updatedTemplate);
      return updatedStyling;
    });
  };

  useEffect(() => {
    if (!template || !lastUpdatedItem) return;

    const updatedTemplate = updateTemplateStyles(template, lastUpdatedItem);
    setTemplate(updatedTemplate);
  }, [lastUpdatedItem, styling, template, updateTemplateStyles]);

  const updateHtmlTemplate = useCallback(
    (htmlTemplate) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlTemplate, 'text/html');

      const elements = doc.querySelectorAll('*');
      const initialStyles = {};

      elements.forEach((element) => {
        let key = null;
        let matched = false;

        const textMatch = element.textContent.match(/###(.*?)###/);
        if (textMatch && !['HTML', 'BODY', 'DIV', 'UL'].includes(element.tagName)) {
          key = textMatch[1].trim();
          matched = true;
        }

        if (element.tagName === 'IMG' && element.src.includes('###')) {
          const srcMatch = element.src.match(/###(.*?)###/);
          if (srcMatch) {
            key = srcMatch[1].trim();
            matched = true;
          }
        }

        const bgImage = element.style.backgroundImage;
        if (bgImage && bgImage.includes('###')) {
          const bgMatch = bgImage.match(/###(.*?)###/);
          if (bgMatch) {
            key = bgMatch[1].trim();
            matched = true;
          }
        }

        if (matched && key) {
          element.setAttribute('id', key);

          const translateX = 0;
          const translateY = 0;
          initialStyles[key] = {
            id: key,
            fontSize: Number(String(element.style.fontSize || 'inherit').replace('px', '')) || 12,
            fontWeight: element.style.fontWeight || 'normal',
            src: element.style.src || editableTexts?.find((item) => item?.key === key)?.value,
            fontStyle: element.style.fontStyle || 'normal',
            underLine: element.style.textDecoration.includes('underline') ? 'underline' : 'normal',
            lineThrough: element.style.textDecoration.includes('line-through')
              ? 'line-through'
              : 'normal',
            textAlign: element.style.textAlign || 'left',
            textTransform: element.style.textTransform || 'capitalize',
            color: element.style.color || 'inherit',
            backgroundColor: element.style.backgroundColor || 'transparent',
            backgroundImage: element.style.backgroundImage,
            width: Number(String(element.style.width || 'inherit').replace('px', '')) || 'auto',
            height: element.style.height || 'auto',
            left: element.style.left || 'auto',
            display: element.style.display || 'flex',
            visibility: element.style.visibility || 'visible',
            position: element.style.position || 'static',
            transform: `translate(${translateX}px, ${translateY}px)`,
            wordBreak: element.style.wordBreak || 'normal',
          };
        }
      });

      setStyling(initialStyles);

      return { updatedTemplate: doc.documentElement.outerHTML, initialStyles };
    },
    [editableTexts]
  );

  useEffect(() => {
    if (!data?.template) return;

    const { updatedTemplate } = updateHtmlTemplate(data.template);
    let modifiedTemplate = updatedTemplate;

    editableTexts?.forEach(({ key, value }) => {
      const textValue = typeof value === 'string' ? value : value?.toString() || '';
      const placeholder = new RegExp(`###${key}###`, 'g');
      modifiedTemplate = modifiedTemplate.replace(placeholder, textValue);
    });

    setTemplate(modifiedTemplate);
  }, [data?.template, editableTexts, updateHtmlTemplate]);

  const saveEditableText = useCallback(
    async (editableTex) => {
      if (
        !data?.share_post_template?.template &&
        !data?.html &&
        !data?.template &&
        !data?.htmlTemplate
      ) {
        return;
      }
      try {
        setIsBtLoading(true);
        const payload = {
          query: manageBusinessData.getConvertedSharePostImage,
          variables: {
            htmlTemplate: encodeURIComponent(
              template ||
                data?.template ||
                data?.htmlTemplate ||
                data?.share_post_template?.template ||
                decodeURIComponent(data?.html)
            ),
            editableText: editableTex,
            review: editableTex.find((item) => item?.key === 'review')?.value || '',
            reviewRating: editableTex.find((item) => item?.key === 'rating')?.value?.length || null,
            reviewerName: editableTex.find((item) => item?.key === 'reviewer')?.value || '',
            businessName: editableTex.find((item) => item?.key === 'location')?.value || '',
            reviewPlatformTypeId: plateFormID || null,
            isHtmlTemplate: true,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getConvertedSharePostImage?.status === 200) {
          setEditableTexts((prev) =>
            prev?.map((text, i) =>
              i === editableTexts?.indexOf(editableTex)
                ? result?.getConvertedSharePostImage?.data
                : text
            )
          );
          setURL(result?.getConvertedSharePostImage?.data?.socialPostUrl);
        }
        setIsBtLoading(false);
      } catch (error) {
        console.error('Error regenerating suggestion:', error);
      }
    },
    [
      data?.html,
      data?.htmlTemplate,
      data?.share_post_template?.template,
      data?.template,
      editableTexts,
      plateFormID,
      template,
    ]
  );

  const handleImageUpload = async (event, index, itemKey) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64Image = e.target.result;

        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = base64Image;

        img.onload = async () => {
          setLoading(true);
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          canvas.toBlob(async (blob) => {
            if (blob) {
              const fileName = file.name.endsWith('.png') ? file.name : `${file.name}.png`;
              const fileToUpload = new File([blob], fileName, { type: 'image/png' });

              const formData = new FormData();
              formData.append('file', fileToUpload);

              const payload = {
                query: businessData.uploadImage,
                variables: {
                  input: [{ image: fileToUpload }],
                },
              };

              try {
                const res = await storeImageInServer(payload);

                if (res?.uploadImage?.status === 200) {
                  const uploadedImageUrl = res?.uploadImage?.data?.[0]?.imageUrl;
                  handleTextChange(index, uploadedImageUrl, itemKey, 'image');
                  setLoading(false);
                } else {
                  enqueueSnackbar(res?.uploadImage?.message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                  });
                }
              } catch (error) {
                console.error('Error during image upload:', error);
                enqueueSnackbar('Error uploading image. Please try again.', {
                  variant: 'error',
                  autoHideDuration: 3000,
                });
              } finally {
                setLoading(false);
              }
            } else {
              console.error('Failed to convert canvas to Blob.');
            }
          }, 'image/png');
        };

        img.onerror = (err) => {
          console.error('Failed to load the image', err);
          enqueueSnackbar('Failed to load the image. Please try again.', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        };
        setLoading(false);
      };

      reader.readAsDataURL(file);
    }
  };

  const alignmentOptions = [
    { key: 'textAlignLeft', icon: <FormatAlignLeftOutlinedIcon />, value: 'left' },
    { key: 'textAlignCenter', icon: <FormatAlignCenterOutlinedIcon />, value: 'center' },
    { key: 'textAlignRight', icon: <FormatAlignRightOutlinedIcon />, value: 'right' },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setShowPicker((prev) => !prev);
    }
  };

  useEffect(() => {
    if (reviewTemplate) {
      const updatedEditableTexts = editable?.map((item) => {
        switch (item?.key) {
          case 'review':
            if (reviewTemplate?.review) {
              return { ...item, value: reviewTemplate?.review };
            }
            break;
          case 'rating':
            if (reviewTemplate?.reviewRating || reviewTemplate?.rating) {
              const stars = '★'.repeat(reviewTemplate?.reviewRating || reviewTemplate?.rating);
              return { ...item, value: stars };
            }
            break;
          case 'reviewer':
            if (reviewTemplate?.reviewerName) {
              return { ...item, value: reviewTemplate?.reviewerName };
            }
            break;
          case 'location':
            if (userCurrentBusinessDetails?.name) {
              return { ...item, value: userCurrentBusinessDetails?.name };
            }
            break;
          case 'platformLogo':
            if (PFlogo) {
              return { ...item, value: PFlogo };
            }
            break;
          default:
            return item;
        }
        return item;
      });
      setEditableTexts(updatedEditableTexts);
    }
  }, [
    PFlogo,
    reviewTemplate,
    userCurrentBusinessDetails?.name,
    userCurrentBusinessDetails?.reviewplatform,
  ]);

  const handleSave = useCallback(
    (index) => {
      const updatedText = editableTexts[index];
      if (modifiedCards[index]) {
        saveEditableText([updatedText]);
      }
      setModifiedCards((prev) => {
        const updated = [...prev];
        updated[index] = false;
        return updated;
      });
    },
    [editableTexts, modifiedCards, saveEditableText]
  );

  const handleEditableTextDes = useCallback(
    async (index, descRip) => {
      try {
        const payload = {
          query: manageBusinessData.sharePostGenerateSummarizeReviews,
          variables: {
            review: descRip,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.sharePostGenerateSummarizeReviews?.status === 200) {
          const newDescription = result?.sharePostGenerateSummarizeReviews?.data;
          let newValue = '';
          if (typeof newDescription === 'object') {
            newValue = newDescription?.summarizeReviews;
          }
          setEditableTexts((prevTexts) =>
            prevTexts?.map((item, i) => (i === index ? { ...item, value: newValue } : item))
          );
          handleSave(index);
        } else {
          enqueueSnackbar(result?.sharePostGenerateSummarizeReviews?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('Error regenerating suggestion:', error);
      }
    },
    [enqueueSnackbar, handleSave]
  );
  const handleShare = useCallback(async () => {
    const payloadData = {
      query: quickPostQuery.saveViewedPosts,
      variables: {
        userBusinessesId: Number(userCurrentBusinessDetails?.id),
        sharePostTemplateId: data?.id,
        editableText: editableTexts,
      },
    };
    await handleApiRequest(payloadData);
  }, [data?.id, editableTexts, userCurrentBusinessDetails?.id]);

  const handleDirectPost = async () => {
    setIsBtLoading(true);
    const createPayload = (type) => {
      switch (type) {
        case 'facebook':
          return {
            query: manageBusinessData.postOnFacebookChannel,
            variables: {
              image: url,
              postType: url === '' ? 'text' : 'image',
              text: description,
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            },
            responseKey: 'postOnFacebookChannel',
          };
        case 'instagram':
          if (!url) {
            console.warn('Instagram post requires a URL. Skipping Instagram post creation.');
            return null;
          }
          return {
            query: manageBusinessData.createInstagramPost,
            variables: {
              isTemplate: true,
              templateData: url,
              mediaType: 'image',
              caption: description,
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            },
            responseKey: 'createInstagramPost',
          };
        case 'linkedin':
          if (!url) {
            console.warn('Linkedin post requires a URL. Skipping Linkedin post creation.');
            return null;
          }
          return {
            query: quickSocialQuery.createLinkedinPost,
            variables: {
              isTemplate: true,
              templateData: url,
              mediaType: 'image',
              caption: description,
              userBusinessId: Number(userCurrentBusinessDetails?.id),
            },
            responseKey: 'createLinkedinPost',
          };
        default:
          return null;
      }
    };

    try {
      if (
        Array.isArray(getSelectedReviewPlatformIds()) &&
        getSelectedReviewPlatformIds().includes(9)
      ) {
        const facebookPayload = createPayload('facebook');
        if (facebookPayload) {
          const result = await handleApiRequest(facebookPayload);
          const response = result?.[facebookPayload?.responseKey];
          enqueueSnackbar(response?.message, {
            variant: response?.status === 200 ? 'success' : 'error',
            autoHideDuration: 3000,
          });
          if (response?.status === 200) {
            if (socialId === 4) {
              handleShare();
            }
            history.push(`quick-social/share-post/`);
          }
          if (response?.status === 190) {
            setTimeout(() => {
              history.push(`quick-social/social-connect/`);
            }, 1000);
          }
        }
      }

      if (
        Array.isArray(getSelectedReviewPlatformIds()) &&
        getSelectedReviewPlatformIds()?.includes(10) &&
        url
      ) {
        const instagramPayload = createPayload('instagram');
        if (instagramPayload) {
          const result = await handleApiRequest(instagramPayload);
          const response = result?.[instagramPayload?.responseKey];
          enqueueSnackbar(response?.message, {
            variant: response?.status === 200 ? 'success' : 'error',
            autoHideDuration: 3000,
          });
          if (response?.status === 200) {
            if (socialId === 4) {
              handleShare();
            }
            history.push(`quick-social/share-post/`);
          }
          if (response?.status === 190) {
            setTimeout(() => {
              history.push(`quick-social/social-connect/`);
            }, 1000);
          }
        }
      }
      if (
        Array.isArray(getSelectedReviewPlatformIds()) &&
        getSelectedReviewPlatformIds()?.includes(12) &&
        url
      ) {
        const linkedinPayload = createPayload('linkedin');
        if (linkedinPayload) {
          const result = await handleApiRequest(linkedinPayload);
          const response = result?.[linkedinPayload?.responseKey];
          enqueueSnackbar(response?.message, {
            variant: response?.status === 200 ? 'success' : 'error',
            autoHideDuration: 3000,
          });
          if (response?.status === 200) {
            if (socialId === 4) {
              handleShare();
            }
            history.push(`quick-social/share-post/`);
          }
          if (response?.status === 190) {
            setTimeout(() => {
              history.push(`quick-social/social-connect/`);
            }, 1000);
          }
        }
      }
    } catch (error) {
      console.error('Error posting to platform:', error);
    }
    setIsBtLoading(false);
  };

  const handleTextChange = (index, value, itemKey, selectTabs) => {
    if (selectTabs === 'image') {
      updateStyling(itemKey, 'src', value);
    } else {
      setEditableTexts((prevEditableTexts) => {
        const updatedTexts = prevEditableTexts?.map((item, i) =>
          item?.key === itemKey ? { ...item, value } : item
        );
        return updatedTexts;
      });
    }
  };

  const handleEditableTextDesc = (index, descRip) => {
    handleEditableTextDes(index, descRip);
  };

  const handleSchedule = () => {
    setOpenDialogueForSchedule(true);
  };

  const handleNext = () => {
    setShowNextDiv(true);
    saveEditableText(editableTexts);
  };

  const handleCheckboxChange = (reviewPlatformId) => {
    const platformIndex = socialData.findIndex(
      (platform) => platform.reviewPlatformId === reviewPlatformId
    );
    if (platformIndex !== -1) {
      setIsChecked((prevState) => {
        const updatedChecked = [...prevState];
        updatedChecked[platformIndex] = !prevState[platformIndex];
        return updatedChecked;
      });
    }
  };

  const formatDateTime = (date, time) => {
    const originalDate = new Date(date);
    const formattedDat = originalDate?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [month, day, year] = formattedDat.split('/');
    const desiredFormat = `${year}-${month}-${day}`;
    const setTime = time.toLocaleTimeString();
    const dateTimeString = `${desiredFormat} ${setTime}`;
    const dateObj = new Date(dateTimeString);
    dateObj?.setHours(dateObj.getHours() + 5);
    dateObj?.setMinutes(dateObj.getMinutes() + 30);
    const offset = dateObj.getTimezoneOffset();
    const hoursOffset = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
    const minutesOffset = String(Math.abs(offset) % 60).padStart(2, '0');
    const sign = offset > 0 ? '-' : '+';
    const formattedDate = dateObj
      ?.toISOString()
      ?.replace(/:\d{2}\.\d{3}Z$/, '')
      ?.concat(`${sign}${hoursOffset}:${minutesOffset}`);
    return formattedDate;
  };

  const setSchedule = async () => {
    setIsBtLoading(true);
    setRefresh(true);
    try {
      const payload = {
        query: manageBusinessData.addQuickSocialSchedulePost,
        variables: {
          sharedChannels: getSelectedReviewPlatformIds(),
          editableText: editableTexts,
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          scheduleDate: formatDateTime(scheduledDate, scheduledTime),
          description,
          html: encodeURIComponent(template),
          pageId:
            Array.isArray(getSelectedReviewPlatformIds()) &&
            getSelectedReviewPlatformIds()?.includes(9)
              ? socialData?.find((sc) => sc?.name === 'facebook')?.pageId
              : null,
          linkedinPageId:
            Array.isArray(getSelectedReviewPlatformIds()) &&
            getSelectedReviewPlatformIds()?.includes(12)
              ? socialData?.find((sc) => sc?.name === 'linkedin')?.pageId
              : null,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.addQuickSocialSchedulePost?.status === 200) {
        if (socialId === 4) {
          handleShare();
        }
        history.push(`quick-social/schedule-post/${params?.id ? params.id : ''}`);
        enqueueSnackbar(result?.addQuickSocialSchedulePost?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setOpenDialogueForSchedule(false);
      } else {
        enqueueSnackbar(result?.addQuickSocialSchedulePost?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error regenerating suggestion:', error);
    }
    setIsBtLoading(false);
    setRefresh(false);
  };

  const setUpdateSchedule = async () => {
    try {
      setRefresh(true);
      const payload = {
        query: quickSocialQuery.updateQuickSocialSchedulePost,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          scheduleDate: formatDateTime(scheduledDate, scheduledTime) || data?.scheduleDate,
          description,
          editableText: editableTexts,
          html: encodeURIComponent(template),
          schedulePostId: data?.id,
          sharedChannels: getSelectedReviewPlatformIds(),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.updateQuickSocialSchedulePost?.status === 200) {
        history.push(`quick-social/schedule-post/`);
        enqueueSnackbar(result?.updateQuickSocialSchedulePost?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        openDialogueForSchedule(false);
      }
    } catch (error) {
      console.error('Error regenerating suggestion:', error);
    } finally {
      setRefresh(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialogueForSchedule(false);
    setOpenTemplateChangePopup(false);
    setOpenShare(false);
    setScheduledDate(null);
    setScheduledTime(null);
  };

  // const handleSharePost = () => {
  //   setOpenShare(true);
  // };

  const handleEdit = () => {
    setShowNextDiv(false);
  };

  const isDisabled = () => {
    return (
      isBtLoading ||
      !isChecked?.some((item) => item === true) ||
      (!template &&
        Array.isArray(getSelectedReviewPlatformIds()) &&
        getSelectedReviewPlatformIds()?.includes(10))
    );
  };

  const handleTemplateChange = () => {
    history.push(`/quick-social/template/${params?.id ? params?.id : ''}`);
  };

  const handleOpenShare = async (imageSrc, fileName) => {
    try {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = imageSrc;
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(async (blob) => {
          if (blob) {
            const file = new File(
              [blob],
              fileName.endsWith('.png') ? fileName : `${fileName}.png`,
              {
                type: 'image/png',
              }
            );
            const formData = new FormData();
            formData.append('file', file);
            const payload = {
              query: businessData.uploadImage,
              variables: {
                input: [{ image: file }],
              },
            };
            const res = await storeImageInServer(payload);
            if (res?.uploadImage?.status === 200) {
              setImageUrl(res?.uploadImage?.data?.[0]?.imageUrl);
              setOpenShare(true);
            } else {
              enqueueSnackbar(res?.uploadImage?.message, {
                variant: 'error',
                autoHideDuration: 3000,
              });
            }
          } else {
            console.error('Failed to convert canvas to Blob.');
          }
        }, 'image/png');
      };
      img.onerror = (err) => {
        console.error('Failed to load the image', err);
      };
    } catch (error) {
      console.error('An error occurred while uploading the image:', error);
    }
  };

  const handleImageDownload = async (imageSrc, fileName) => {
    try {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const pngDataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = pngDataUrl;
        link.download = fileName.endsWith('.png') ? fileName : `${fileName}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      img.onerror = (err) => {
        console.error('Failed to load the image', err);
      };
    } catch (error) {
      console.error('An error occurred while downloading the image:', error);
    }
  };

  const formatTimeFromDate = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${ampm}`;
  };

  return (
    <div className={`lg:p-32 p-10 `}>
      <style>
        {`
    .responsive-wrapper {
    width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
}

.responsive-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  width: 16.666%;
}

.responsive-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  width: 83.333%;
}

@media (min-width: 959px) and (max-width: 1750px) {
  .responsive-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .responsive-column {
    width: 100%;
  }

  .responsive-container {
    width: 100%;
  }
}

@media (max-width: 959px), (min-width: 1751px) {
  .responsive-wrapper {
    flex-direction: row;
  }

  .responsive-column {
    width: 16.666%; /* 2/12 */
  }

  .responsive-container {
    width: 83.333%;
  }
}

  `}
      </style>
      {!showNextDiv && (
        <div>
          <Toolbar className="px-0 font-semibold min-h-fit mb-20">
            {location?.state?.passFrom === 'Template' && (
              <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
                west
              </Icon>
            )}
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 px-12 font-bold text-18"
            >
              {location?.state?.passFrom === 'Template'
                ? t('quickSocialPost.title1')
                : t('quickSocialPost.title')}
            </Typography>
          </Toolbar>

          <div className="w-full justify-center flex flex-col md:flex-row gap-14">
            <div className="w-full md:w-[675px] items-center flex-shrink-0 flex-grow-0 relative md:sticky top-0">
              {data ? (
                <>
                  <div className="bg-white overflow-hidden mb-10 rounded-md">
                    <div>
                      {(data?.template || template) && (
                        <div
                          className="relative"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          {template !== undefined && (
                            <div className="rounded-md items-center justify-center">
                              {isLoading ? (
                                <div className="w-[675px] h-[675px] flex justify-center items-center">
                                  <CircularProgress
                                    size={24}
                                    className="text-quick-social absolute mx-auto"
                                  />
                                </div>
                              ) : data?.template || template ? (
                                <div
                                  ref={iframeRef}
                                  className="p-10 self-center"
                                  dangerouslySetInnerHTML={{ __html: template }}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      <ConformationDialogBox
                        open={openTemplateChangePopup}
                        onClose={() => setOpenTemplateChangePopup(false)}
                        onDelete={() => handleTemplateChange()}
                        additionalInfo={t('quickSocialTemplate.confirmationData')}
                        confirmationText={t('quickSocialTemplate.confirmationQuestion')}
                        deleteImage="/assets/images/icon/TemplateConfirm.png"
                        backgroundColor="quick-social"
                        textColor="quick-social"
                        buttonText={t('common.confirm')}
                      />
                    </div>
                  </div>
                  <div className="flex w-full justify-end">
                    {(location?.state?.passFrom === 'Template' || !location?.state?.passFrom) && (
                      <Button
                        className="md:text-18 font-semibold bg-quick-social border border-solid rounded-md hover:text-white items-center px-32 p-10"
                        variant="contained"
                        color="quickSocial"
                        type="button"
                        onClick={() => {
                          handleNext();
                        }}
                      >
                        {t('quickSocialPost.next')}
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                <div>
                  <AddDescription
                    suggestion={suggestion}
                    setSuggestion={setSuggestion}
                    setShowNextDiv={setShowNextDiv}
                    showNextDiv={showNextDiv}
                    description={description}
                    setDescription={setDescription}
                    handleNext={handleNext}
                    passFrom={location?.state?.passFrom}
                  />
                </div>
              )}
            </div>
            {location?.state?.passFrom === 'Template' && <div className="block w-[1px] bg-black" />}
            {data && (
              <div className="w-full md:w-7/12 rounded-md ">
                <div className="border-solid border-grey sm:flex justify-between items-center border-b-2 mb-24 overflow-x-auto">
                  <Tabs
                    value={tabs}
                    onChange={(e, newValue) => setTabs(newValue)}
                    textColor=""
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    sx={{
                      height: '100%',
                      minHeight: '30px',
                      '& .MuiTabs-scroller': {
                        overflow: { xs: 'scroll !important', sm: 'hidden !important' },
                      },
                      '& .MuiTabs-indicator': {
                        backgroundColor: 'skyblue',
                      },
                    }}
                  >
                    <Tab
                      className={`sm:text-18 font-bold mr-20 p-0 ${
                        tabs === 'text' ? 'text-quick-social' : 'text-black'
                      }`}
                      value="text"
                      label="Text"
                      icon={
                        <TitleIcon
                          className={`text-inherit border border-solid rounded-md ${
                            tabs === 'text' ? 'border-quick-social' : 'border-black'
                          }`}
                        />
                      }
                      iconPosition="start"
                    />
                    {editableTexts.some((item) => item.type === 'image') && (
                      <Tab
                        className={`sm:text-18 font-bold p-0 ${
                          tabs === 'image' ? 'text-quick-social' : 'text-black'
                        }`}
                        value="image"
                        label={t('appPromotionSlider.column.image')}
                        icon={<SatelliteOutlinedIcon className="text-inherit text-20" />}
                        iconPosition="start"
                      />
                    )}
                    {editableTexts.some((item) => item.key === 'list') && (
                      <Tab
                        className={`sm:text-18 font-bold p-0 ${
                          tabs === 'list' ? 'text-quick-social' : 'text-black'
                        }`}
                        value="list"
                        label="List"
                        icon={<FormatListNumberedOutlinedIcon className="text-inherit text-20" />}
                        iconPosition="start"
                      />
                    )}
                  </Tabs>
                </div>
                <div className=" rounded-md w-full pb-20 max-h-586 overflow-y-auto">
                  {template &&
                    editableTexts
                      ?.filter((item) =>
                        tabs === 'text'
                          ? item?.type !== 'image'
                          : tabs === 'image'
                          ? item?.type === 'image'
                          : false
                      )
                      ?.map((item, index) => (
                        <div
                          key={index}
                          className="bg-gray-A500 py-10 rounded-md flex flex-col gap-4 items-start"
                        >
                          {tabs === 'text' ? (
                            <>
                              <div className="bg-white flex flex-col w-full justify-between gap-6 items-center">
                                <div className="flex flex-row w-full justify-between gap-6 items-center">
                                  <div className="flex items-center gap-5 w-full">
                                    <div className="flex items-center gap-5 w-full ml-10">
                                      {styling && item?.key && styling[item?.key]?.visibility && (
                                        <Button
                                          key={item?.key}
                                          onClick={() => {
                                            updateStyling(
                                              item?.key,
                                              'visibility',
                                              styling[item.key]?.visibility === 'visible'
                                                ? 'hidden'
                                                : 'visible'
                                            );
                                          }}
                                          className="hover:bg-transparent !p-0 !h-28 !max-h-28 !min-h-28 !min-w-28 !w-28"
                                        >
                                          {styling[item.key]?.visibility === 'visible' ? (
                                            <VisibilityOutlinedIcon />
                                          ) : (
                                            <VisibilityOffOutlinedIcon />
                                          )}
                                        </Button>
                                      )}

                                      <div className="w-full">
                                        {tabs !== 'list' && (
                                          <TextField
                                            placeholder={t('botResponses.typeTheBotResponse')}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            value={item?.value}
                                            onChange={(e) => {
                                              const newValue = e.target.value;
                                              handleTextChange(index, newValue, item?.key, tabs);
                                            }}
                                            inputProps={{
                                              className:
                                                'rounded-4 focus:ring-0 focus:outline-none',
                                            }}
                                            sx={{
                                              '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                  border: 'none',
                                                },
                                                padding: 0,
                                              },
                                              '& .MuiOutlinedInput-input': {
                                                padding: '8px',
                                                whiteSpace: 'normal',
                                                wordBreak: 'break-word',
                                                overflowWrap: 'break-word',
                                              },
                                            }}
                                            className="w-full text-16 font-medium border-none bg-transparent"
                                            color="quick-social"
                                            error={
                                              (item?.key === 'description' &&
                                                item?.value?.length > 0 &&
                                                item?.value?.length < 20) ||
                                              (item?.key === 'userName' && item?.value?.length > 30)
                                            }
                                            helperText={
                                              item?.key === 'description'
                                                ? item?.value?.length === 0
                                                  ? t('quickSocialPost.validation')
                                                  : item?.value?.length > 0 &&
                                                    item?.value?.length < 20
                                                  ? t('quickSocialPost.validation')
                                                  : ''
                                                : item?.key === 'userName' &&
                                                  item?.value?.length > 30
                                                ? t('quickSocialPost.userNameMaxLength')
                                                : ''
                                            }
                                            disabled={reviewTemplate && item?.key !== 'review'}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    {activeIndex !== index && (
                                      <div className="w-[1px] h-40 p-0 bg-gray-300" />
                                    )}

                                    <div>
                                      {activeIndex === index ? (
                                        <Button
                                          onClick={() => handleEditClick(index)}
                                          className="hover:bg-quick-social text-white rounded-6 bg-quick-social mr-20 px-24 !max-h-32 !min-h-32 !h-32"
                                        >
                                          {t('common.save')}
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={() => handleEditClick(index)}
                                          className=" rounded-6 hover:bg-white"
                                        >
                                          <img src="/assets/images/icon/editIcon.png" alt="" />
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {activeIndex === index && (
                                  <div className="mt-10 w-full">
                                    <div className="flex flex-wrap xl:flex-nowrap gap-6 px-6 items-center justify-evenly">
                                      <div className="relative h-48 w-60">
                                        {styling?.[item?.key]?.color && (
                                          <div
                                            className="w-full h-full rounded-md cursor-pointer border border-gray-300"
                                            style={{ backgroundColor: styling[item?.key]?.color }}
                                            onClick={() => setShowPicker((prev) => !prev)}
                                            onKeyDown={handleKeyDown}
                                            role="button"
                                            tabIndex={0}
                                            aria-label="Select color"
                                          />
                                        )}
                                        {showPicker && (
                                          <div
                                            ref={pickerRef}
                                            className="absolute z-10 mt-2 bg-white w-fit p-2 shadow-md rounded-md"
                                          >
                                            <div className="flex justify-end">
                                              <Icon
                                                onClick={() => setShowPicker(false)}
                                                color="action"
                                                className="cursor-pointer"
                                              >
                                                close
                                              </Icon>
                                            </div>
                                            <ChromePicker
                                              color={styling[item?.key]?.color || '#000'}
                                              className="!shadow-none border border-gray-300"
                                              onChange={(colors) => {
                                                const newColor = colors.hex;
                                                setColor(newColor);
                                                updateStyling(item?.key, 'color', newColor);
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>

                                      <div className="flex flex-wrap bg-gray-A500 p-4 rounded-md gap-4 justify-center sm:justify-normal">
                                        {[
                                          {
                                            key: 'fontWeight',
                                            icon: <FormatBoldOutlinedIcon />,
                                            activeValue: 'bold',
                                            inactiveValue: 'normal',
                                          },
                                          {
                                            key: 'textTransform',
                                            icon: <FormatSizeOutlinedIcon />,
                                            activeValue: 'uppercase',
                                            inactiveValue: 'capitalize',
                                          },
                                          {
                                            key: 'fontStyle',
                                            icon: <FormatItalicOutlinedIcon />,
                                            activeValue: 'italic',
                                            inactiveValue: 'normal',
                                          },
                                          {
                                            key: 'underLine',
                                            icon: <FormatUnderlinedOutlinedIcon />,
                                            activeValue: 'underline',
                                            inactiveValue: 'normal',
                                          },
                                          {
                                            key: 'lineThrough',
                                            icon: (
                                              <span className="line-through text-20 font-bold">
                                                S
                                              </span>
                                            ),
                                            activeValue: 'line-through',
                                            inactiveValue: 'normal',
                                          },
                                        ]?.map(
                                          ({ key, icon, activeValue, inactiveValue }) =>
                                            styling?.[item?.key]?.[key] !== undefined && (
                                              <Button
                                                key={key}
                                                onClick={() =>
                                                  updateStyling(
                                                    item?.key,
                                                    key,
                                                    styling[item?.key]?.[key] === inactiveValue
                                                      ? activeValue
                                                      : inactiveValue
                                                  )
                                                }
                                                className={`${
                                                  styling[item?.key]?.[key] === inactiveValue
                                                    ? 'hover:text-gray-700 hover:bg-transparent text-gray-700'
                                                    : 'hover:bg-quick-social bg-quick-social text-white '
                                                } p-2 h-10 w-10 flex items-center justify-center rounded`}
                                              >
                                                {icon}
                                              </Button>
                                            )
                                        )}
                                      </div>

                                      <div className="flex flex-wrap bg-gray-A500 p-4 rounded-md gap-4 justify-center sm:justify-normal">
                                        {alignmentOptions.map(({ key, icon, value }) => (
                                          <Button
                                            key={key}
                                            onClick={() =>
                                              updateStyling(item?.key, 'textAlign', value)
                                            }
                                            className={`${
                                              styling[item?.key]?.textAlign === value
                                                ? 'hover:bg-quick-social hover:text-white bg-quick-social text-white'
                                                : 'hover:bg-transparent hover:text-gray-700 text-gray-700'
                                            } p-2 h-10 w-10 flex items-center justify-center rounded`}
                                          >
                                            {icon}
                                          </Button>
                                        ))}
                                      </div>
                                    </div>
                                    <div className="w-full flex justify-center">
                                      <div className="w-[96%] my-10 flex flex-col gap-10">
                                        {styling[item?.key]?.fontSize !== undefined && (
                                          <div className="flex bg-gray-A500 rounded-4 gap-14 sm:gap-20 items-center p-6">
                                            <img
                                              src="/assets/images/icon/width.png"
                                              alt="width"
                                              className="w-24 sm:w-32 h-24 sm:h-32"
                                            />
                                            <Slider
                                              value={styling[item?.key]?.fontSize || 0}
                                              min={0}
                                              max={600}
                                              className="w-full"
                                              step={1}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                updateStyling(item?.key, 'fontSize', newValue);
                                              }}
                                              aria-labelledby={`${
                                                styling[item?.key]?.fontSize
                                              }-slider`}
                                              valueLabelDisplay="auto"
                                              sx={{
                                                width: 500,
                                                color: '#4CAF50',
                                                '& .MuiSlider-thumb': {
                                                  border: '1px solid #219ebc',
                                                  backgroundColor: 'white',
                                                  width: '20px',
                                                  height: '20px',
                                                  '&:hover': {
                                                    backgroundColor: '#219ebc',
                                                  },
                                                },
                                                '& .MuiSlider-track': {
                                                  backgroundColor: '#219ebc',
                                                  border: 0,
                                                  height: '7px',
                                                },
                                                '& .MuiSlider-rail': {
                                                  backgroundColor: '#219ebc',
                                                  opacity: 1,
                                                  height: '7px',
                                                },
                                              }}
                                            />
                                            <div className="bg-white w-32 rounded-4 h-32 px-10 font-600 flex items-center justify-center">
                                              <span>{styling[item?.key]?.fontSize || 0}</span>
                                            </div>
                                          </div>
                                        )}

                                        {styling[item?.key]?.transform !== undefined && (
                                          <div className="flex bg-gray-A500 rounded-4 gap-14 sm:gap-20 items-center p-6">
                                            <img
                                              src="/assets/images/icon/left-right.png"
                                              alt="left-right"
                                              className="w-24 sm:w-32 h-24 sm:h-32"
                                            />
                                            <Slider
                                              value={styling[item?.key]?.xPosition || 0}
                                              min={-600}
                                              max={600}
                                              className="w-full"
                                              step={1}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                updateStyling(item?.key, 'xPosition', newValue);
                                              }}
                                              aria-labelledby={`${
                                                styling[item?.key]?.transform
                                              }-slider`}
                                              valueLabelDisplay="auto"
                                              sx={{
                                                width: 500,
                                                color: '#4CAF50',
                                                '& .MuiSlider-thumb': {
                                                  border: '1px solid #219ebc',
                                                  backgroundColor: 'white',
                                                  width: '20px',
                                                  height: '20px',
                                                  '&:hover': {
                                                    backgroundColor: '#219ebc',
                                                  },
                                                },
                                                '& .MuiSlider-track': {
                                                  backgroundColor: '#219ebc',
                                                  border: 0,
                                                  height: '7px',
                                                },
                                                '& .MuiSlider-rail': {
                                                  backgroundColor: '#219ebc',
                                                  opacity: 1,
                                                  height: '7px',
                                                },
                                              }}
                                            />
                                            <div className="bg-white w-32 h-32 rounded-4 px-10 font-600 flex items-center justify-center">
                                              <span>{styling[item?.key]?.xPosition || 0}</span>
                                            </div>
                                          </div>
                                        )}

                                        {styling[item?.key]?.transform !== undefined && (
                                          <div className="flex bg-gray-A500 rounded-4 gap-14 sm:gap-20 items-center p-6">
                                            <img
                                              src="/assets/images/icon/top-bottom.png"
                                              alt="top-bottom"
                                              className="w-24 sm:w-32 h-24 sm:h-32"
                                            />
                                            <Slider
                                              value={styling[item?.key]?.yPosition || 0}
                                              min={-600}
                                              max={600}
                                              className="w-full"
                                              step={1}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                updateStyling(item?.key, 'yPosition', newValue);
                                              }}
                                              aria-labelledby={`${
                                                styling[item?.key]?.transform
                                              }-slider`}
                                              valueLabelDisplay="auto"
                                              sx={{
                                                width: 500,
                                                color: '#4CAF50',
                                                '& .MuiSlider-thumb': {
                                                  border: '1px solid #219ebc',
                                                  backgroundColor: 'white',
                                                  width: '20px',
                                                  height: '20px',
                                                  '&:hover': {
                                                    backgroundColor: '#219ebc',
                                                  },
                                                },
                                                '& .MuiSlider-track': {
                                                  backgroundColor: '#219ebc',
                                                  border: 0,
                                                  height: '7px',
                                                },
                                                '& .MuiSlider-rail': {
                                                  backgroundColor: '#219ebc',
                                                  opacity: 1,
                                                  height: '7px',
                                                },
                                              }}
                                            />
                                            <div className="bg-white w-32 h-32 rounded-4 px-10 font-600 flex items-center justify-center">
                                              <span>{styling[item?.key]?.yPosition || 0}</span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {tabs === 'list' &&
                                      item?.properties
                                        ?.filter((property) => /^listText\d+$/.test(property?.key))
                                        ?.map((filteredItem) => (
                                          <TextField
                                            placeholder={t('botResponses.typeTheBotResponse')}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            value={filteredItem?.value}
                                            onChange={(e) => {
                                              const newValue = e.target.value;
                                              handleTextChange(
                                                index,
                                                newValue,
                                                filteredItem?.key,
                                                tabs
                                              );
                                            }}
                                            inputProps={{
                                              className:
                                                'rounded-4 focus:ring-0 focus:outline-none bg-indigo-100 bg-opacity-30',
                                            }}
                                            sx={{
                                              '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                  border: 'none',
                                                },
                                                padding: 0,
                                              },
                                              '& .MuiOutlinedInput-input': {
                                                padding: '8px',
                                                whiteSpace: 'normal',
                                                wordBreak: 'break-word',
                                                overflowWrap: 'break-word',
                                              },
                                            }}
                                            className="w-full text-16 font-medium border-none bg-transparent"
                                            color="quick-social"
                                            error={
                                              (filteredItem?.key === 'description' &&
                                                filteredItem?.value?.length > 0 &&
                                                filteredItem?.value?.length < 20) ||
                                              (filteredItem?.key === 'userName' &&
                                                filteredItem?.value?.length > 30)
                                            }
                                            helperText={
                                              filteredItem?.key === 'description'
                                                ? filteredItem?.value?.length === 0
                                                  ? t('quickSocialPost.validation')
                                                  : filteredItem?.value?.length > 0 &&
                                                    filteredItem?.value?.length < 20
                                                  ? t('quickSocialPost.validation')
                                                  : ''
                                                : filteredItem?.key === 'userName' &&
                                                  filteredItem?.value?.length > 30
                                                ? t('quickSocialPost.userNameMaxLength')
                                                : ''
                                            }
                                            disabled={reviewTemplate && item?.key !== 'review'}
                                          />
                                        ))}
                                  </div>
                                )}
                              </div>
                            </>
                          ) : tabs === 'image' ? (
                            <div className="responsive-wrapper">
                              <div className="responsive-column">
                                <div className=" w-full h-136 rounded-4">
                                  <img
                                    key={styling[item?.key]?.src || item?.value}
                                    src={styling[item?.key]?.src || item?.value}
                                    alt={styling[item?.key]?.src || item?.value}
                                    className="w-full h-full object-cover rounded-md shadow-1"
                                  />
                                </div>
                                <div className="w-full">
                                  <div className="flex gap-6 w-full justify-center">
                                    {styling && item?.key && styling[item?.key]?.visibility && (
                                      <Button
                                        key={item?.key}
                                        onClick={() => {
                                          updateStyling(
                                            item?.key,
                                            'visibility',
                                            styling[item.key]?.visibility === 'visible'
                                              ? 'hidden'
                                              : 'visible'
                                          );
                                        }}
                                        className="hover:bg-transparent rounded-md border-1 !min-h-28 sm:!min-h-32 !min-w-40 sm:!min-w-52 sm:!max-w-52 !max-w-40 !w-40 sm:!w-52 !max-h-28 sm:!max-h-32 !h-28 sm:!h-32 border-black border-solid"
                                      >
                                        {styling[item.key]?.visibility === 'visible' ? (
                                          <VisibilityOutlinedIcon />
                                        ) : (
                                          <VisibilityOffOutlinedIcon />
                                        )}
                                      </Button>
                                    )}
                                    <Button
                                      onClick={() => handleFileInputClick(item)}
                                      className="rounded-md bg-quick-social hover:bg-quick-social p-3 !min-h-28 sm:!min-h-32 !min-w-40 sm:!min-w-52 sm:!max-w-52 !max-w-40 !w-40 sm:!w-52 !max-h-28 sm:!max-h-32 !h-28 sm:!h-32"
                                    >
                                      <img
                                        src="/assets/images/icon/upload.png"
                                        alt="upload"
                                        className="w-24 sm:w-32 h-24 sm:h-32 sm:p-8 p-5"
                                      />
                                    </Button>
                                    <input
                                      type="file"
                                      ref={(el) => {
                                        fileInputRefLogo.current[item?.key] = el;
                                      }}
                                      style={{ display: 'none' }}
                                      onChange={(event) =>
                                        handleImageUpload(event, index, item?.key)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="responsive-container">
                                <div className="gap-12 rounded-md w-full flex flex-col bg-transparent sm:bg-white px-0 sm:px-14 py-10">
                                  {styling[item?.key]?.width !== undefined && (
                                    <div className="flex bg-gray-A500 rounded-4 gap-14 sm:gap-20 items-center p-6">
                                      <img
                                        src="/assets/images/icon/width.png"
                                        alt="left-right"
                                        className="w-24 sm:w-32 h-24 sm:h-32"
                                      />
                                      <Slider
                                        value={styling[item?.key]?.width || 0}
                                        min={0}
                                        max={675}
                                        className="w-full"
                                        step={1}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          updateStyling(item?.key, 'width', newValue);
                                        }}
                                        aria-labelledby={`${styling[item?.key]?.width}-slider`}
                                        valueLabelDisplay="auto"
                                        sx={{
                                          width: 500,
                                          color: '#4CAF50',
                                          '& .MuiSlider-thumb': {
                                            border: '1px solid #219ebc',
                                            backgroundColor: 'white',
                                            width: '20px',
                                            height: '20px',
                                            '&:hover': {
                                              backgroundColor: '#219ebc',
                                            },
                                          },
                                          '& .MuiSlider-track': {
                                            backgroundColor: '#219ebc',
                                            border: 0,
                                            height: '7px',
                                          },
                                          '& .MuiSlider-rail': {
                                            backgroundColor: '#219ebc',
                                            opacity: 1,
                                            height: '7px',
                                          },
                                        }}
                                      />
                                      <div className="bg-white w-32 h-32 rounded-4 px-10 font-600 flex items-center justify-center">
                                        <span>{Number(styling[item?.key]?.width) || 0}</span>
                                      </div>
                                    </div>
                                  )}

                                  {styling[item?.key]?.transform !== undefined && (
                                    <div className="flex bg-gray-A500 rounded-4 gap-14 sm:gap-20 items-center p-6">
                                      <img
                                        src="/assets/images/icon/left-right.png"
                                        alt="left-right"
                                        className="w-24 sm:w-32 h-24 sm:h-32"
                                      />
                                      <Slider
                                        value={styling[item?.key]?.xPosition || 0}
                                        min={-600}
                                        max={600}
                                        className="w-full"
                                        step={1}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          updateStyling(item?.key, 'xPosition', newValue);
                                        }}
                                        aria-labelledby={`${styling[item?.key]?.transform}-slider`}
                                        valueLabelDisplay="auto"
                                        sx={{
                                          width: 500,
                                          color: '#4CAF50',
                                          '& .MuiSlider-thumb': {
                                            border: '1px solid #219ebc',
                                            backgroundColor: 'white',
                                            width: '20px',
                                            height: '20px',
                                            '&:hover': {
                                              backgroundColor: '#219ebc',
                                            },
                                          },
                                          '& .MuiSlider-track': {
                                            backgroundColor: '#219ebc',
                                            border: 0,
                                            height: '7px',
                                          },
                                          '& .MuiSlider-rail': {
                                            backgroundColor: '#219ebc',
                                            opacity: 1,
                                            height: '7px',
                                          },
                                        }}
                                      />
                                      <div className="bg-white w-32 h-32 rounded-4 px-10 font-600 flex items-center justify-center">
                                        <span>{styling[item?.key]?.xPosition || 0}</span>
                                      </div>
                                    </div>
                                  )}

                                  {styling[item?.key]?.transform !== undefined && (
                                    <div className="flex bg-gray-A500 rounded-4 gap-14 sm:gap-20 items-center p-6">
                                      <img
                                        src="/assets/images/icon/top-bottom.png"
                                        alt="left-right"
                                        className="w-24 sm:w-32 h-24 sm:h-32"
                                      />
                                      <Slider
                                        value={styling[item?.key]?.yPosition || 0}
                                        min={-600}
                                        max={600}
                                        className="w-full"
                                        step={1}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          updateStyling(item?.key, 'yPosition', newValue);
                                        }}
                                        aria-labelledby={`${styling[item?.key]?.transform}-slider`}
                                        valueLabelDisplay="auto"
                                        sx={{
                                          width: 500,
                                          color: '#4CAF50',
                                          '& .MuiSlider-thumb': {
                                            border: '1px solid #219ebc',
                                            backgroundColor: 'white',
                                            width: '20px',
                                            height: '20px',
                                            '&:hover': {
                                              backgroundColor: '#219ebc',
                                            },
                                          },
                                          '& .MuiSlider-track': {
                                            backgroundColor: '#219ebc',
                                            border: 0,
                                            height: '7px',
                                          },
                                          '& .MuiSlider-rail': {
                                            backgroundColor: '#219ebc',
                                            opacity: 1,
                                            height: '7px',
                                          },
                                        }}
                                      />
                                      <div className="bg-white w-32 h-32 rounded-4 px-10 font-600 flex items-center justify-center">
                                        <span>{styling[item?.key]?.yPosition || 0}</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>No Editable Field Available.</>
                          )}
                        </div>
                      ))}
                  <AddDescription
                    suggestion={suggestion}
                    setSuggestion={setSuggestion}
                    setShowNextDiv={setShowNextDiv}
                    showNextDiv={showNextDiv}
                    description={description}
                    template={template}
                    setDescription={setDescription}
                    handleNext={handleNext}
                    passFrom={location?.state?.passFrom}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {showNextDiv && (
        <>
          <div className="md:flex gap-24">
            <div className=" bg-white p-20 rounded-md md:w-3/5 w-full mb-10">
              <div className="flex justify-between mb-44">
                <div className="flex items-center gap-10">
                  <Icon className="text-24 cursor-pointer" onClick={() => setShowNextDiv(false)}>
                    west
                  </Icon>
                  <h2 className="text-28 font-semibold">{t('quickSocialPost.yourPost')}</h2>
                </div>
                <div className="gap-20">
                  {isBtLoading ? (
                    <CircularProgress size={20} color="quickSocial" />
                  ) : (
                    imagePath && (
                      <div>
                        <IconButton
                          color="primary"
                          aria-label="download"
                          onClick={() => handleImageDownload(imagePath, 'downloaded-image')}
                        >
                          <DownloadIcon />
                        </IconButton>
                        <IconButton
                          color="primary"
                          aria-label="share"
                          onClick={() => handleOpenShare(imagePath, 'downloaded-image')}
                        >
                          <ShareIcon />
                        </IconButton>
                      </div>
                    )
                  )}
                </div>
              </div>
              {description?.length > 0 && (
                <div className="bg-gray-A500 p-20 mb-20 rounded-md ">
                  <p>{description}</p>
                </div>
              )}
              <div className="">
                <div className="flex justify-end items-center">
                  <IconButton color="primary" aria-label="edit" onClick={() => handleEdit()}>
                    <EditIcon />
                  </IconButton>
                </div>
                <div className="flex justify-center items-center">
                  {isBtLoading ? (
                    <FuseLoading />
                  ) : (
                    imagePath && (
                      <img src={imagePath} alt="post" className="max-w-full max-h-full mr-5" />
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="bg-white rounded-md p-24 md:w-2/5 w-full mb-10">
              <h3 className="text-20 font-semibold mb-16 ">
                {t('quickSocialPost.selectPlatform')}
              </h3>
              {!socialData?.some((platform) => platform?.isConnected) ? (
                <div className="bg-gray-A500 rounded-md p-16 flex flex-col items-center">
                  <h3 className="text-24 font-medium mb-32">
                    {t('quickSocialPost.connectToSocialAccount')}
                  </h3>
                  <div className="platform-list flex gap-16 mb-20 justify-center">
                    {socialData?.map((platform, index) => (
                      <div key={index} className="flex items-center gap-8">
                        <img
                          src={platform?.logo}
                          alt={`${platform?.name} Logo`}
                          className=" w-24 sm:w-32 h-24 sm:h-32"
                        />
                        <span>{platform?.name}</span>
                      </div>
                    ))}
                  </div>
                  <p className="text-16 font-500 mb-28 text-center">
                    {t('quickSocialPost.connectToSocialAccountContent')}
                  </p>
                  <Button
                    variant="contained"
                    color="quickSocial"
                    autoFocus
                    onClick={() => history.push(`/quick-social/social-connect`)}
                    className="rounded-md"
                  >
                    {t('quickSocialPost.connect')}
                  </Button>
                </div>
              ) : (
                <div className="connected-platforms-list  gap-16">
                  <div className="bg-gray-A500 px-24 pt-24 mb-24 rounded-md">
                    {socialData
                      ?.filter((platform) => {
                        if (!url && platform?.reviewPlatformId === 10) {
                          return false;
                        }
                        return platform?.isConnected;
                      })
                      ?.map((platform, index) => (
                        <div key={index} className="flex items-center pb-24">
                          <input
                            type="checkbox"
                            id={`checkbox-${platform?.reviewPlatformId}`}
                            checked={
                              isChecked[
                                socialData?.findIndex(
                                  (p) => p?.reviewPlatformId === platform?.reviewPlatformId
                                )
                              ]
                            }
                            onChange={() => handleCheckboxChange(platform?.reviewPlatformId)}
                            className="hidden"
                          />
                          <label
                            htmlFor={`checkbox-${platform?.reviewPlatformId}`}
                            className="flex items-center cursor-pointer"
                          >
                            <span
                              className={`w-16 h-16 inline-block mr-4 rounded border-2 border-gray-400 ${
                                isChecked[
                                  socialData?.findIndex(
                                    (p) => p?.reviewPlatformId === platform?.reviewPlatformId
                                  )
                                ]
                                  ? 'bg-green-500 border-green-500'
                                  : ''
                              }`}
                            >
                              {isChecked[
                                socialData?.findIndex(
                                  (p) => p?.reviewPlatformId === platform?.reviewPlatformId
                                )
                              ] && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-14 h-14 text-white m-auto"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <polyline points="20 6 9 17 4 12" />
                                </svg>
                              )}
                            </span>

                            <img
                              src={platform?.logo}
                              alt={`${platform?.name} Logo`}
                              className="w-24 h-24 mr-8"
                            />
                            <p className="text-20 font-medium">
                              {platform?.name?.replace(/\b\w/g, (char) => char?.toUpperCase())}
                            </p>
                          </label>
                        </div>
                      ))}
                    {!url &&
                      socialData?.some(
                        (platform) => !platform?.isConnected && platform?.reviewPlatformId === 9
                      ) && (
                        <div className="p-10 flex flex-col items-center justify-center text-center">
                          {socialData
                            ?.filter((platform) => platform?.reviewPlatformId !== 10)
                            ?.map((platform) => (
                              <img
                                key={platform?.reviewPlatformId}
                                src={platform?.logo}
                                alt={`${platform?.name} Logo`}
                                className="w-24 h-24 mb-4"
                              />
                            ))}
                          <h1 className="text-16 font-600 mb-4">
                            Connect Facebook for posting caption
                          </h1>
                          <Button
                            variant="contained"
                            color="quickSocial"
                            autoFocus
                            onClick={() => history.push(`/quick-social/dashboard`)}
                            className="rounded-md"
                          >
                            {t('quickSocialPost.connect')}
                          </Button>
                        </div>
                      )}
                  </div>
                  {from === 'schedule' && (
                    <div
                      className="border sm:flex items-center max-w-400 p-10 rounded-md border-grey-200 gap-32 mb-20"
                      role="button"
                      tabIndex={0}
                      onClick={() => handleSchedule('Schedule')}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleSchedule('Schedule');
                        }
                      }}
                    >
                      <div className=" flex items-center gap-6">
                        <img
                          src="/assets/images/icon/DateIcon.svg"
                          alt="Date Icon"
                          className="sm:w-32 sm:h-32 w-24 h-24 object-cover"
                        />
                        <p className="text-12 sm:text-14 font-500">
                          {data?.scheduledDate
                            ? new Date(data?.scheduledDate).toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                              })
                            : t('quickSocialScheduled.noDateScheduled')}
                        </p>
                      </div>
                      <div className="">
                        <p className="text-12 sm:text-14 font-500">
                          {data?.scheduledDate
                            ? formatTimeFromDate(data?.scheduledDate)
                            : t('quickSocialScheduled.NoTimeAvailable')}
                        </p>
                      </div>
                    </div>
                  )}

                  {from === 'schedule' && (
                    <div className="gap-16 flex justify-end">
                      <div>
                        <Button
                          variant="outlined"
                          color="quickSocial"
                          autoFocus
                          onClick={() => setUpdateSchedule()}
                          className="rounded-md px-32 flex items-center justify-center"
                          disabled={!url && description?.length <= 0}
                        >
                          {isBtLoading ? (
                            <span className="flex items-center gap-2">
                              <CircularProgress size={16} color="quickSocial" />
                              {t('common.save')}
                            </span>
                          ) : (
                            t('common.save')
                          )}
                        </Button>
                      </div>
                    </div>
                  )}
                  {from !== 'schedule' && (
                    <div className="gap-16 flex justify-end">
                      <div>
                        <Button
                          variant="outlined"
                          color="quickSocial"
                          autoFocus
                          onClick={() => handleSchedule('Schedule')}
                          className="rounded-md px-32 flex items-center justify-center"
                          disabled={isDisabled()}
                        >
                          {isBtLoading ? (
                            <span className="flex items-center gap-2">
                              <CircularProgress size={16} color="quickSocial" />
                              {t('quickSocialPost.schedule')}
                            </span>
                          ) : (
                            t('quickSocialPost.schedule')
                          )}
                        </Button>
                      </div>

                      <div>
                        <Button
                          variant="contained"
                          color="quickSocial"
                          autoFocus
                          onClick={() => handleDirectPost()}
                          className="rounded-md px-48 flex items-center justify-center"
                          disabled={isDisabled()}
                        >
                          {isBtLoading ? (
                            <span className="flex items-center gap-2">
                              <CircularProgress size={16} color="quickSocial" />
                              {t('quickSocialPost.post')}
                            </span>
                          ) : (
                            t('quickSocialPost.post')
                          )}
                        </Button>
                      </div>
                    </div>
                  )}
                  <SchedulePopUp
                    open={openDialogueForSchedule}
                    onClose={handleCloseDeleteDialog}
                    onCreatePost={from === 'schedule' ? setUpdateSchedule : setSchedule}
                    backgroundColor="quick-social"
                    textColor="white"
                    buttonText="Schedule"
                    scheduledDate={scheduledDate}
                    setScheduledDate={setScheduledDate}
                    scheduledTime={scheduledTime}
                    setScheduledTime={setScheduledTime}
                    refresh={refresh}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {openShare && imageUrl && (
        <SharePost
          open={openShare}
          onClose={handleCloseDeleteDialog}
          image={imageUrl}
          businessId={userCurrentBusinessDetails?.id}
          editableTexts={editableTexts}
          plateFormID={location?.state}
        />
      )}
    </div>
  );
};

export default Post;
