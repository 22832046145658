import React, { useState, useMemo, useEffect } from 'react';
import './Landing.css';
import { Typography, Box, Button } from '@mui/material';
import { Star } from '@mui/icons-material';

function Landing() {
  const [visibleImages, setVisibleImages] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const productData = useMemo(
    () => [
      {
        label: 'Quick Review',
        image: 'assets/images/quick-hub/quick-review.svg',
        id: 0,
        content_heading:
          'Your <span class="text-landing-page text-bold">reputation matters.</span>',
        content_body:
          'With Quick Reviews, you can manage and respond to reviews from platforms like Google and Yelp in one place. Analyze trends, uncover actionable insights, and ensure your customers feel heard. Multi-location businesses? No problem—track reviews across all your branches effortlessly.',
        content_img: 'assets/images/landing/product-desc/quick-review.png',
      },
      {
        label: 'Quick Chat',
        image: 'assets/images/quick-hub/quick-chat.svg',
        id: 1,
        content_heading:
          'Never miss a <span class="text-landing-page text-bold">conversation.</span>',
        content_body:
          'Quick Chat lets you handle customer inquiries, support requests, and bot interactions from a unified inbox. Keep response times low and satisfaction high by managing all your customer touchpoints seamlessly.',
        content_img: 'assets/images/landing/product-desc/quick-chat.png',
      },
      {
        label: 'Quick Social',
        image: 'assets/images/quick-hub/quick-social.svg',
        id: 2,
        content_heading:
          'Be everywhere <span class="text-landing-page text-bold">your audience is</span>',
        content_body:
          'Quick Social enables you to create stunning posts using templates, schedule them across platforms, and analyze engagement—all from a single dashboard. Build your brand effortlessly with the power of consistency and creativity.',
        content_img: 'assets/images/landing/product-desc/quick-social.png',
      },
      {
        label: 'Quick Campaign',
        image: 'assets/images/quick-hub/quick-campaigns.svg',
        id: 3,
        content_heading:
          'Supercharge your <span class="text-landing-page text-bold">outreach.</span>',
        content_body:
          'Use Quick Campaign to craft targeted marketing campaigns via email, SMS, and WhatsApp. Whether you’re welcoming new customers or promoting a seasonal offer, you’ll have the tools to build meaningful connections and drive engagement.',
        content_img: 'assets/images/landing/product-desc/quick-Campaign.png',
      },
      {
        label: 'Quick Ads',
        image: 'assets/images/quick-hub/quick-ads.svg',
        id: 4,
        content_heading:
          'Make your <span class="text-landing-page text-bold">advertising easy and impactful.</span>',
        content_body:
          'Quick Ads simplifies launching Facebook and Instagram campaigns by helping you target the right people, set your budget, and define your goals. Focus on connecting with the right audience. we’ll handle the rest.',
        content_img: 'assets/images/landing/product-desc/quick-ads.png',
      },
    ],
    []
  );

  const features = [
    {
      label: 'Save Time',
      image: 'assets/images/landing/save-time.png',
      id: 0,
      content_body: 'Automate processes and focus on what matters most—your growth.',
    },
    {
      label: 'All-in-One',
      image: 'assets/images/landing/all-in-one.png',
      id: 1,
      content_body: 'No need to juggle multiple tools; everything you need is here.',
    },
    {
      label: 'Data-Driven Insights',
      image: 'assets/images/landing/data-driven.png',
      id: 0,
      content_body: 'Make smarter decisions with comprehensive analytics.',
    },
    {
      label: 'Easy Integration',
      image: 'assets/images/landing/easy-integration.png',
      id: 0,
      content_body: 'Seamlessly connect with platforms you already use.',
    },
  ];

  const feedBack = [
    {
      customer_name: 'Rahul',
      review:
        'I just tried it out, and I am seeing fascinating results in the increase of reviews. It is very simple to provide reviews to our customers, and I am very satisfied with the service and value that Quick Reviews delivers for our customers and business growth. Thank you, Quick Reviews, for helping us grow our business.',
      business_name: 'DSC Vadodara',
      image: 'assets/images/landing/testimonial/rahul.png',
    },
    {
      customer_name: 'Sayali',
      review:
        'After downloading Quick Reviews, we noticed sudden changes in our Google review count. Our customers appreciate the process of obtaining reviews by simply scanning the QR code and selecting attributes. It is simple and quick, leading to growth in our business. Thank you, Quick Reviews!',
      business_name: 'Hakuna Matata',
      image: 'assets/images/landing/testimonial/sayali.png',
    },
    {
      customer_name: 'Ram',
      review:
        'Quick Reviews has made our review acquisition process incredibly easy for both our customers and our business. It has facilitated the growth of our reviews, which in turn is boosting our Google ratings. We can easily See all the reviews and analytics in the quick reviews app which helps to make better decisions.',
      business_name: 'Paneerwala',
      image: 'assets/images/landing/testimonial/ram.png',
    },
    {
      customer_name: 'Narayan',
      review:
        'Quick Reviews helps us increase our reviews on Google Maps. I can effortlessly share positive reviews across all our social media accounts. by receiving the very best reviews, we are growing our business effectively.',
      business_name: 'Shafe Kababi',
      image: 'assets/images/landing/testimonial/narayan.png',
    },
    {
      customer_name: 'Ram',
      review:
        'Quick Hub has completely transformed the way I manage my business. From handling campaigns to tracking leads, everything is so streamlined and efficient now. It’s truly a game-changer!',
      business_name: 'Founder, Paneerwala',
      image: 'assets/images/landing/testimonial/ram.png',
    },
  ];

  const steps = [
    {
      label: 'Sign Up',
      image: 'assets/images/landing/sign-up.png',
      id: 0,
      content_body: 'Create your Quick Hub account in minutes.',
    },
    {
      label: 'Customize',
      image: 'assets/images/landing/customize.png',
      id: 1,
      content_body: 'Set up your business preferences and connect platforms.',
    },
    {
      label: 'Launch',
      image: 'assets/images/landing/launch.png',
      id: 2,
      content_body: 'Start managing and growing your business seamlessly.',
    },
  ];

  useEffect(() => {
    productData.forEach((_, index) => {
      setTimeout(() => {
        setVisibleImages((prev) => [...prev, index]);
      }, index * 1000);
    });
  }, [productData]);

  return (
    <>
      <header className="bg-[#111218] md:py-24 py-16 relative">
        <div className="custom-container">
          <img
            src="https://quick-review.s3.us-east-1.amazonaws.com/logo/logo_white_horizontal.png"
            className="md:w-256 w-200"
            alt="Quick hub"
          />
        </div>
        <img
          src="assets/images/landing/line-green.png"
          className="absolute -bottom-0 w-full"
          alt=""
        />
      </header>
      <div className="bg-[#0b0b0b] lg:py-112 md:py-80 py-36 relative">
        <div className="custom-container">
          <div className="md:grid md:grid-cols-2">
            <div>
              <h2 className="text-white md:text-56 text-28 font-bold">Your Business,</h2>
              <h2 className="text-landing-page md:text-[80px] text-36 font-bold">Simplified</h2>
              <div className="border-b-1 border-solid pb-14 flex items-center !border-landing-page md:mb-32 mb-16 relative before:w-5 before:h-5 before:bg-landing-page before:inline-block before:rounded-full before:absolute before:right-0 before:-bottom-3">
                <h3 className="md:text-36 text-24 font-medium text-white lg:mr-48 mr-12">
                  with Quick Hub
                </h3>
                <div className="flex items-center">
                  {productData.map((product, index) => (
                    <img
                      key={product.id}
                      src={product.image}
                      alt={product.label}
                      className={`mx-2 transition-opacity md:w-48 md:h-48 w-28 h-28 duration-1000 ${
                        visibleImages.includes(index) ? 'opacity-100' : 'opacity-0'
                      }`}
                    />
                  ))}
                </div>
              </div>
              <Typography className="md:text-24 text-16 font-semibold text-white max-w-400">
                Manage reviews, run campaigns, engage with customers, grow your social presence, and
                launch ads—all in one powerful platform.
              </Typography>
            </div>
            <div className="rounded-[40px] md:p-24 p-16 max-w-512 ml-auto w-full mt-20 md:mt-0 form-bg-landing">
              <div className="md:text-24 text-20 font-medium text-white max-w-360">
                Get started with your 15-Day free trail.
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/images/landing/line-green.png"
          className="absolute -bottom-0 w-full"
          alt=""
        />
      </div>
      <div className="bg-[#272727] py-32 md:mb-72 mb-36">
        <div className="custom-container">
          <Typography className="md:text-36 text-landing-page mb-16 text-24 text-center block">
            Platform Integrations
          </Typography>
          <div className="md:flex grid grid-cols-3 gap-4 items-center justify-between">
            <img src="assets/images/landing/partner_logo/google.png" className="" alt="google" />
            <img src="assets/images/landing/partner_logo/meta.png" className="" alt="meta" />
            <img
              src="assets/images/landing/partner_logo/truspilot.png"
              className=""
              alt="truspilot"
            />
            <img src="assets/images/landing/partner_logo/amazon.png" className="" alt="amazon" />
            <img src="assets/images/landing/partner_logo/yelp.png" className="" alt="yelp" />
          </div>
        </div>
      </div>

      <div className="custom-container text-center mb-48">
        <Typography className="md:text-36 text-24 font-semibold leading-none">
          What Makes Quick Hub
        </Typography>
        <Typography className="md:text-36 text-24 font-semibold md:mb-56 mb-28">
          Your <span className="text-landing-page-A500 font-bold">Ultimate Growth Partner?</span>
        </Typography>

        <div className="bg-[#e0e0e0] rounded-3xl border-1 border-solid border-[#9B9B9B] mb-40 ">
          <div className="flex rounded-3xl w-full bg-gray-300 relative">
            {productData.map((tab, index) => (
              <div
                key={tab.id}
                aria-hidden
                onClick={() => setActiveTab(index)}
                className={`cursor-pointer transition-all md:py-24 py-12 text-center mx-auto relative rounded-t-3xl
              ${
                activeTab === index
                  ? 'bg-black text-white tab-active'
                  : 'hover:bg-gray-300 tab-normal'
              }`}
                style={{ width: `${100 / productData.length}%` }}
              >
                <span className="mr-4 inline-block align-middle">
                  <img src={tab.image} className="md:w-36 md:h-36 w-20 h-20" alt={tab.label} />
                </span>
                <span className="md:text-20 text-14 font-medium inline-block align-middle">
                  {tab.label}
                </span>
              </div>
            ))}
          </div>

          <Box
            className={`bg-black text-white rounded-3xl mx-auto text-left min-h-400 md:p-32 p-16 tab-content ${
              activeTab === 0 && 'rounded-tl-none'
            } ${activeTab === 4 && 'rounded-tr-none'}`}
          >
            {productData.map((tab, index) => (
              <div key={index} aria-hidden>
                {activeTab === index && (
                  <>
                    <Typography className="font-semibold md:text-48 text-24 mb-20">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: tab?.content_heading,
                        }}
                      />
                    </Typography>
                    <Typography className="mt-3 text-white md:text-28 text-16 max-w-[900px]">
                      {tab.content_body}
                    </Typography>
                    <div className="text-right">
                      <img src={tab.content_img} className="ml-auto md:-mb-16" alt="..." />
                    </div>
                  </>
                )}
              </div>
            ))}
          </Box>
        </div>

        <Typography className="mx-auto max-w-786 md:text-[22px] text-16 font-semibold mb-24">
          <span className="text-landing-page-A500 font-bold">
            Save up to 40% with the Quick Hub Bundle
          </span>{' '}
          and gain access to an integrated system that helps you manage your business more
          efficiently.
        </Typography>

        <Button
          variant="contained"
          size="medium"
          className="text-white md:px-20 md:py-10 rounded-lg shadow-lg md:text-24 text-16 font-medium md:min-h-56"
          style={{ background: 'linear-gradient(90deg, #4F37B4 0%, #7863D1 99.78%)' }}
        >
          Get the Full Suite at an Exclusive Price
        </Button>
      </div>

      <div className="bg-[#0B0B0B] md:pt-40 pt-20 md:pb-120 md:mb-120 mb-36 pb-36">
        <div className="custom-container md:mb-120 mb-36">
          <Typography className="md:text-36 text-24 font-semibold md:mb-56 mb-28 text-center text-white">
            Why <span className="text-landing-page font-bold">Businesses Love</span>
            <span className="block"> Quick Hub ?</span>
          </Typography>
          <div className="md:grid md:grid-cols-4 grid-cols-2 gap-16">
            {features.map((fea, index) => (
              <div
                key={index}
                className="rounded-[42px] md:p-24 p-16 text-center bg-[#2d2d2d] md:mb-0 mb-10"
                style={{
                  border: '1.5px solid',

                  borderColor:
                    'linear-gradient(123.29deg, #929292 9.48%, #47747F 46.48%, rgba(6, 68, 82, 0.52) 85.03%)',
                }}
              >
                <>
                  <div className="mb-16 h-80">
                    <img src={fea.image} className="mx-auto mb-16" alt="" />
                  </div>
                  <Typography className="font-semibold text-20 text-white mb-16">
                    {fea.label}
                  </Typography>
                  <Typography className="text-white text-16 max-w-208 mx-auto">
                    {fea.content_body}
                  </Typography>
                </>
              </div>
            ))}
          </div>
        </div>
        <div className=" max-w-[1564px] mx-auto text-center">
          <div className="mx-16">
            <Typography className="md:text-36 text-24 font-medium md:mb-56 mb-28 text-center text-white">
              Real Results Real Feedback
            </Typography>
            <div className="grid md:grid-cols-3 gap-24 justify-center">
              {feedBack.map((testimonial, index) => (
                <div key={index} className="rounded-2xl p-24 text-center bg-[#2d2d2d]">
                  <>
                    <div className="flex justify-start mb-10">
                      {[...Array(5)].map((_, i) => (
                        <Star key={i} className="text-yellow-400" fontSize="small" />
                      ))}
                    </div>
                    <p className="text-gray-300 md:text-20 text-16 text-left mb-12">
                      {testimonial.review}
                    </p>
                    <div className="block">
                      <div className="text-left flex items-center justify-between">
                        <div>
                          <img
                            src={testimonial.image}
                            className="w-40 h-40  inline-block align-middle mr-10"
                            alt=".."
                          />
                          <p className="font-bold text-16 text-[#BCB9B4] inline-block align-middle">
                            {testimonial.customer_name}
                          </p>
                        </div>

                        <p className="text-gray-400 text-14">{testimonial.business_name}</p>
                      </div>
                    </div>
                  </>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="custom-container md:mb-80 mb-36">
        <div>
          <Typography className="md:text-36 text-24 font-semibold md:mb-56 mb-28 text-center">
            Get Started in <span className="text-landing-page-A500 font-bold">3 Easy Steps</span>
          </Typography>
        </div>
        <div className="sm:flex text-center justify-between">
          {steps.map((step, index) => (
            <div key={index} className="steps-ui relative mb-16">
              <img
                src={step.image}
                className="w-112 h-112 inline-block align-middle relative z-9999"
                alt=".."
              />
              <span className="block text-20 font-bold my-10">{step.label}</span>
              <Typography className="text-16 font-medium w-200 mx-auto">
                {step.content_body}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <div className="custom-container">
        <div className="md:flex items-center justify-between">
          <div className="max-w-586">
            <Typography className="md:text-36 text-24 font-medium md:leading-none mb-20">
              Join Thousands of Businesses Growing with Quick Hub
            </Typography>
            <Typography className="text-16 mb-20">
              Quick Hub is transforming the way businesses manage operations—bringing together
              reviews, campaigns, chats, social media, and ads into one seamless platform. Ready to
              simplify your work and boost your growth? Let’s get started today.
            </Typography>
            <Button
              variant="contained"
              size="medium"
              className="text-white px-20 py-10 rounded-lg shadow-lg md:text-24 text-16 font-medium md:min-h-56"
              style={{ background: 'linear-gradient(90deg, #4F37B4 0%, #7863D1 99.78%)' }}
            >
              Start Your Free Trial Now
            </Button>
          </div>
          <img
            src="assets/images/landing/app.png"
            className="max-w-480 w-full md:mt-0 mt-20 img-responsive"
            alt="..."
          />
        </div>
      </div>
      <footer className="bg-[#0b0b0b] pt-24 md:pb-48 pb-24">
        <div className="custom-container">
          <div className="md:flex items-center justify-between mb-28">
            <img
              src="https://quick-review.s3.us-east-1.amazonaws.com/logo/logo_white_horizontal.png"
              className="md:w-256 w-200"
              alt="Quick hub"
            />
            <div className="block text-center md:mt-0 mt-16">
              <a
                href="/a"
                role="button"
                aria-hidden
                className="inline-block align-middle leading-none text-white hover:text-landing-page mr-6 pr-6 border-r-1 border-solid border-grey"
              >
                Terms of Service
              </a>
              <a
                href="/sx"
                role="button"
                aria-hidden
                className="inline-block align-middle leading-none text-white hover:text-landing-page"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <Typography className="block text-white text-16 text-center">
            The names and logos of Quick Hub are trademarks of Cloudpeak Technologies Pvt Ltd. All
            other trademarks, logos, and product names mentioned are the property of their
            respective owners. Comparison data is accurate as of June 15th, 2024, and is subject to
            change.
          </Typography>
        </div>
      </footer>
    </>
  );
}

export default Landing;
