import { Button, Menu, Icon, MenuItem, Select, Typography, Tab, Tabs } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import ReviewTemplatePopup from './ReviewTemplatePopUp';
import Pagination from '../../../component/table/Pagination';
import reviewPlatformData from '../../query/reviewPlatform';
import {
  capitalizeFirstLetter,
  formatDateWithTimeZone,
  getParamsId,
  handleApiRequest,
} from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';
import UserProfile from '../../common/userProfile';
import manageBusinessData from '../../query/manageBusiness';

const SocialReviews = ({ selectedImage, reviewTitle }) => {
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const location = useLocation();
  const [reviewData, setReviewData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [selectedReviewData, setSelectedReviewData] = useState();
  const [shouldFetch, setShouldFetch] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [placeID, setPlaceID] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [plateFormID, setPlateFormID] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [zomatoRatingType, setZomatoRatingType] = useState();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isInitialFetchComplete, setIsInitialFetchComplete] = useState(false);
  const [totalReview, setTotalReview] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [reviewTemplates, setReviewTemplates] = useState(false);
  const [logo, setLogo] = useState();

  console.log(totalCount);
  useEffect(() => {
    const fetchPhysicalBusinessReview = async () => {
      if (plateFormID && (Number(plateFormID) === 7 || placeID)) {
        if (Number(plateFormID) === 3 && !zomatoRatingType) {
          return;
        }
        try {
          const payload = {
            query:
              Number(plateFormID) === 7
                ? reviewPlatformData.getOnlineBusinessReview
                : reviewPlatformData.getAllBusinessReviews,
            variables: {
              pageNumber: page,
              pageSize: rowsPerPage,
              filterReviewRating: ['fiveStarReview', 'fourStarReview'],
              placeId: placeID,
              ...(Number(plateFormID) === 3 && {
                ratingType: zomatoRatingType,
              }),
              ...(Number(plateFormID) === 7
                ? {
                    businessId: Number(userCurrentBusinessDetails?.id),
                    reviewPlatformId: Number(plateFormID),
                  }
                : {
                    platformId: Number(plateFormID),
                  }),
            },
          };

          const result = await handleApiRequest(payload);
          const res =
            Number(plateFormID) === 7
              ? result?.getOnlineBusinessReview
              : result?.getAllBusinessReviews;

          if (res?.status === 200) {
            setReviewData(res?.data);
            setSelectedReviewData(res?.data[0]);
            setTotalCount(res?.totalReviews || res?.totalReview);
          } else {
            enqueueSnackbar(res?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        } catch (error) {
          enqueueSnackbar('Failed to fetch reviews. Please try again.', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    };
    fetchPhysicalBusinessReview();
  }, [
    enqueueSnackbar,
    page,
    placeID,
    plateFormID,
    rowsPerPage,
    userCurrentBusinessDetails?.id,
    zomatoRatingType,
  ]);

  const fetchProducts = useCallback(
    async (plateFormIds) => {
      if (userCurrentBusinessDetails?.id) {
        const payload = {
          query:
            Number(plateFormIds) === 5
              ? manageBusinessData.getAmazonProduct
              : Number(plateFormIds) === 6 && manageBusinessData.getFlipkartProduct,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        const res =
          Number(plateFormIds) === 5
            ? result?.getAmazonProduct
            : Number(plateFormIds) === 6 && result?.getFlipkartProduct;
        if (res?.status === 200) {
          setProducts(res?.data);
          setPage(1);
          const product = res?.data[0];
          setSelectedProduct(product);
          setPlaceID(product?.placeId);
        } else {
          enqueueSnackbar(res?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
      setShouldFetch(true);
    },
    [userCurrentBusinessDetails?.id, enqueueSnackbar]
  );

  useEffect(() => {
    if (userCurrentBusinessDetails?.isOnlineBusiness) {
      const sortData = userCurrentBusinessDetails?.reviewplatform
        ?.slice()
        ?.sort((a, b) => a.id - b.id);
      setTotalReview(sortData[0]?.totalReview);
      setPlateFormID(sortData[0]?.reviewPlatformId);
      setLogo(sortData[0]?.logo);
      fetchProducts(sortData[0]?.reviewPlatformId, sortData[0]?.totalReview);
      setShouldFetch(true);
    }
  }, [
    fetchProducts,
    userCurrentBusinessDetails?.isOnlineBusiness,
    userCurrentBusinessDetails?.reviewplatform,
  ]);

  useEffect(() => {
    if (!userCurrentBusinessDetails?.isOnlineBusiness) {
      const findDefaultPlateForm = userCurrentBusinessDetails?.reviewplatform?.find(
        (item) => item.defaultReview === true
      );
      setPlaceID(findDefaultPlateForm?.placeId);
      setTotalReview(
        Number(findDefaultPlateForm?.reviewPlatformId) === 3
          ? findDefaultPlateForm?.aggregateData?.aggregateTotalReview
          : findDefaultPlateForm?.totalReview
      );
      setPlateFormID(findDefaultPlateForm?.reviewPlatformId);
      setLogo(findDefaultPlateForm?.logo);
      setZomatoRatingType('dining');
      setPage(1);
      setShouldFetch(true);
    }
  }, [userCurrentBusinessDetails?.reviewplatform, userCurrentBusinessDetails?.isOnlineBusiness]);

  const selectReviewData = (data) => {
    setSelectedReviewData(data);
  };

  useEffect(() => {
    if (plateFormID === 7 && !isInitialFetchComplete) {
      setIsInitialFetchComplete(true);
    }
  }, [page, rowsPerPage, isInitialFetchComplete, plateFormID]);

  const changeReviewPlatForm = async (reviewPlatformId) => {
    await setSelectedReviewData();
    const findReviewData = userCurrentBusinessDetails?.reviewplatform?.find(
      (item) => item.reviewPlatformId === reviewPlatformId
    );
    setLogo(findReviewData?.logo);
    setPage(1);
    setRowsPerPage(10);
    setProducts([]);
    setReviewData([]);
    setPlaceID(findReviewData?.placeId);
    setPlateFormID(findReviewData?.reviewPlatformId);
    setZomatoRatingType('dining');

    if (
      userCurrentBusinessDetails?.isOnlineBusiness &&
      Number(findReviewData?.reviewPlatformId) !== 7
    ) {
      await fetchProducts(findReviewData?.reviewPlatformId);
    }
    setShouldFetch(true);
  };

  const handleChangePage = (event, value) => {
    setPage(value + 1);
  };

  const handleChangeRowsPerPage = (event, value) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleSelectProduct = (id) => {
    const findProduct = products?.find((product) => product?.id === id);
    setSelectedProduct(findProduct);
    setPlaceID(findProduct?.placeId);
    setPlateFormID(plateFormID);
    setShouldFetch(true);
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  const redirectAddProduct = () => {
    const id = getParamsId();
    history.push(id ? `quick-reviews/product/${id}` : 'quick-reviews/product');
  };

  const handleCreatePostPopUp = () => {
    const currentPath = location.pathname;
    const id = getParamsId();
    if (!currentPath) {
      console.error('currentPath is undefined or empty.');
      return;
    }
    if (
      currentPath === `/quick-social/social-reviews/${id}` ||
      currentPath === '/quick-social/social-reviews/'
    ) {
      setReviewTemplates(true);
    } else if (
      currentPath === undefined ||
      '/quick-social/template/' ||
      '/quick-social/dashboard/'
    ) {
      history.push('/quick-social/post', {
        data: selectedImage,
        reviewTemplate: selectedReviewData,
        PFlogo: logo,
        plateFormID,
      });
    }
  };

  const closeCreatePostPopUp = () => {
    setReviewTemplates(false);
  };

  return (
    <div className="lg:p-24 p-14">
      <div className="flex w-full items-start sm:items-center flex-col sm:flex-row justify-normal sm:justify-between lg:mb-20 mb-16">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="xs:flex md:text-28 text-20 font-bold mb-16 sm:mb-0"
        >
          {reviewTitle || t('reviews.title')}
        </Typography>
        <div className="w-full sm:w-256 text-left">
          <Select
            className="rounded-md h-40 bg-white text-16 w-full sm:w-256 leading-5 shadow-none capitalize"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '8px', zIndex: '10' } }}
            value={Number(plateFormID)}
            fullWidth
            disabled={userCurrentBusinessDetails?.reviewplatform?.length === 1}
            onChange={(e) => changeReviewPlatForm(e?.target?.value)}
            IconComponent={() => (
              <ArrowDropDownOutlinedIcon className="text-28 align-middle absolute right-14 text-black ml-2" />
            )}
          >
            {!userCurrentBusinessDetails?.isOnlineBusiness &&
              userCurrentBusinessDetails?.reviewplatform
                ?.slice()
                .sort((a, b) => b.defaultReview - a.defaultReview)
                .map((review, i) => (
                  <MenuItem className="capitalize" value={review?.reviewPlatformId} key={i}>
                    <div className="flex gap-8">
                      <img className="w-20" src={review?.logo} alt="" />{' '}
                      <Typography> {review?.name && capitalizeFirstLetter(review.name)}</Typography>
                    </div>
                  </MenuItem>
                ))}
            {userCurrentBusinessDetails?.isOnlineBusiness &&
              userCurrentBusinessDetails?.reviewplatform
                ?.slice()
                ?.sort((a, b) => a.id - b.id)
                .map((item, index) => {
                  return (
                    <MenuItem className="capitalize" value={item?.reviewPlatformId} key={index}>
                      <div className="flex gap-8">
                        <img className="w-20" src={item?.logo} alt="" />{' '}
                        <Typography> {item?.name && capitalizeFirstLetter(item.name)}</Typography>
                      </div>
                    </MenuItem>
                  );
                })}
          </Select>
        </div>
      </div>
      <>
        {(Number(totalReview) > 0 && reviewData?.length > 0) ||
        (userCurrentBusinessDetails?.isOnlineBusiness && products?.length > 0) ? (
          <>
            <div className="bg-white w-full rounded-md p-16">
              <Typography className="sm:flex text-16 md:text-20 font-semibold mb-10">
                {t('quickSocial.title')}
              </Typography>
              <div className="flex flex-col-reverse md:flex-row w-full gap-20">
                <div className="overflow-scroll max-h-640 md:w-1/3">
                  {userCurrentBusinessDetails?.isOnlineBusiness &&
                    products?.length > 0 &&
                    Number(plateFormID) !== 7 && (
                      <Select
                        className="inline-block bg-white text-black capitalize border-none pt-0 font-semibold w-full mb-12 relative"
                        size="small"
                        value={selectedProduct?.id}
                        onChange={(e) => handleSelectProduct(e.target.value)}
                      >
                        {products?.map((product, i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={product?.id}
                              className="flex capitalize whitespace-normal"
                            >
                              <div className="w-full max-w-[460px]">{product?.genericName}</div>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  {reviewData?.length > 0 &&
                    reviewData?.map((review, index) => {
                      return (
                        <div
                          className={`flex rounded-md mb-16 bg-gray-A300 p-12 border border-solid border-gray-A300 ${
                            Number(selectedReviewData?.id) === Number(review?.id)
                              ? 'bg-quick-social text-white'
                              : ''
                          }`}
                          key={index}
                          onClick={() => selectReviewData(review)}
                          onKeyDown={(e) => {}}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="w-full">
                            <div className="flex w-full justify-between">
                              <div className="flex itemx-center gap-12">
                                {review?.reviewerProfileUrl ? (
                                  <img
                                    className="w-48 h-48 rounded-3xl"
                                    src={
                                      review?.reviewerProfileUrl || 'assets/images/icon/Profile.png'
                                    }
                                    alt=""
                                  />
                                ) : (
                                  <UserProfile
                                    name={review?.reviewerName}
                                    width="45px"
                                    height="45px"
                                  />
                                )}
                                <div className="">
                                  <Typography className="font-semibold">
                                    {review?.reviewerName}
                                  </Typography>
                                  {[...Array(5)]?.map((_, i) => (
                                    <Icon
                                      className={`text-16 align-middle mr-2 -mt-2 ${
                                        i <
                                        (Number(plateFormID) !== 7
                                          ? review?.reviewRating
                                          : review?.rating)
                                          ? 'text-yellow-800'
                                          : 'text-gray-400'
                                      }`}
                                      key={i}
                                    >
                                      star
                                    </Icon>
                                  ))}
                                  {review?.reviewRating || review?.rating}.0
                                </div>
                              </div>
                              <Typography className="">
                                {formatDateWithTimeZone(review?.reviewCts, 'MMM DD, YYYY')}
                              </Typography>
                            </div>
                            <Typography className="pt-12 text-14">
                              {review?.review && review?.review?.length > 190
                                ? `${review.review?.slice(0, 190)}...`
                                : review?.review}
                            </Typography>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {Number(totalReview) > 0 && reviewData?.length === 0 && (
                  <div className="h-full text-20 font-bold justify-center text-center my-192">
                    <img className="m-auto" src="/assets/images/business/Processing.png" alt="" />
                    <Typography className="font-semibold text-20 my-20">
                      {t('reviews.processing')}
                    </Typography>
                    <Typography className="text-16">{t('reviews.loadingReviewMessage')}</Typography>
                  </div>
                )}
                {Number(totalReview) === 0 && reviewData?.length === 0 && (
                  <div className="text-center my-192">
                    <img className="m-auto" src="/assets/images/business/reviews.svg" alt="" />
                    <Typography className="font-semibold text-20 my-20">
                      {t('reviews.noReviews')}
                    </Typography>
                    <Typography className="text-16">{t('reviews.message')}</Typography>
                  </div>
                )}
                {selectedReviewData && (
                  <div className="h-max md:h-full md:overflow-scroll rounded-md md:w-2/3 bg-gray-A300 p-12">
                    <div className="flex justify-between">
                      <div className="flex items-center gap-12">
                        {selectedReviewData?.reviewerProfileUrl ? (
                          <img
                            className="w-48 h-48 rounded-3xl"
                            src={
                              selectedReviewData?.reviewerProfileUrl ||
                              'assets/images/icon/Profile.png'
                            }
                            alt=""
                          />
                        ) : (
                          <UserProfile
                            name={selectedReviewData?.reviewerName}
                            width="45px"
                            height="45px"
                          />
                        )}
                        <div className="">
                          <Typography className="font-semibold">
                            {selectedReviewData?.reviewerName}
                          </Typography>
                          {[...Array(5)].map((_, i) => (
                            <Icon
                              className={`text-16 align-middle mr-2 -mt-2 ${
                                i <
                                (Number(plateFormID) !== 7
                                  ? selectedReviewData?.reviewRating
                                  : selectedReviewData?.rating)
                                  ? 'text-yellow-800'
                                  : 'text-gray-400'
                              }`}
                              key={i}
                            >
                              star
                            </Icon>
                          ))}{' '}
                          {selectedReviewData?.reviewRating || selectedReviewData?.rating}
                          .0
                        </div>
                      </div>
                      <Typography className="">
                        {formatDateWithTimeZone(selectedReviewData?.reviewCts, 'MMM DD, YYYY')}
                      </Typography>
                    </div>
                    <Typography className="font-medium pt-8 text-14 md:text-16">
                      {selectedReviewData?.review}
                    </Typography>
                    <div className="grid grid-cols-2 gap-8  sm:grid-cols-3 lg:grid-cols-4">
                      {selectedReviewData?.imageLinks?.length > 0 &&
                        selectedReviewData?.imageLinks?.map((item, i) => {
                          return (
                            <div className="" key={i}>
                              <img
                                className="sm:w-192 sm:h-192 w-160 h-160 m-auto rounded-md mt-24"
                                src={item}
                                alt=""
                              />
                            </div>
                          );
                        })}
                    </div>
                    <Button
                      className="rounded-md mt-20 text-14 sm:text-16 px-20 font-500"
                      size="medium"
                      variant="contained"
                      color="quickSocial"
                      onClick={handleCreatePostPopUp}
                    >
                      <EditIcon className="text-16 sm:text-20 mr-10" />
                      {t('createPost.title')}
                    </Button>
                  </div>
                )}
                <ReviewTemplatePopup
                  open={reviewTemplates}
                  onClose={closeCreatePostPopUp}
                  reviewData={selectedReviewData}
                  plateFormID={plateFormID}
                  logo={logo}
                />
              </div>
              {totalCount > 10 && (
                <div className="sm:w-1/3">
                  <div className="flex justify-center">
                    <Pagination
                      totalCount={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="text-center mt-160 sm:mt-256">
            {Number(totalReview) > 0 && !userCurrentBusinessDetails?.isOnlineBusiness ? (
              <div className="h-full text-20 font-bold justify-center">
                <img className="m-auto" src="/assets/images/business/Processing.png" alt="" />
                <Typography className="font-semibold text-20 my-20">
                  {t('reviews.processing')}
                </Typography>
                <Typography className="text-16">{t('reviews.loadingReviewMessage')}</Typography>
              </div>
            ) : (
              <div className="h-full text-20 font-bold justify-center">
                {(Number(plateFormID) === 5 || Number(plateFormID) === 6) &&
                products?.length === 0 ? (
                  <div>
                    <img
                      className="m-auto mb-16"
                      src="/assets/images/business/products.png"
                      alt=""
                    />
                    {Number(plateFormID) === 5 && (
                      <div>
                        <Typography className="font-semibold text-20 my-16">
                          {t('products.noAmazonProduct')}
                        </Typography>
                        <Typography className="text-16 max-w-640 m-auto">
                          {t('products.addAmazonProductMessage')}
                        </Typography>
                        <Button
                          className="bg-darkgreen rounded-md text-white px-36 hover:bg-darkgreen mt-16"
                          onClick={() => redirectAddProduct()}
                        >
                          {t('products.addAmazonProduct')}
                        </Button>
                      </div>
                    )}
                    {Number(plateFormID) === 6 && (
                      <div>
                        <Typography className="font-semibold text-20 my-16">
                          {t('products.noFlipKartProduct')}
                        </Typography>
                        <Typography className="text-16 max-w-640 m-auto">
                          {t('products.addFlipkartProductMessage')}
                        </Typography>
                        <Button
                          className="bg-darkgreen rounded-md text-white px-36 hover:bg-darkgreen mt-16"
                          onClick={() => redirectAddProduct()}
                        >
                          {t('products.addFlipkartProduct')}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <img className="m-auto" src="/assets/images/business/reviews.svg" alt="" />
                    <Typography className="font-semibold text-20 my-20">
                      {t('reviews.noReviews')}
                    </Typography>
                    <Typography className="text-16">{t('reviews.message')}</Typography>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default SocialReviews;
