import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import ReactApexChart from 'react-apexcharts';
import { Icon, Tooltip, Typography } from '@mui/material';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../../query/metrics';
import NoData from '../../matrices/noDataScreen';
import {
  capitalizeFirstLetter,
  formatDateWithTimeZone,
  getDataToServer,
} from '../../../common/common';

function ConversationMatrices(props) {
  const { metricsIconInfo } = props;
  const [newGraphData, setNewGraphData] = useState([]);
  const [summaryData, setSummaryData] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          query: metricsData.getQuickChatConversationMatrix,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            botTypeId: 1,
          },
        };
        const result = await getDataToServer(payload);
        const keyValueObject = result?.getQuickChatConversationMatrix?.data?.conversations
          ?.map((item) => {
            const obj = {};
            item?.forEach(({ key, value }) => {
              obj[key] = value;
            });
            return obj;
          })
          ?.sort((a, b) => b.timeStamp - a.timeStamp);
        setNewGraphData(keyValueObject);
        const obj = {};
        result?.getQuickChatConversationMatrix?.data?.summary?.forEach(({ key, value }) => {
          obj[key] = value;
          return obj;
        });
        setSummaryData(obj);
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchData();
    }
  }, [userCurrentBusinessDetails?.id]);

  function getChartSimple(data) {
    const chart = {
      series: [
        {
          name: 'Conversations',
          data: data.map((period) => period.count),
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '20%',
            distributed: false,
          },
        },
        fill: {
          colors: ['#1E90FF'],
        },
        xaxis: {
          categories: data.map((period) => formatDateWithTimeZone(period?.timeStamp, 'DD MMM')),
          labels: {
            style: {
              colors: '#000',
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          categories: data.map((period) => period?.count),
          tickAmount: 10,
          labels: {
            style: {
              colors: '#000',
              fontSize: '12px',
            },
          },
          title: {
            style: {
              color: '#000',
              fontSize: '14px',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          theme: 'light',
        },
        grid: {
          show: true,
          borderColor: '#C5C5C5',
          strokeDashArray: 4,
        },
      },
    };

    return (
      <ReactApexChart
        options={chart.options}
        series={chart.series}
        type="bar"
        height={500}
        width="100%"
      />
    );
  }

  return (
    <>
      <div className="flex flex-row">
        <h3 className="text-16 lg:text-18 font-bold mb-16 items-center">
          {t('metrics.conversations')}
        </h3>
        <Tooltip
          title={metricsIconInfo}
          arrow
          classes={{
            tooltip: 'bg-quick-chat',
            arrow: 'text-quick-chat',
          }}
        >
          <Icon className="text-quick-chat text-20 align-middle mt-4 ml-4">info</Icon>
        </Tooltip>
      </div>
      {newGraphData?.length > 0 ? (
        <>
          <div>{getChartSimple(newGraphData)}</div>
          <div className="bg-gray-A500 rounded-md py-8 px-24 mx-16">
            <div className="flex gap-16">
              <img
                className="w-60 h-60"
                src="/assets/images/business/conversationchat.png"
                alt=""
              />
              <div className="grid">
                <Typography className="font-semibold text-20">{summaryData?.totalCount}</Typography>
                <Typography className="font-normal">
                  {capitalizeFirstLetter(summaryData?.text)}
                </Typography>
              </div>
            </div>
          </div>
        </>
      ) : (
        <NoData />
      )}
    </>
  );
}

export default ConversationMatrices;
