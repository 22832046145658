import { Typography } from '@mui/material';
import withReducer from 'app/store/withReducer';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import history from '@history';
import _ from '@lodash';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import { useEffect, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import UserService from '../../../services/userService';
import cmsData from '../../query/cms';
import {
  getUserTemplateBasicDetails,
  updateUserTemplateBasicDetails,
  resetCmsWebsite,
} from './store/cmsWebsiteSlice';
import reducer from './store';

function About() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * Form Validation Schema
   */

  const defaultValues = {
    companyHeading: '',
    companyInformation: '',
    companyVision: '',
    CompanyMission: '',
  };

  const schema = yup.object().shape({
    companyInformation: yup.string(),
    // .required(t('appWebsite.about.formField.companyInformation.validationMessages.required')),
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const [loading, setLoading] = useState(true);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [imageUpload, setImageUpload] = useState();
  const [imagePreviewName, setImagePreviewName] = useState();
  const [noImgMsg, setNoImgMsg] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const loginUserData = UserService.getUserData();
  const userDomainRegisterId = parseInt(loginUserData?.domainRegisterId, 10);
  const isValidDomainRegisterId = loginUserData?.domainRegisterId;

  const templateDetails = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.getUserTemplateBasicDetailsData?.data
        ?.getUserTemplateBasicDetails?.data[0] || ''
  );
  const addAboutInformationStatus = useSelector(
    ({ cmsWebsiteReducer }) =>
      cmsWebsiteReducer?.cmsWebsite?.updateUserTemplateBasicDetailsData?.data
        ?.updateUserTemplateBasicDetails || ''
  );

  useEffect(() => {
    if (isValidDomainRegisterId) {
      const payload = {
        query: cmsData.getUserTemplateBasicDetails,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      dispatch(getUserTemplateBasicDetails(payload));
    }
    return () => {
      dispatch(resetCmsWebsite());
    };
  }, [dispatch, userDomainRegisterId, isValidDomainRegisterId]);

  useEffect(() => {
    if (isValidDomainRegisterId) {
      if (templateDetails || templateDetails === null) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [templateDetails, isValidDomainRegisterId]);

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: cmsData.updateUserTemplateBasicDetails,
        variables: {
          aboutHeading: data.companyHeading,
          aboutUs: data.companyInformation,
          vision: data.companyVision,
          mission: data.companyMission,
          domainRegisterId: userDomainRegisterId,
          themeId: parseInt(templateDetails.webTemplateId, 10),
          aboutImage: imageUpload,
        },
      };
      await dispatch(updateUserTemplateBasicDetails(payload));
      const payloadAbout = {
        query: cmsData.getUserTemplateBasicDetails,
        variables: { domainRegisterId: userDomainRegisterId },
      };
      await dispatch(getUserTemplateBasicDetails(payloadAbout));
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  useEffect(() => {
    if (addAboutInformationStatus && addAboutInformationStatus.status === 400) {
      enqueueSnackbar(addAboutInformationStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      closeSnackbar(3000);
    } else if (addAboutInformationStatus && addAboutInformationStatus.status === 200) {
      enqueueSnackbar(addAboutInformationStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      setIsEdit(false);
      closeSnackbar(3000);
    }
  }, [reset, dispatch, addAboutInformationStatus, enqueueSnackbar, closeSnackbar]);

  if (loading) {
    return <FuseLoading />;
  }

  function handleFileChange(e) {
    const file = e.target.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();
    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      setImageUpload(file);
      setImagePreviewName(file?.name);
    };
    setNoImgMsg('');
  }

  function handleDrop(e) {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async () => {
      setImageUpload(file);
      setImagePreviewName(file?.name);
    };
    setNoImgMsg('');
  }
  function redirectUserRoutes() {
    return history.push({
      pathname: `/app-website`,
    });
  }
  return (
    <div className="p-20 lg:p-24 h-full">
      {isValidDomainRegisterId ? (
        <>
          <div className="items-center mb-36">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="text-16 md:text-24 font-bold mb-20"
            >
              {t('appWebsite.about.title')}
            </Typography>
            <>
              {!isEdit ? (
                <div className="inline-block items-center w-auto float-right">
                  <Button
                    className="text-18 px-15 font-semibold"
                    variant="outlined"
                    color="secondary"
                    aria-label="Edit"
                    onClick={(e) => setIsEdit(true)}
                  >
                    <Icon>edit</Icon>
                    {t('common.edit')}
                  </Button>
                </div>
              ) : (
                <div className="inline-block items-center w-auto float-right">
                  <Button
                    className="text-18 px-15 font-semibold"
                    variant="outlined"
                    color="secondary"
                    aria-label="Edit"
                    onClick={(e) => setIsEdit(false)}
                  >
                    {t('common.cancel')}
                  </Button>
                </div>
              )}
            </>
          </div>
          {!templateDetails || isEdit ? (
            <div className="bg-white rounded-md relative shadow p-24">
              <form
                name="registerForm"
                noValidate
                className="w-full mt-32"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <div className="">
                    <div className="block">
                      <InputLabel
                        htmlFor="aboutHeading"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.about.formField.companyHeading.name')}*
                      </InputLabel>
                      <Controller
                        name="companyHeading"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            multiline
                            rows={4}
                            value={undefined}
                            defaultValue={templateDetails?.aboutHeading}
                            error={!!errors.companyHeading}
                            helperText={errors?.companyHeading?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="companyInformation"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.about.formField.companyInformation.name')}*
                      </InputLabel>
                      <Controller
                        name="companyInformation"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            multiline
                            rows={4}
                            value={undefined}
                            defaultValue={templateDetails?.aboutUs}
                            error={!!errors.companyInformation}
                            helperText={errors?.companyInformation?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="companyVision"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.about.formField.companyVision.name')}
                      </InputLabel>
                      <Controller
                        name="companyVision"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            defaultValue={templateDetails?.vision}
                            multiline
                            rows={4}
                            error={!!errors.companyVision}
                            helperText={errors?.companyVision?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="block">
                      <InputLabel
                        htmlFor="companyMission"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.about.formField.companyMission.name')}
                      </InputLabel>
                      <Controller
                        name="companyMission"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-24"
                            sx={{
                              '& .MuiInputBase-input': {
                                background: 'white',
                              },
                            }}
                            type="text"
                            size="small"
                            value={undefined}
                            defaultValue={templateDetails?.mission}
                            multiline
                            rows={4}
                            error={!!errors.companyMission}
                            helperText={errors?.companyMission?.message}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <motion.div className="block mb-24">
                      <InputLabel
                        htmlFor="businessEmail"
                        className="text-16 font-medium mb-12 text-grey-900"
                      >
                        {t('appWebsite.about.formField.uploadImage.name')}
                      </InputLabel>
                      <Paper
                        className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-darkgreen p-12 bg-white"
                        role="button"
                        onDrop={handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <div className="inline-block">
                          <label htmlFor="button-file">
                            <input
                              accept="image/*"
                              className="hidden"
                              id="button-file"
                              type="file"
                              onChange={handleFileChange}
                            />
                            <Button
                              size="small"
                              className="rounded block bg-darkgreen-100 text-darkgreen text-14 font-medium hover:bg-darkgreen hover:text-white"
                              variant="contained"
                              color="secondary"
                              component="span"
                            >
                              <Icon color="action material-symbols-outlined align-middle">
                                upload
                              </Icon>{' '}
                              {t('reviewPlatform.buttons.uploadReviewIcon')}
                            </Button>
                          </label>
                        </div>
                        <div className="inline-block ml-16 font-medium">
                          {imagePreviewName || `${t('appWebsite.about.formField.OrDropImage')}`}
                        </div>
                        {noImgMsg && <span className="text-red block mt-16">{noImgMsg}</span>}
                      </Paper>
                    </motion.div>
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="mt-24 mx-auto md:px-80 md:text-18 font-semibold disabled:text-black rounded-md"
                    aria-label="Register"
                    disabled={refreshIcon}
                    type="submit"
                    size="small"
                  >
                    {t('common.save')}{' '}
                    {refreshIcon && (
                      <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                    )}
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <div>
              <div className="bg-white rounded-md relative shadow p-24 mb-40">
                <div className="items-center mb-36 border-b-2 border-dashed border-grey-400 pb-20">
                  <Typography
                    component={motion.span}
                    initial={{ x: -20 }}
                    animate={{ x: 0, transition: { delay: 0.2 } }}
                    delay={300}
                    className="text-16 md:text-24 font-semibold mb-20"
                  >
                    {t('appWebsite.about.formField.companyHeading.name')}
                  </Typography>
                </div>
                <Typography className="text-14 md:text-16 font-semibold">
                  {templateDetails?.aboutHeading}
                </Typography>
              </div>
              <div className="bg-white rounded-md relative shadow p-24 mb-40">
                <div className="items-center mb-36 border-b-2 border-dashed border-grey-400 pb-20">
                  <Typography
                    component={motion.span}
                    initial={{ x: -20 }}
                    animate={{ x: 0, transition: { delay: 0.2 } }}
                    delay={300}
                    className="text-16 md:text-24 font-semibold mb-20"
                  >
                    {t('appWebsite.about.formField.companyInformation.name')}
                  </Typography>
                </div>
                <Typography className="text-14 md:text-16 font-semibold">
                  {templateDetails?.aboutUs}
                </Typography>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-20 lg:gap-40 mb-40">
                <div className="bg-white rounded-md relative shadow p-24">
                  <div className="items-center mb-36 border-b-2 border-dashed border-grey-400 pb-20">
                    <Typography
                      component={motion.span}
                      initial={{ x: -20 }}
                      animate={{ x: 0, transition: { delay: 0.2 } }}
                      delay={300}
                      className="text-16 md:text-24 font-semibold mb-20"
                    >
                      {t('appWebsite.about.formField.companyVision.name')}
                    </Typography>
                  </div>
                  <Typography className="text-14 md:text-16 font-semibold">
                    {templateDetails?.vision}
                  </Typography>
                </div>
                <div className="bg-white rounded-md relative shadow p-24">
                  <div className="items-center mb-36 border-b-2 border-dashed border-grey-400 pb-20">
                    <Typography
                      component={motion.span}
                      initial={{ x: -20 }}
                      animate={{ x: 0, transition: { delay: 0.2 } }}
                      delay={300}
                      className="text-16 md:text-24 font-semibold mb-20"
                    >
                      {t('appWebsite.about.formField.companyMission.name')}
                    </Typography>
                  </div>
                  <Typography className="text-14 md:text-16 font-semibold">
                    {templateDetails?.mission}
                  </Typography>
                </div>
              </div>
              <img
                src={templateDetails?.aboutImage}
                className="img-fluid max-w-480 rounded-md"
                alt="trial_accounts"
              />
            </div>
          )}
        </>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full"
        >
          <div>
            <img
              src="/assets/images/business/register.png"
              className="img-fluid mx-auto max-w-160 pb-40"
              alt="..."
            />
            <h5 className="block text-20 font-bold text-black text-center">
              {t('business.validationMessage.domainRegistration')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-14 py-12 max-w-640 text-center mx-auto"
            >
              {t('business.validationMessage.desc')}
              <br />
              {t('business.validationMessage.shortDesc')}
            </Typography>
            <div className="text-center">
              <Button
                variant="contained"
                color="secondary"
                className="mt-24 sm:min-w-256 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                aria-label="Search"
                type="button"
                size="medium"
                onClick={() => redirectUserRoutes()}
              >
                {t('business.validationMessage.button')}
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default withReducer('cmsWebsiteReducer', reducer)(About);
