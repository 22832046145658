import { Button, CircularProgress, Divider, Paper, TextField, Typography } from '@mui/material';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { getCountryData, setCountries } from 'app/store/userSlice';
import { t } from 'i18next';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import AppleLogin from 'react-apple-login';
import ReactFlagsSelect from 'react-flags-select';
import history from '@history';
import { publicIpv4 } from 'public-ip';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  checkEmailExistOrNot,
  checkPhoneNumberExistOrNot,
  fetchCountries,
  getDataToServerForAuth,
  getDecryptData,
  getEncryptedData,
  handleApiRequest,
  sendOTPOnEmailVerification,
  sendOTPOnPhoneNumberVerification,
} from '../common/common';
import Query from '../query/auth';
import PolicySection from './PolicySection';
import QuickProducts from '../quick-products/QuickProducts';
import queryData from '../query/common';

const EmailOrPhone = () => {
  const location = useLocation();
  const [showPhoneDropdown, setShowPhoneDropdown] = useState(!!location?.state?.phoneNumber);
  const countriesData = useSelector(getCountryData);
  const [userValue, setUserValue] = useState(
    location?.state?.email || location?.state?.phoneNumber || ''
  );
  const [selected, setSelected] = useState('IN');
  const [countryIsoData, setCountryIsoData] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [countryList, setCountryList] = useState([]);
  const [googleAccessToken, setGoogleAccessToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();

  const [ipBasedData, setIpBasedData] = useState(
    localStorage.getItem('ipData') ? JSON.parse(getDecryptData(localStorage.getItem('ipData'))) : ''
  );

  const countryDataString = localStorage.getItem('countries');
  useEffect(() => {
    if (countryDataString) {
      const countryDatas = JSON.parse(countryDataString);

      if (countryDatas?.length > 0) {
        const isoCodes = countryDatas.map((country) => country.isoCode);
        setCountryIsoData(isoCodes);
      }
    }
  }, [countryDataString]);

  useEffect(() => {
    setCountryList(countriesData || JSON.parse(countryDataString));
  }, [countriesData, countryDataString]);

  const customLabels = useMemo(() => {
    if (!Array.isArray(countryList)) {
      return {};
    }

    return countryList.reduce((acc, country) => {
      acc[country.isoCode] = {
        primary: country.name,
        secondary: `+${country.countryCode}`,
        isoCode: country.isoCode,
        countryId: country?.id,
      };
      return acc;
    }, {});
  }, [countryList]);

  useEffect(() => {
    if (!ipBasedData) {
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: queryData.getLocation,
          variables: { data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`) },
        };
        const locationResponse = await handleApiRequest(payload);
        if (locationResponse?.getLocation?.data) {
          const locationData = locationResponse?.getLocation?.data;
          if (locationData) {
            localStorage.setItem('ipData', locationData);
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
          }
        }
      };
      ipAddressData();
    }
  }, [ipBasedData]);

  useEffect(() => {
    if (ipBasedData && ipBasedData.countryCode) {
      const matchingLabel = Object.entries(customLabels)?.find(
        ([key, value]) => value.secondary === `+${ipBasedData.countryCode}`
      );
      if (matchingLabel) {
        setSelected(matchingLabel[1].isoCode);
      }
    }
  }, [ipBasedData, customLabels]);

  useEffect(() => {
    if (Array.isArray(countryList)) {
      const isoCode = countryList?.map((country) => country.isoCode);
      setCountryIsoData(isoCode);
    }
  }, [countryList]);

  useEffect(() => {
    if (userValue === '') {
      setErrorMessage('');
    }
  }, [userValue]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setUserValue(inputValue);
    if (/^\d+$/.test(inputValue)) {
      e.target.type = 'tel';
      setShowPhoneDropdown(true);
      setErrorMessage(
        e.target.type === 'tel' && /^\d+$/.test(inputValue) && inputValue?.length > 9
          ? ''
          : 'Enter valid phone number'
      );

      const digitCount = inputValue.replace(/\D/g, '').length;
      if (digitCount > 15) {
        e.target.value = inputValue.substring(0, 10);
        setUserValue(inputValue.substring(0, 15));
      }
    } else {
      setErrorMessage(
        e.target.type === 'text' && /\S+@\S+\.\S+/.test(inputValue)
          ? ''
          : t('signInPage.formField.email.validationMessages.validEmail')
      );
      e.target.type = 'text';
      setShowPhoneDropdown(false);
    }
  };

  const responseGoogle = async (response) => {
    const decodeData = jwtDecode(response?.credential);
    const matchingLabel = Object.entries(customLabels).find(
      ([key, value]) => value.isoCode === selected
    )?.[1];
    if (response) {
      try {
        const googleResponse = JSON.stringify({
          accessToken: response.credential,
        });
        const payload = {
          query: Query.asPreviouslyRegesterInGoogle,
          variables: { data: getEncryptedData(googleResponse) },
        };
        const registrationResult = await getDataToServerForAuth(payload);
        if (registrationResult?.asPreviouslyRegesterInGoogle?.available === true) {
          history.push('/userInformation', {
            email: decodeData?.email,
            phoneNumber: '',
            countryCode: matchingLabel.secondary || `+${ipBasedData?.countryCode}`,
            isoCode: matchingLabel.isoCode || ipBasedData?.isoCode,
            countryId: matchingLabel.countryId || ipBasedData?.countryId,
            firstName: decodeData?.given_name || '',
            lastName: decodeData?.family_name || '',
            isEmailVerified: true,
            isPhoneNumberVerified: false,
            isComeFromUserInformation: false,
            userPackageIds: selectedProducts,
            isGoogle: true,
            googleResponse: response?.credential,
          });
        } else {
          enqueueSnackbar(t('signInPage.messages.notRegistered'), {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        enqueueSnackbar(t('signInPage.messages.errorOccurred'), {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    }
  };

  const handleSubmit = async () => {
    setRefreshIcon(true);
    const matchingLabel = Object.entries(customLabels).find(
      ([key, value]) => value.isoCode === selected
    )?.[1];
    try {
      const isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(userValue);
      const isPhoneNumber = /^\d+$/.test(userValue);
      if (isEmail) {
        const checkEmailExist = await checkEmailExistOrNot(userValue, enqueueSnackbar);
        if (!checkEmailExist) return;

        const otpSend = await sendOTPOnEmailVerification(userValue, enqueueSnackbar);
        if (!otpSend) return;
        setErrorMessage('');
        history.push('/verifyOtp', {
          email: userValue,
          phoneNumber: '',
          countryCode: matchingLabel?.secondary || `+${ipBasedData?.countryCode}`,
          isoCode: matchingLabel?.isoCode || ipBasedData?.isoCode,
          countryId: matchingLabel?.countryId || ipBasedData?.countryId,
          isEmailVerified: false,
          isPhoneNumberVerified: false,
          userPackageIds: selectedProducts,
        });
      } else if (isPhoneNumber) {
        const checkPhoneNumberExist = await checkPhoneNumberExistOrNot(userValue, enqueueSnackbar);
        if (!checkPhoneNumberExist) return;

        const sendOtpOnPhone = await sendOTPOnPhoneNumberVerification(
          userValue,
          matchingLabel.secondary || `+${ipBasedData?.countryCode}`,
          enqueueSnackbar
        );
        if (!sendOtpOnPhone) return;

        history.push('/verifyOtp', {
          email: '',
          phoneNumber: userValue,
          countryCode: matchingLabel.secondary || `+${ipBasedData?.countryCode}`,
          isoCode: matchingLabel.isoCode || ipBasedData?.isoCode,
          countryId: matchingLabel.countryId || ipBasedData?.countryId,
          isEmailVerified: false,
          isPhoneNumberVerified: false,
          isComeFromUserInformation: false,
          userPackageIds: selectedProducts,
        });
      } else {
        enqueueSnackbar('Invalid email or phone number format.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Unexpected error occurred during mail handling:', error);
    } finally {
      setRefreshIcon(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleProductSelection = (selectedProductsArray) => {
    const productIds = products
      .filter((item) => selectedProductsArray?.includes(item.packageTypeId))
      ?.map((item) => item?.id);
    setSelectedProducts(productIds);
    localStorage.setItem('userPackageIds', JSON.stringify(productIds));
    setIsProductSelected(true);
    if (location?.state) {
      const { token, decodeData, isAppleSignUp } = location.state;
      if (isAppleSignUp) {
        history.push('/userInformation', {
          isAppleSignUp: true,
          token,
          decodeData,
          isEmailVerified: true,
          isPhoneNumberVerified: false,
          email: decodeData?.email,
          userPackageIds: productIds,
        });
      }
    }
  };

  return (
    <div className="flex items-center justify-center bg-white h-full w-full sm:px-0 px-16 sm:py-0 py-40">
      <img
        src="assets/images/background/top-round.png"
        className="absolute top-0 right-0 hidden lg:block"
        alt="..."
      />
      <img
        src="assets/images/background/bottom-round.png"
        className="absolute bottom-0 left-0 hidden lg:block"
        alt="..."
      />
      <div className="py-40">
        <img
          src="assets/images/logo/quick_hub.png"
          className="mx-auto md:max-w-88 max-w-80"
          alt="logo"
        />
        {!isProductSelected && (!location?.state || location?.state?.isAppleSignUp) ? (
          <>
            <Typography className="mt-10 mb-32 text-24 font-bold text-center text-black">
              {t('selectProduct.SelectYourProduct')}
            </Typography>
            <QuickProducts onStartTrial={handleProductSelection} setProducts={setProducts} />
          </>
        ) : (
          <>
            <Typography className="mt-10 mb-32 text-24 font-bold text-center text-black">
              {t('signUpPage.createAccount')}
            </Typography>
            <Paper className="shadow-none">
              <div className="bg-grey-50 sm:max-w-[816px] md:min-w-[816px] w-full mx-auto rounded-2xl items-center justify-center sm:shadow md:shadow-none py-20 px-20 md:py-40 md:px-80">
                <Typography className="text-20 font-bold tracking-tight leading-tight">
                  {t('signUpPage.title')}
                </Typography>
                <div className="sm:flex items-top">
                  <div className="relative block w-full max-w-320 md:w-320 mx-auto sm:mx-0 mt-32">
                    {showPhoneDropdown && (
                      <span className="absolute z-20">
                        <ReactFlagsSelect
                          className="react-flag-button"
                          selected={selected}
                          onSelect={(code) => setSelected(code)}
                          countries={countryIsoData}
                          searchable
                          customLabels={customLabels}
                          showSelectedLabel={false}
                        />
                      </span>
                    )}
                    <TextField
                      className="rounded-md"
                      name="email"
                      style={{ height: '40px' }}
                      placeholder={t('signInPage.formField.email.userNameSignUp')}
                      sx={{
                        '& .MuiInputBase-input': {
                          backgroundColor: '#fff',
                          paddingTop: 0,
                          paddingBottom: 0,
                          height: '40px',
                          fontWeight: '500',
                          textIndent: showPhoneDropdown ? '105px !important' : 'initial',
                          transition: 'all .2s ease-in-out',
                          borderRadius: '5px',
                        },
                      }}
                      autoFocus
                      value={userValue}
                      onChange={handleInputChange}
                      onKeyDown={(event) => {
                        handleKeyPress(event);
                      }}
                      size="small"
                      variant="outlined"
                      required
                      fullWidth
                    />
                    {errorMessage && (
                      <Typography className="text-red text-14  pt-4 font-semibold">
                        {errorMessage}
                      </Typography>
                    )}
                    <div className="mt-24">
                      <Button
                        variant="contained"
                        color="secondary"
                        className="w-full md:text-14 font-semibold disabled:text-black rounded-md"
                        aria-label="Sign in"
                        disabled={!userValue || refreshIcon || errorMessage}
                        onClick={() => handleSubmit()}
                        type="button"
                      >
                        {t('signUpPage.buttons.next')}
                        {refreshIcon && (
                          <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                        )}
                      </Button>
                    </div>
                    <div className="text-left">
                      <Typography className="text-14 font-semibold mt-16 inline-block">
                        {t('signUpPage.messages.alreadyHaveAccount')}
                      </Typography>
                      <Link
                        className="text-14 font-bold text-darkgreen inline-block ml-5"
                        role="button"
                        to="/sign-in"
                      >
                        {t('signInPage.title')}
                      </Link>
                    </div>
                    <PolicySection />
                  </div>
                  <div className="sm:grid flex items-center justify-center md:mx-40 sm:mx-20 sm:my-0 my-20">
                    <Divider className="sm:h-80 sm:w-2 h-1 w-28 bg-gray-300 sm:mx-auto mx-6" />
                    <span className="font-medium text-16">{t('signInPage.messages.or')}</span>
                    <Divider className="sm:h-80 sm:w-2 h-1 w-28 bg-gray-300 sm:mx-auto mx-6" />
                  </div>
                  <div className="block sm:max-w-256 sm:min-w-256 sm:mt-32">
                    <div className="w-full mb-16 text-left relative">
                      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                        <div className="w-full">
                          <GoogleLogin
                            logo_alignment="center"
                            width="256"
                            height="40"
                            text="signup_with"
                            onSuccess={responseGoogle}
                            onError={responseGoogle}
                          />
                        </div>
                      </GoogleOAuthProvider>
                    </div>
                    <div className="w-full mb-16 cursor-pointer">
                      <AppleLogin
                        clientId="org.reactjs.native.example.happyReview"
                        redirectURI={`${process.env.REACT_APP_APPLE_REDIRECT_URL}appleVerify`}
                        scope="email name   "
                        responseMode="form_post"
                        responseType="id_token code"
                        render={(renderProps) => (
                          <Button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="w-full max-w-[256px] border-1 border-solid border-grey-300 bg-white font-semibold text-[#3c4043] rounded h-10 flex items-center justify-center text-center px-12"
                          >
                            <img
                              src="/assets/images/icon/apple.png"
                              alt="Apple logo"
                              className="w-16 h-16 mr-8"
                            />
                            Sign up with Apple
                          </Button>
                        )}
                      />
                    </div>
                    {/*   <div className="mx-auto cursor-pointer">
                      <Button
                        className="border text-14 border-solid border-grey-300 bg-white font-bold rounded w-256 h-40 hover:text-darkgreen flex items-center justify-start px-12"
                        // onClick={() => setIsWhatsApp(true)}
                      >
                        <img
                          className="mr-8 w-16 h-16"
                          src="/assets/images/business/whatsapp.svg"
                          alt=""
                        />
                        {t('whatsapp.continueWhatsappSignUp')}
                      </Button>
                    </div>
                    <span className="font-medium text-14 block text-center mt-6 w-256">
                      {t('signInPage.messages.or')}
                    </span>
                    <Button
                      className="text-darkgreen underline w-256 m-auto hover:bg-white text-center p-0 min-h-0 max-h-0"
                      // onClick={() => handleWhatsappClick()}
                    >
                      {' '}
                      {t('whatsapp.openWhatsapp')}
                    </Button> */}
                  </div>
                </div>
              </div>
            </Paper>
          </>
        )}
      </div>
    </div>
  );
};

export default EmailOrPhone;
