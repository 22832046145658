import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DashBoardPopUp from 'src/app/main/CommonComponent/DashBoardPopUp';
import './Dashboards.css';
import {
  getAllUserBusinessSubscription,
  selectDashboardData,
  setUserCurrentBusinessDetailsValue,
} from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useLocation, useParams } from 'react-router-dom';
import CommonPendingTask from 'src/app/main/CommonComponent/CommonPendingTask';
import { useSnackbar } from 'notistack';
import { MenuItem, Select, Tooltip, Typography } from '@mui/material';
import CommonFreeTrial from 'src/app/main/CommonComponent/CommonFreeTrial';
import history from '../../../../../@history/@history';
import {
  handleApiRequest,
  findExpireDay,
  transformUserSubscriptionData,
  getFilteredData,
  addExpiryToProductArray,
  defaultProductArr,
  formatNumber,
} from '../../../common/common';
import DashboardAlerts from './DashboardAlerts';
import BusinessPlateForm from './BusinessPlatForm';
import metricsData from '../../../query/metrics';
import ReviewDistribution from '../../matrices/reviewDistribution';
import ReceivedReviewsDash from '../../matrices/receivedReviewsDash';
import quickHub from '../../../query/quickHub';
import RepliedVsNotReplied from '../../matrices/RepliedVsNotReplied';
import TextVsNoText from '../../matrices/TextVsNoText';
import reviewPlatformData from '../../../query/reviewPlatform';
import userData from '../../../query/user';
import ManageBusiness from '../../manage-business/ManageBusiness';

function Dashboards() {
  const { t } = useTranslation();
  const params = useParams();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [selectPlateForm, setSelectPlateForm] = useState();
  const [reviewPlatformInfos, setReviewPlatformInfos] = useState({});
  const [dateDetails, setDateDetails] = useState([]);
  const [reviewPlaceId, setReviewPlaceId] = useState(null);
  const [selectPlateFormIDForRedirect, setSelectPlateFormIDForRedirect] = useState(
    userCurrentBusinessDetails?.reviewPlatformId
  );
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [productData, setProductData] = useState([]);
  const [isManual, setIsManual] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    reviewSentimentPeriodsData: [],
    overallRatingAndReview: {},
    metricsInfoIconData: null,
  });

  const filteredData = userSubscriptionData?.filter(
    (subscription) => subscription?.userBusinessId === userCurrentBusinessDetails?.id
  );

  const shouldShowPopup = productData?.some((item) => {
    return (
      item?.name === 'Quick Reviews' && item?.expireDate && findExpireDay(item?.expireDate) < 0
    );
  });

  useEffect(() => {
    if (
      location.pathname.includes('quick-reviews') &&
      userCurrentBusinessDetails?.isManualBusiness
    ) {
      const checkPlateFormAddedOrNot =
        userCurrentBusinessDetails?.reviewplatform?.length > 0 &&
        userCurrentBusinessDetails?.reviewplatform?.[0]?.reviewPlatformId === null;
      if (checkPlateFormAddedOrNot) {
        history.push('quick-reviews/dashboard');
        setIsManual(true);
      }
    }
  }, [userCurrentBusinessDetails, location.pathname]);

  useEffect(() => {
    const transformedData = transformUserSubscriptionData(
      userSubscriptionData,
      userCurrentBusinessDetails
    );
    if (!transformedData) return;

    const filterData = getFilteredData(transformedData);
    const updatedProducts = addExpiryToProductArray(filterData, defaultProductArr);

    setProductData((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(updatedProducts)) {
        return updatedProducts;
      }
      return prev;
    });
  }, [userSubscriptionData, userCurrentBusinessDetails?.id, userCurrentBusinessDetails]);

  const redirect = (type) => {
    try {
      if (type === 'matrices') {
        const path = params?.id ? `quick-reviews/matrices/${params.id}` : `quick-reviews/matrices/`;
        history.push(path);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    const fetchDashBoard = async () => {
      try {
        const obj = {
          query: userData.getDashboard,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const res = await handleApiRequest(obj);
        dispatch(setUserCurrentBusinessDetailsValue(res?.getDashboard?.data?.[0]));
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    if (userCurrentBusinessDetails?.id) {
      fetchDashBoard();
    }
  }, [userCurrentBusinessDetails?.id, dispatch]);

  const fetchDashboardData = async (id) => {
    const fetchPayload = (query, variables) => ({
      query,
      variables: { ...variables },
    });

    const requests = [
      handleApiRequest(fetchPayload(metricsData.getReviewSentimentPeriodsData)).catch((err) => ({
        error: err,
      })),
      handleApiRequest(fetchPayload(quickHub.getOverallRatingAndReview, { businessId: id })).catch(
        (err) => ({ error: err })
      ),
      handleApiRequest(fetchPayload(metricsData.getMetricsInfoIconData)).catch((err) => ({
        error: err,
      })),
    ];

    try {
      setLoading(true);
      const results = await Promise.all(requests);

      const [reviewSentimentPeriodsData, overallRatingAndReview, metricsInfoIconData] = results.map(
        (result) => (result.error ? null : result)
      );

      setDashboardData({
        reviewSentimentPeriodsData:
          reviewSentimentPeriodsData?.getReviewSentimentPeriodsData?.data || [],
        overallRatingAndReview: overallRatingAndReview?.getOverallRatingAndReview?.data || {},
        metricsInfoIconData: metricsInfoIconData?.getMetricsInfoIconData?.data?.info || null,
      });
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchDashboardData(userCurrentBusinessDetails?.id);
    }
  }, [userCurrentBusinessDetails?.id]);
  const { reviewSentimentPeriodsData, overallRatingAndReview, metricsInfoIconData } = dashboardData;

  useEffect(() => {
    if (!selectPlateForm) {
      const reviewPlatformInfo = userCurrentBusinessDetails?.reviewplatform?.find((item) =>
        userCurrentBusinessDetails?.isOnlineBusiness
          ? item?.name === 'website'
          : item.defaultReview === true
      );
      setSelectPlateForm(reviewPlatformInfo?.reviewPlatformId);
      setSelectPlateFormIDForRedirect(reviewPlatformInfo?.reviewPlatformId);
      setReviewPlaceId(reviewPlatformInfo?.placeId);
    }
  }, [selectPlateForm, userCurrentBusinessDetails]);

  useEffect(() => {
    if (!userCurrentBusinessDetails?.isOnlineBusiness) {
      if (!selectPlateForm) {
        const reviewPlatformValue = userCurrentBusinessDetails?.reviewplatform?.find(
          (item) => item.defaultReview === true
        );
        setReviewPlatformInfos(reviewPlatformValue);
        setSelectPlateForm(reviewPlatformValue?.reviewPlatformId);
        setReviewPlaceId(reviewPlatformValue?.placeId);
      }
    } else {
      const reviewPlatformValue = userCurrentBusinessDetails?.reviewplatform?.find(
        (item) => item.reviewPlatformId === selectPlateForm
      );
      setReviewPlatformInfos(reviewPlatformValue);
      setReviewPlaceId(reviewPlatformValue?.placeId);
    }
  }, [
    selectPlateForm,
    userCurrentBusinessDetails,
    reviewPlatformInfos,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  function handlePlatformChange(event) {
    const reviewPlatformInfo = userCurrentBusinessDetails?.reviewplatform?.find(
      (item) => item.reviewPlatformId === event?.target?.value
    );
    setSelectPlateForm(event?.target?.value);
    setReviewPlaceId(reviewPlatformInfo?.placeId);
  }

  const handlePlatformRedirect = async (event) => {
    setSelectPlateFormIDForRedirect(event);
    const payload = {
      query: reviewPlatformData.defaultReviewPlatform,
      variables: {
        businessId: userCurrentBusinessDetails?.id,
        defaultPlatformId: event,
      },
    };
    const result = await handleApiRequest(payload);

    if (
      result?.defaultReviewPlatform?.status === 400 ||
      result?.defaultReviewPlatform?.status === 403
    ) {
      enqueueSnackbar(result?.defaultReviewPlatform.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    } else if (result?.defaultReviewPlatform?.status === 200) {
      enqueueSnackbar(result?.defaultReviewPlatform.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      if (userCurrentBusinessDetails?.id) {
        const obj = {
          query: userData.getDashboard,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const res = await handleApiRequest(obj);
        dispatch(setUserCurrentBusinessDetailsValue(res?.getDashboard?.data?.[0]));
      }
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <>
      {isManual ? (
        <div className="p-24 overflow-scroll">
          <div className="bg-gray-A500 h-full grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
            <div className="col-span-2">
              <div className="mb-32 bg-white shadow rounded-md py-20 px-24">
                <div className="flex flex-col-reverse md:flex-row justify-between items-center">
                  <div className="sm:mt-0 mt-20">
                    <h3 className="text-16 lg:text-20 font-bold">
                      {t('quickHubDashboard.welcomeText')}
                    </h3>
                    <Typography className="text-16 lg:text-20 font-medium my-16 sm:my-24 max-w-512">
                      {' '}
                      {t('quickHubDashboard.mainDesc')}
                    </Typography>
                    <div className="flex gap-20 md:justify-start justify-center">
                      <a
                        role="button"
                        target="_blank"
                        href="https://itunes.apple.com/app/id6449814972"
                        rel="noreferrer"
                      >
                        <img
                          src="assets/images/quick-hub/app-store.png"
                          className="inline-block max-w-120 lg:max-w-160"
                          alt="..."
                        />
                      </a>
                      <a
                        role="button"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=reviewApp.dev"
                        rel="noreferrer"
                      >
                        <img
                          src="assets/images/quick-hub/play-store.png"
                          className="inline-block max-w-120 lg:max-w-160"
                          alt="..."
                        />
                      </a>
                    </div>
                  </div>
                  <div>
                    <img
                      src="assets/images/quick-hub/welcome.png"
                      className="max-w-256"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <ManageBusiness isManual="true" />
            </div>
          </div>
        </div>
      ) : (
        <div className="lg:p-24 p-16 h-full l-padding">
          <div className="flex flex-col gap-32">
            <CommonFreeTrial
              callFrom="quickReviews"
              productName="Quick Reviews"
              packageBundle={7}
            />
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
              <div className="col-span-2">
                <div className="flex flex-col mb-32 items-center gap-32">
                  <CommonPendingTask
                    productType="quick_reviews"
                    bgColor="quick-review"
                    buttonColor="quickReview"
                    videoImg="/assets/images/icon/playVideoIcon-review.svg"
                  />
                  {overallRatingAndReview && Object?.keys(overallRatingAndReview)?.length > 0 && (
                    <div className="p-10 sm:p-16 w-full rounded-md bg-white">
                      <div className="flex flex-col gap-14">
                        <div className="w-full flex items-center justify-between">
                          <h1 className="text-20 md:text-24 font-bold">
                            {t('quickHubDashboard.matrices')}
                          </h1>
                          <Button
                            className="text-quick-review h-0 font-semibold cursor-pointer hover:bg-transparent text-16 md:text-20"
                            size="small"
                            onClick={() => redirect('matrices')}
                          >
                            {t('dashboard.dashboardSales.viewAll')}
                          </Button>
                        </div>
                        <div className="flex md:flex-row flex-col gap-20">
                          {[
                            {
                              title: overallRatingAndReview?.review,
                              text: overallRatingAndReview?.reviewText,
                              backgroundColor: overallRatingAndReview?.reviewBackgroundColor,
                              iconSrc: '/assets/images/icon/messageicon.svg',
                              altText: 'good',
                            },
                            {
                              title: overallRatingAndReview?.rating,
                              text: overallRatingAndReview?.ratingText,
                              backgroundColor: overallRatingAndReview?.ratingBackgroundColor,
                              iconSrc: '/assets/images/icon/star.svg',
                              altText: 'good',
                            },
                            {
                              title: overallRatingAndReview?.todayReviews,
                              text: t('reviews.todayReviews'),
                              backgroundColor: overallRatingAndReview?.todaysReviewBackgroundColor,
                              iconSrc: '/assets/images/icon/messageicon.svg',
                              altText: 'good',
                            },
                          ].map((item, index) => (
                            <div
                              key={index}
                              className="w-full md:w-360 p-14 flex rounded-12 items-center justify-between h-200"
                              style={{ backgroundColor: item.backgroundColor }}
                            >
                              <div>
                                <h1 className="text-28 md:text-28 lg:text-40 sm:text-40 font-semibold">
                                  {formatNumber(item.title)}
                                </h1>
                                <p className="text-14 md:text-12 lg:text-16 sm:text-16 font-medium">
                                  {item.text}
                                </p>
                              </div>
                              <div className="bg-white rounded-full flex items-center justify-center w-60 h-60 md:w-60 md:h-60 lg:w-80 lg:h-80 sm:w-80 sm:h-80">
                                <img
                                  src={item.iconSrc}
                                  alt={item.altText}
                                  className="max-w-28 md:max-w-28 lg:max-w-48 sm:max-w-48"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-28">
                  {!userCurrentBusinessDetails?.isOnlineBusiness && (
                    <div className="bg-white rounded-md relative shadow p-20 w-full">
                      <div className="flex items-center mb-16">
                        <h3 className="text-18 font-bold items-center">
                          {t('metrics.receivedReview')}
                        </h3>
                        <Tooltip
                          title={metricsInfoIconData?.receivedReview}
                          arrow
                          classes={{
                            tooltip: 'bg-darkgreen',
                            arrow: 'text-darkgreen',
                          }}
                        >
                          <img
                            src="/assets/images/icon/tooltipicon.svg"
                            alt="good"
                            className="ml-6"
                          />
                        </Tooltip>
                      </div>
                      <ReceivedReviewsDash
                        reviewPlatformId={selectPlateForm}
                        reviewSentiment={reviewSentimentPeriodsData?.filter(
                          (item) => item?.id !== 7
                        )}
                        useArea="dashboard"
                        setDateDetails={setDateDetails}
                      />
                    </div>
                  )}
                  <div className="bg-white rounded-md relative shadow p-10 sm:p-20 w-full">
                    <div className="flex items-center mb-16">
                      <h3 className="text-18 font-bold">{t('metrics.reviewDistribution')}</h3>
                      <Tooltip
                        title={metricsInfoIconData?.reviewDistribution}
                        arrow
                        classes={{
                          tooltip: 'bg-quick-review',
                          arrow: 'text-quick-review',
                        }}
                      >
                        <img
                          src="/assets/images/icon/tooltipicon.svg"
                          alt="good"
                          className="ml-6"
                        />
                      </Tooltip>
                    </div>
                    <ReviewDistribution
                      reviewPlatformId={selectPlateForm}
                      placeId={reviewPlaceId}
                      totalReview={
                        !userCurrentBusinessDetails?.isOnlineBusiness ||
                        Number(reviewPlatformInfos?.reviewPlatformId) === 7
                          ? reviewPlatformInfos?.reviewPlatformId === 3
                            ? reviewPlatformInfos?.aggregateData?.aggregateTotalReview
                            : reviewPlatformInfos?.totalReview
                          : selectedProduct?.totalReviews
                      }
                      averageRating={
                        !userCurrentBusinessDetails?.isOnlineBusiness ||
                        Number(reviewPlatformInfos?.reviewPlatformId) === 7
                          ? reviewPlatformInfos?.reviewPlatformId === 3
                            ? reviewPlatformInfos?.aggregateData?.aggregateAverageRating
                            : reviewPlatformInfos?.totalRatingStars
                          : selectedProduct?.rating
                      }
                      color={['#FFBD23']}
                      categories={['5 Stars', '4 Stars', '3 Stars', '2 Stars', '1 Stars']}
                      useArea="metrics"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-32 w-full items-center">
                <DashboardAlerts />
                <BusinessPlateForm />
                <div className="bg-white rounded-md relative shadow md:p-20 p-20 w-full">
                  <h3 className="text-18 font-semibold border-b-2 border-dashed border-grey-400 mb-16 pb-16">
                    {t('businessDashboard.qrCode')}
                  </h3>
                  <span className="text-15 font-medium text-grey-700 mb-12 block">
                    {t('businessDashboard.redirect')}
                  </span>
                  <Select
                    className="block bg-grey-100 capitalize"
                    id="country"
                    value={parseInt(selectPlateFormIDForRedirect, 10)}
                    size="small"
                    displayEmpty
                    disabled={userCurrentBusinessDetails?.isOnlineBusiness}
                    sx={{
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                      },
                    }}
                    onChange={(e) => {
                      handlePlatformRedirect(e?.target?.value);
                    }}
                  >
                    {userCurrentBusinessDetails?.reviewplatform?.map((option) => (
                      <MenuItem
                        key={option.reviewPlatformId}
                        value={option?.reviewPlatformId}
                        className="capitalize"
                      >
                        {option.name === 'quickreviews' ? 'Quick reviews' : option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <div className="text-center border-1 border-dashed border-darkgreen rounded-md qrcode-height py-16 flex items-center mt-16">
                    <div className="block mx-auto">
                      <div className="block mx-auto">
                        <img
                          src="/assets/images/business/dashboard_qr.svg"
                          className="img-fluid mx-auto max-w-92 mb-20"
                          alt="..."
                        />
                      </div>
                      <Button
                        variant="contained"
                        className="rounded-md px-20 mx-auto"
                        color="secondary"
                        size="small"
                        type="button"
                        onClick={() => {
                          history.push('/quick-reviews/qrCode');
                        }}
                      >
                        {t('businessDashboard.open')}
                      </Button>
                    </div>
                  </div>
                </div>
                {Number(reviewPlatformInfos?.reviewPlatformId) !== 8 &&
                  !userCurrentBusinessDetails?.isOnlineBusiness && (
                    <div className="bg-white rounded-md relative shadow p-10 sm:p-16 w-full">
                      <div className="flex items-center mb-16">
                        <h3 className="text-16 font-bold">{t('metrics.repliedVsNoReplied')}</h3>
                        <Tooltip
                          title={metricsInfoIconData?.repliedVsNotReplied}
                          arrow
                          classes={{
                            tooltip: 'bg-darkgreen',
                            arrow: 'text-darkgreen',
                          }}
                        >
                          <img
                            src="/assets/images/icon/tooltipicon.svg"
                            alt="good"
                            className="ml-6"
                          />
                        </Tooltip>
                      </div>
                      <RepliedVsNotReplied
                        reviewSentiment={reviewSentimentPeriodsData}
                        reviewPlatformId={selectPlateForm}
                        placeId={reviewPlaceId}
                        totalReview={
                          !userCurrentBusinessDetails?.isOnlineBusiness ||
                          Number(reviewPlatformInfos?.reviewPlatformId) === 7
                            ? reviewPlatformInfos?.reviewPlatformId === 3
                              ? reviewPlatformInfos?.aggregateData?.aggregateTotalReview
                              : reviewPlatformInfos?.totalReview
                            : selectedProduct?.totalReviews
                        }
                        sizes="91%"
                        dataLabel="false"
                        fromDashboard="Dashboard"
                      />
                    </div>
                  )}
                {Number(reviewPlatformInfos?.reviewPlatformId) !== 8 &&
                  !userCurrentBusinessDetails?.isOnlineBusiness && (
                    <div className="bg-white rounded-md relative shadow p-10 sm:p-16 w-full">
                      <div className="flex items-center mb-16">
                        <h3 className="text-16 font-bold">{t('metrics.textVsNoText')}</h3>
                        <Tooltip
                          title={metricsInfoIconData?.textVsNoText}
                          arrow
                          classes={{
                            tooltip: 'bg-darkgreen',
                            arrow: 'text-darkgreen',
                          }}
                        >
                          <img
                            src="/assets/images/icon/tooltipicon.svg"
                            alt="good"
                            className="ml-6"
                          />
                        </Tooltip>
                      </div>
                      <TextVsNoText
                        reviewSentiment={reviewSentimentPeriodsData}
                        reviewPlatformId={selectPlateForm}
                        placeId={reviewPlaceId}
                        totalReview={
                          !userCurrentBusinessDetails?.isOnlineBusiness ||
                          Number(reviewPlatformInfos?.reviewPlatformId) === 7
                            ? reviewPlatformInfos?.reviewPlatformId === 3
                              ? reviewPlatformInfos?.aggregateData?.aggregateTotalReview
                              : reviewPlatformInfos?.totalReview
                            : selectedProduct?.totalReviews
                        }
                        sizes="91%"
                        dataLabel="false"
                        fromDashboard="Dashboard"
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
          {shouldShowPopup && (
            <DashBoardPopUp
              open={shouldShowPopup}
              filteredData={filteredData}
              productData={productData}
              from="Quick Reviews"
              productId={7}
            />
          )}
        </div>
      )}
    </>
  );
}
export default Dashboards;
