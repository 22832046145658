import React, { useCallback, useState } from 'react';
import { t } from 'i18next';
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  InputLabel,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import manageBusinessData from '../../query/manageBusiness';
import { handleApiRequest } from '../../common/common';

const AddDescription = ({
  suggestion,
  setSuggestion,
  description,
  setDescription,
  handleNext,
  passFrom,
  template,
}) => {
  const [isSuggestion, setIsSuggestion] = useState(false);
  const [isBtLoading, setIsBtLoading] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  const handleRegenerate = useCallback(
    async (descRip) => {
      setIsBtLoading(true);
      try {
        const payload = {
          query: manageBusinessData.sharePostGenerateSummarizeReviewsV1,
          variables: {
            review: descRip,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.sharePostGenerateSummarizeReviewsV1?.status === 200) {
          setSelectedSuggestion(null);
          if (description) {
            setSuggestion(result?.sharePostGenerateSummarizeReviewsV1?.data);
          }
        }
      } catch (error) {
        console.error('Error regenerating suggestion:', error);
      }
      setIsBtLoading(false);
    },
    [description, setSuggestion]
  );

  const handleRegenerate2 = () => {
    handleRegenerate(description);
  };

  const handleToggleCollapse = () => {
    setIsSuggestion((prev) => !prev);
    handleRegenerate(description);
  };

  const handleOnClickSug = (desc, index) => {
    setDescription(desc);
    setSelectedSuggestion(index);
  };

  return (
    <div>
      <div className="bg-white p-20 rounded-md quick-social mt-16">
        <div className="mb-12">
          <InputLabel htmlFor="botAnswer" className="text-20 font-semibold mb-10 text-black ">
            {t('quickSocialPost.addDescription')}
          </InputLabel>
          <TextField
            placeholder={t('quickSocialPost.placeHolder')}
            variant="outlined"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="max-w-full text-14 bg-gray-A500 "
            color="quickChat"
            multiline
            rows={4}
            inputProps={{
              className: 'focus:ring-0 focus:outline-none',
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
          />
        </div>
        <div>
          <div className="mb-24 ">
            <div className="flex mb-10 justify-between">
              <Button
                className="md:text-18 font-semibold rounded-full bg-quick-social-100 text-quick-social border-solid"
                variant="contained"
                size="small"
                color="quickSocial"
                type="button"
                onClick={handleToggleCollapse}
                disabled={description?.length === 0}
              >
                {t('quickSocialPost.writeWithAI')}
                <img src="/assets/images/icon/Ai.svg" alt="Ai" />
              </Button>
            </div>
            <Collapse
              in={isSuggestion}
              timeout="auto"
              unmountOnExit
              className="bg-grey-200 p-12 rounded-12 "
            >
              <div className="mb-12 flex items-center ">
                <h3>{t('quickSocialPost.suggestedText')}</h3>
                <IconButton onClick={() => setIsSuggestion(false)} color="inherit">
                  <CloseIcon className="w-16 h-16 text-black" />
                </IconButton>
              </div>
              {suggestion?.length > 0 ? (
                suggestion?.map((suggestions, index) => (
                  <div
                    key={index}
                    className={`mb-8 rounded-8 p-12 cursor-pointer ${
                      selectedSuggestion === index
                        ? 'bg-quick-social text-white'
                        : 'bg-white hover:bg-quick-social-100 '
                    }`}
                    onClick={() => handleOnClickSug(suggestions, index)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleOnClickSug(suggestions, index);
                      }
                    }}
                  >
                    <p>{suggestions}</p>
                  </div>
                ))
              ) : (
                <p className="bg-white mb-5 rounded-8 p-10">{t('quickSocialPost.noSuggestions')}</p>
              )}
              <Button
                className="md:text-18 rounded-md font-semibold bg-white text-quick-social gap-10 border-solid mt-16"
                variant="outlined"
                size="small"
                type="button"
                onClick={handleRegenerate2}
                disabled={isBtLoading}
              >
                {isBtLoading ? (
                  <span className="relative justify-center flex items-center gap-2">
                    <CircularProgress className="absolute" size={16} color="quickSocial" />
                    {t('quickSocialPost.regenerate')}
                  </span>
                ) : (
                  t('quickSocialPost.regenerate')
                )}
              </Button>
            </Collapse>
          </div>
        </div>
        {passFrom === 'Dashboard' && (
          <div className="flex justify-end">
            <Button
              className="md:text-18 font-semibold bg-quick-social border border-solid rounded-md hover:text-white items-center px-32 p-10"
              variant="contained"
              color="quickSocial"
              type="button"
              disabled={!template && description?.length === 0}
              onClick={() => {
                handleNext();
              }}
            >
              {t('quickSocialPost.next')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddDescription;
