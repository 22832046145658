import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import jwtDecode from 'jwt-decode';
import Button from '@mui/material/Button';
import FuseLoading from '@fuse/core/FuseLoading';
import { useParams } from 'react-router-dom';
import userService from 'src/app/services';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { TablePagination } from '@mui/material';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import './clients.css';
import { useSnackbar } from 'notistack';
import AddEditClientPopUp from './addEditClient';
import UserProfile from '../../common/userProfile';
import { getDataToServer, handleApiRequest, getEncryptedData } from '../../common/common';
import agencyData from '../../query/agency';
import history from '../../../../@history/@history';

function ClientDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const routeParams = useParams();
  const loginUserData = userService.getUserData();
  const [agencyBusinessDetail, setAgencyBusinessDetail] = useState();
  const [clientBusinessDetail, setClientBusinessDetail] = useState();
  const [totalAgencyClient, setTotalAgencyClient] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [openAddDialogue, setOpenAddDialogue] = useState(false);
  const [copy, setCopy] = useState(false);
  const [copyUserName, setCopyUserName] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState();
  const [businessId, setBusinessId] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [searchValue, setSearchValue] = useState('');
  const [openDialogueForDelete, setOpenDialogueForDelete] = useState(false);
  const [isActiveClient, setIsActiveClient] = useState(true);
  const [agentStatus, setAgentStatus] = useState('');
  const additionalInfo = (
    <>
      {t('agencyBusiness.deleteText.deleteText1')}{' '}
      <strong>{agencyBusinessDetail?.firstName}</strong>{' '}
      <strong>{agencyBusinessDetail?.lastName}</strong>?{' '}
      {t('agencyBusiness.deleteText.deleteText2')}{' '}
      <strong>{agencyBusinessDetail?.firstName}</strong>{' '}
      <strong>{agencyBusinessDetail?.lastName}</strong> {t('agencyBusiness.deleteText.deleteText3')}
    </>
  );
  useEffect(() => {
    const fetchDashboard = async () => {
      setPageLoading(true);
      const payload = {
        query: agencyData.getUserDetailsBaseOnUserId,
        variables: {
          userId: parseInt(routeParams.clientId, 10),
        },
      };
      const result = await getDataToServer(payload);
      if (result?.getUserDetailsBaseOnUserId?.status === 200) {
        setPageLoading(false);
        setAgencyBusinessDetail(result?.getUserDetailsBaseOnUserId?.data);
        setAgentStatus(result?.getUserDetailsBaseOnUserId?.data?.userStatus);
      }
    };
    fetchDashboard();
  }, [dispatch, routeParams.clientId]);

  useEffect(() => {
    const fetchDashboard = async () => {
      setPageLoading(true);
      const payload = {
        query: agencyData.getBusinessListForAgencyAndClient,
        variables: {
          clientId: parseInt(routeParams.clientId, 10),
          pageNumber: page,
          pageSize: rowsPerPage,
          isActive: isActiveClient,
        },
      };
      const result = await getDataToServer(payload);
      if (result?.getBusinessListForAgencyAndClient?.status === 200) {
        setPageLoading(false);
      }
      setClientBusinessDetail(result?.getBusinessListForAgencyAndClient?.data);
      setTotalCount(result?.getBusinessListForAgencyAndClient?.totalCount);
    };
    fetchDashboard();
  }, [dispatch, routeParams.clientId, page, rowsPerPage, isActiveClient]);
  if (pageLoading) {
    return <FuseLoading />;
  }

  const handleActiveInActiveClient = async (action) => {
    try {
      const payload = {
        query: agencyData.activeInactiveClinetByAgent,
        variables: {
          clientUserId: parseInt(routeParams.clientId, 10),
          isActive: action !== 'deactivate',
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.activeInactiveClinetByAgent?.status === 200) {
        setIsActiveClient(action !== 'deactivate');
        setOpenDialogueForDelete(false);
        history.push('/agency/clients');
        enqueueSnackbar(result?.activeInactiveClinetByAgent?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.activeInactiveClinetByAgent?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error(`Error ${action} client:`, error);
    }
  };

  const handleCloseDeleteDialog = () => setOpenDialogueForDelete(false);

  const cancelDialogue = async () => {
    setOpenAddDialogue(false);
    const payload = {
      query: agencyData.getUserDetailsBaseOnUserId,
      variables: {
        userId: parseInt(routeParams.clientId, 10),
      },
    };
    const result = await getDataToServer(payload);
    setPageLoading(false);
    if (result?.getUserDetailsBaseOnUserId?.status === 200) {
      setAgencyBusinessDetail(result?.getUserDetailsBaseOnUserId?.data);
      setProfileImageUrl(result?.getUserDetailsBaseOnUserId?.data?.profileUrl);
    }
  };

  const addBusiness = () => {
    history.push(`/agency/add-business/${parseInt(routeParams.clientId, 10)}`, { isActiveClient });
  };

  const redirectClient = async (item) => {
    const data = JSON.stringify({
      agentId: parseInt(loginUserData?.id, 10),
      userId: item?.userId,
    });
    const payload = {
      query: agencyData.getTokenUsingUserIdAndBusinessId,
      variables: { data: getEncryptedData(data) },
    };
    const result = await handleApiRequest(payload);
    if (result?.getTokenUsingUserIdAndBusinessId?.status === 200) {
      const userDetails = jwtDecode(result?.getTokenUsingUserIdAndBusinessId?.accessToken);
      sessionStorage.setItem(`selectedBusinessId_${userDetails?.id}`, item?.businessId);
      sessionStorage.setItem(
        `jwt_access_token_${userDetails?.id}`,
        result?.getTokenUsingUserIdAndBusinessId?.accessToken
      );
      window.open(`business/dashboard/${userDetails?.id}`, '_blank');
    }
  };

  const handleCopyClick = async (id) => {
    const textToCopy = document.getElementById(id).innerText;

    navigator.clipboard.writeText('').then(() => {
      if (id === 'username') {
        setCopyUserName(true);
      } else {
        setCopy(true);
      }

      return navigator.clipboard.writeText(textToCopy);
    });
    await setTimeout(() => {
      if (id === 'username') {
        setCopyUserName(false);
      } else {
        setCopy(false);
      }
    }, 1000);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  return (
    <div className="p-20 lg:p-24">
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('agencyBusiness.clients.clientDetail.title')}
          </Typography>
        </div>
      </div>

      {agencyBusinessDetail && (
        <div className="bg-white p-20 rounded-md">
          <div className="md:flex justify-between items-center">
            {agencyBusinessDetail?.profileUrl ? (
              <img
                src={agencyBusinessDetail?.profileUrl}
                className="img-fluid w-68 h-68 rounded-full"
                alt="..."
              />
            ) : (
              <span className="userProfileDetail">
                <UserProfile name={agencyBusinessDetail.firstName} />
              </span>
            )}
            <div className="w-full md:ml-32 mt-16 md:mt-0">
              <div className="flex justify-between mb-10">
                <h3 className="md:text-20 text-18 font-bold capitalize">
                  <span className="flex items-center justify-between">
                    {agencyBusinessDetail?.firstName} {agencyBusinessDetail?.lastName}
                  </span>
                </h3>
                <div className="flex gap-20">
                  <Button
                    className="text-14 bg-darkgreen-100 text-darkgreen md:px-32 font-semibold hover:text-white"
                    variant="contained"
                    size="small"
                    color="secondary"
                    aria-label="Edit"
                    disabled={agentStatus === 'inactive'}
                    onClick={() => setOpenAddDialogue(true)}
                  >
                    {t('common.editProfile')}
                  </Button>
                  {agentStatus === 'active' ? (
                    <Button
                      className="text-14 bg-red-100 text-red md:px-32 font-semibold hover:text-white"
                      variant="contained"
                      size="small"
                      color="secondary"
                      aria-label="Edit"
                      onClick={() => setOpenDialogueForDelete(true)}
                    >
                      {t('agencyBusiness.clients.clientDetail.deactivate')}
                    </Button>
                  ) : (
                    <Button
                      className="text-14 bg-darkgreen text-white md:px-32 font-semibold hover:text-white"
                      variant="contained"
                      size="small"
                      color="secondary"
                      aria-label="Edit"
                      onClick={() => handleActiveInActiveClient('reactivate')}
                    >
                      {t('agencyBusiness.clients.clientDetail.reactivate')}
                    </Button>
                  )}
                </div>
              </div>

              <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-20">
                <div>
                  {agencyBusinessDetail?.email && (
                    <span className="text-16 font-semibold flex items-center my-12">
                      <img
                        src="/assets/images/agency/email_icon.png"
                        className="img-fluid w-32 h-32 mr-10"
                        alt="..."
                      />{' '}
                      <span className="break-all">{agencyBusinessDetail?.email}</span>
                    </span>
                  )}
                  <span className="text-16 font-semibold flex items-center my-12">
                    <img
                      src="/assets/images/agency/call_icon.png"
                      className="img-fluid w-32 h-32 mr-10"
                      alt="..."
                    />{' '}
                    {agencyBusinessDetail?.phoneNumber?.countryCode}{' '}
                    {agencyBusinessDetail?.phoneNumber?.phoneNumber}
                  </span>
                </div>
                <div className="relative">
                  <span className="text-16 font-semibold flex items-center my-12 relative">
                    <img
                      src="/assets/images/agency/user_icon.png"
                      className="img-fluid w-32 h-32 mr-10"
                      alt="..."
                    />{' '}
                    <span id="username">{agencyBusinessDetail?.userName} </span>{' '}
                    <div>
                      <Button
                        className="p-0 min-w-28 min-h-28 hover:bg-darkgreen hover:text-white text-darkgreen h-28 w-28 bg-darkgreen-100 md:ml-16 ml-5"
                        type="button"
                        variant="contained"
                        onClick={() => handleCopyClick('username')}
                      >
                        <ContentCopyRoundedIcon className="text-15" />
                      </Button>
                      {copyUserName && (
                        <span className="absolute bg-black text-white text-12 px-8 rounded -top-0 duration-300 animate-bounce">
                          {t('business.selectedBusinessDetail.labels.copied')}
                        </span>
                      )}
                    </div>
                  </span>
                  <span className="text-16 font-semibold flex items-center my-12 relative">
                    <img
                      src="/assets/images/agency/key_icon.png"
                      className="img-fluid w-32 h-32 mr-10"
                      alt="..."
                    />
                    <span id="password">{agencyBusinessDetail?.userName}</span>{' '}
                    <div>
                      <Button
                        className="p-0 min-w-28 min-h-28 hover:bg-darkgreen hover:text-white text-darkgreen h-28 w-28 bg-darkgreen-100 md:ml-16 ml-5"
                        type="button"
                        variant="contained"
                        onClick={() => handleCopyClick('password')}
                      >
                        <ContentCopyRoundedIcon className=" text-15" />
                      </Button>
                      {copy && (
                        <span className="absolute bg-black text-white text-12 px-8 rounded -top-0 duration-300 animate-bounce">
                          {t('business.selectedBusinessDetail.labels.copied')}
                        </span>
                      )}
                    </div>
                  </span>
                </div>
                <div>
                  <div className="bg-grey-100 rounded-md inline-block px-20 py-28">
                    <span className="inline-block xl:mr-60 mr-10 font-medium">
                      {t('agencyBusiness.clients.clientDetail.selectedPlan')}
                    </span>
                    <span
                      className={`${
                        agencyBusinessDetail?.user_package_subscription[0]?.packageName ===
                        process.env.REACT_APP_PLAN_ENTERPRISE
                          ? 'bg-orange'
                          : agencyBusinessDetail?.user_package_subscription[0]?.packageName ===
                            process.env.REACT_APP_PLAN_PROFESSIONAL
                          ? 'bg-light-green-A700'
                          : agencyBusinessDetail?.user_package_subscription[0]?.packageName ===
                            process.env.REACT_APP_PLAN_STANDARD
                          ? 'bg-blue'
                          : 'bg-grey-800'
                      } text-white rounded-xl block h-24 pt-4 text-12 font-medium min-w-120 text-center inline-block`}
                    >
                      {agencyBusinessDetail?.user_package_subscription[0]?.packageName
                        ? `${agencyBusinessDetail?.user_package_subscription[0]?.packageName}`
                        : `${t('agencyBusiness.clients.noPlan')}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white p-20 rounded-md shadow rounded-md mt-28">
        <div className="p-0 mb-28 flex items-center justify-between border-b-1 border-dashed border-grey-500 pb-10">
          <Typography className="text-20 font-semibold text-black ">
            {t('dashboard.dashboardAgency.business')}
          </Typography>
          {clientBusinessDetail?.length > 0 && (
            <Button
              className="text-16 px-32 rounded-md"
              variant="contained"
              color="secondary"
              aria-label="Edit"
              onClick={(e) => addBusiness()}
            >
              {t('agencyBusiness.addBusiness')}
            </Button>
          )}
        </div>
        {clientBusinessDetail?.length > 0 ? (
          <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-28">
            {clientBusinessDetail &&
              clientBusinessDetail?.length > 0 &&
              clientBusinessDetail?.map((item, index) => (
                <div
                  className="bg-grey-100 p-14 rounded-md cursor-pointer text-center relative"
                  key={index}
                  aria-hidden="true"
                  onClick={() => redirectClient(item)}
                >
                  <img
                    src={
                      item?.profileURL
                        ? item?.profileURL
                        : '/assets/images/agency/businessprofile.png'
                    }
                    className="img-fluid mx-auto wax-w-200 mb-16 max-h-80 rounded-md"
                    alt="..."
                  />
                  <h3 className="text-16 font-bold mb-8">
                    <span className="block">{item?.name}</span>
                  </h3>
                  <Typography className="text-14 font-semibold text-grey-600">
                    {item?.address || item?.address2}
                  </Typography>
                </div>
              ))}
          </div>
        ) : (
          <div className="text-center py-12 ">
            <Typography
              color="textSecondary"
              variant="h5"
              className="text-20 mb-12 block font-semibold text-black"
            >
              {t('agencyBusiness.noBusiness')}
            </Typography>
            <Button
              className="text-16 px-32 rounded-md"
              variant="contained"
              color="secondary"
              aria-label="Edit"
              disabled={agentStatus === 'inactive'}
              onClick={(e) => addBusiness()}
            >
              {t('agencyBusiness.addBusiness')}
            </Button>
          </div>
        )}
      </div>
      {clientBusinessDetail?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalCount}
          rowsPerPage={12}
          rowsPerPageOptions={[]}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
        />
      )}

      {openAddDialogue && (
        <AddEditClientPopUp
          openValue={openAddDialogue}
          cancelCall={cancelDialogue}
          type="edit"
          profileImageUrl={profileImageUrl}
          setProfileImageUrl={setProfileImageUrl}
          popUpMessage={t('agencyBusiness.clients.clientDetail.editClient')}
          popUpButtonName={t('common.save')}
          clientId={parseInt(routeParams?.clientId, 10)}
          initialValues={{
            firstName: agencyBusinessDetail?.firstName,
            lastName: agencyBusinessDetail?.lastName,
            phone_number: `${agencyBusinessDetail?.phoneNumber?.countryCode} ${agencyBusinessDetail?.phoneNumber?.phoneNumber}`,
            mail: agencyBusinessDetail?.email ? agencyBusinessDetail?.email : '',
            // Add other fields here if needed
          }}
        />
      )}
      <ConformationDialogBox
        open={openDialogueForDelete}
        onClose={handleCloseDeleteDialog}
        onDelete={() => handleActiveInActiveClient('deactivate')}
        confirmationText="Are You Sure?"
        additionalInfo={additionalInfo}
        deleteImage="/assets/images/icon/DeleteClient.svg"
        backgroundColor="red"
        textColor="red"
        buttonText={t('common.delete')}
      />
    </div>
  );
}
export default ClientDetails;
