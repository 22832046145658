import { useEffect, useState, useRef, useCallback } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from '@history';
import { useLocation } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  Icon,
  MenuItem,
  Menu,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReactApexChart from 'react-apexcharts';
import quickAdsQuery from 'src/app/main/query/quickAds';
import { getAllUserBusinessSubscription, selectDashboardData } from 'app/store/userSlice';
import userService from 'src/app/services';
import { formatDateWithTimeZone, handleApiRequest } from '../../../common/common';
import NoData from '../../matrices/noDataScreen';
import GenderMetrics from '../dashboard/GenderMetrics';

function AdsCampaignDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const loginUserData = userService.getUserData();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { ADSdata } = location.state || {};
  const [lead, setLead] = useState([]);
  const [overAllMetrics, setOverAllMetrics] = useState([]);
  const countryId = Number(loginUserData?.countryId);
  const [showList, setShowList] = useState(false);
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    activeIndex: null,
  });

  const fetchAdsCampaign = useCallback(async () => {
    try {
      setLoading(true);
      const payload = {
        query: quickAdsQuery.getQuickAdsLeads,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
          quickHubAdsAccountId: Number(userCurrentBusinessDetails?.quickHubAdsAccountId),
          pageNumber: 1,
          pageSize: 10,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickAdsLeads?.status === 200) {
        setLead(result?.getQuickAdsLeads?.data);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, [userCurrentBusinessDetails?.id, userCurrentBusinessDetails?.quickHubAdsAccountId]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id && userCurrentBusinessDetails?.quickHubAdsAccountId) {
      fetchAdsCampaign();
    }
  }, [
    fetchAdsCampaign,
    userCurrentBusinessDetails?.id,
    userCurrentBusinessDetails?.quickHubAdsAccountId,
  ]);

  useEffect(() => {
    const getAdsOverallMetrics = async () => {
      try {
        setLoading(true);
        const payload = {
          query: quickAdsQuery.getAdsOverallMetrics,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getAdsOverallMetrics?.status === 200) {
          setOverAllMetrics(result?.getAdsOverallMetrics?.data);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      getAdsOverallMetrics();
    }
  }, [userCurrentBusinessDetails?.id]);

  function formatNumber(count) {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
    }
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1).replace(/\.0$/, '')}k`;
    }
    return count;
  }

  function getChartSimple(data, index, color, types) {
    const chart = {
      series: data,
      options: {
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: true,
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#e7e7e7',
              strokeWidth: '100%',
              margin: 5,
              dropShadow: {
                enabled: false,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2,
              },
            },

            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: '35px',
                fontWeight: 'bold',
                color: '#000',
                offsetY: -10,
                formatter(val) {
                  return `${val}%`;
                },
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          colors: color,
        },
      },
    };
    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full">
        {chart?.plotOptions?.total?.formatter}
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="350px"
          width="100%"
        />
      </div>
    );
  }

  const handleOpenMenu = (event, index) => {
    setShowList(true);
    setMenuState({
      anchorEl: event.currentTarget,
      activeIndex: index,
    });
  };

  const handleCloseMenu = () => {
    setShowList(false);
    setMenuState({
      anchorEl: null,
      activeIndex: null,
    });
  };

  const handleDelete = async (data, from) => {
    try {
      const payload = {
        query: quickAdsQuery.pauseOrDeleteAdsCampaign,
        variables: {
          adsCampaignId: Number(data?.id),
          operation: from,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.pauseOrDeleteAdsCampaign?.status === 200) {
        history.push('/quick-ads/ads-campaign');
        enqueueSnackbar(result?.pauseOrDeleteAdsCampaign?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.pauseOrDeleteAdsCampaign?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const handleEdit = (data) => {
    history.push('/create-ads', {
      adsData: data,
    });
  };

  const publishAds = useCallback(
    async (id) => {
      try {
        if (id) {
          const payload = {
            query: quickAdsQuery.publishAds,
            variables: {
              adsCampaignId: id,
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.publishAds?.status === 200) {
            fetchAdsCampaign();
            handleCloseMenu();
            enqueueSnackbar(result?.publishAds?.message, {
              variant: 'success',
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(result?.publishAds?.message, {
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
        }
      } catch {
        console.error('An error occurred');
      }
    },
    [enqueueSnackbar, fetchAdsCampaign]
  );

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-24 h-full">
      <div>
        <div className="grid md:grid grid-cols-5">
          <div className="sm:col-span-2">
            <div className="sm:flex w-full items-center justify-between mb-20">
              <Typography
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="text-20 md:text-28 font-semibold mb-16 md:mb-0"
              >
                {t(`quickCampaign.campaign.campaignDetails`)}
              </Typography>
              <div>
                <div
                  role="button"
                  tabIndex={0}
                  aria-label="Open menu"
                  onClick={(event) => handleOpenMenu(event, ADSdata?.id, ADSdata)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      (event) => handleOpenMenu(event, ADSdata?.id, ADSdata);
                    }
                  }}
                  className="cursor-pointer"
                >
                  <MoreVertIcon />
                </div>
                {showList && menuState?.activeIndex === ADSdata?.id && (
                  <div className="">
                    <Menu
                      anchorEl={menuState?.anchorEl}
                      open={Boolean(menuState?.anchorEl)}
                      onClose={handleCloseMenu}
                      classes={{ paper: 'min-w-112 shadow-none' }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      sx={{
                        '& .MuiPaper-root': {
                          boxShadow: '1px 1px 3px gray',
                        },
                      }}
                    >
                      {ADSdata?.status !== 'COMPLETED' && (
                        <MenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            handleEdit(ADSdata);
                          }}
                        >
                          <Icon className="text-20 mr-8 ">{t('quickSocial.edit1')}</Icon>
                          <span className="text-16 font-500">{t('common.edit')}</span>
                        </MenuItem>
                      )}
                      {ADSdata?.status === 'ACTIVE' && (
                        <MenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDelete(ADSdata, 'PAUSE');
                          }}
                        >
                          <PauseIcon className="text-20 mr-8" />
                          <p className="text-16 font-500">{t('quickAds.pause')}</p>
                        </MenuItem>
                      )}
                      {ADSdata?.status === 'PAUSED' && (
                        <MenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            publishAds(ADSdata?.id);
                          }}
                        >
                          <PlayArrowIcon className="text-20 mr-8" />
                          <p className="text-16 font-500">{t('quickAds.resume')}</p>
                        </MenuItem>
                      )}
                      {ADSdata && (
                        <MenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDelete(ADSdata, 'DELETE');
                          }}
                        >
                          <Icon className="text-20 mr-8 ">{t('quickSocial.delete1')}</Icon>
                          <span className="text-16 font-500">{t('common.delete')}</span>
                        </MenuItem>
                      )}
                    </Menu>{' '}
                  </div>
                )}
              </div>
            </div>
            <div className="flex mb-28">
              <img className="max-w-256 mr-20 mb-20" src={ADSdata?.mediaUrl} alt="..." />
              <div className="block w-full">
                <div>
                  <p className="block text-18 font-medium mb-12">
                    {ADSdata?.name
                      ?.split(' ')
                      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      ?.join(' ')}
                  </p>
                  <div>
                    <p className="text-18 font-medium block text-grey-700 leading-7">
                      {t(`quickAds.campaignObjective`)} :
                    </p>
                    <p className="text-18 font-medium block text-black leading-7 mb-20">
                      {ADSdata?.campaignOverview?.objective}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-20">
                  <p
                    className={`${
                      ADSdata?.status === 'SCHEDULED'
                        ? 'bg-quick-social-100 text-quick-social border-1 border-quick-social'
                        : ADSdata?.status === 'PAUSED'
                        ? 'bg-quick-campaign-100 text-quick-campaign border-1 border-quick-campaign'
                        : ADSdata?.status === 'ACTIVE'
                        ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                        : ADSdata?.status === 'COMPLETED'
                        ? 'bg-[#f1cdea] text-[#C837AB] border-1 border-[#C837AB]'
                        : 'bg-yellow-100 text-quick-chat border-1 border-quick-chat'
                    } inline-block rounded-md font-medium text-16 text-center py-3 px-10`}
                  >
                    {ADSdata?.status}
                  </p>
                  <div>
                    {ADSdata?.shareOnFacebook && (
                      <img
                        src="assets/images/quick-hub/quick-ads/facebook.png"
                        alt="Facebook"
                        className="w-28 h-28 inline-block ml-4"
                      />
                    )}
                    {ADSdata?.shareOnInstagram && (
                      <img
                        src="assets/images/quick-hub/quick-ads/instagram.png"
                        alt="instagram"
                        className="w-28 h-28 inline-block ml-4"
                      />
                    )}
                  </div>
                </div>
                <div className="text-18 font-medium">
                  <span className="bg-quick-social-100 w-32 h-32 inline-block pt-3 align-middle rounded-full text-center mr-10">
                    <Icon className="mx-auto text-grey-800 ">{t(`quickAds.dateRange`)} </Icon>
                  </span>
                  {formatDateWithTimeZone(ADSdata?.scheduleDate, 'MMM DD, YYYY')} to{' '}
                  {formatDateWithTimeZone(ADSdata?.endDate, 'MMM DD, YYYY')}
                </div>
              </div>
            </div>
            <div>
              <div className="text-18 font-semibold  mb-20">
                <div className="inline-block">
                  <span className=" inline-block align-middle text-grey-700">
                    {t(`quickAds.ageGroup`)}
                  </span>
                  <span className="inline-block align-middle mx-4">:</span>
                  <span className="inline-block align-middle text-black">
                    {ADSdata?.campaignOverview?.minAge} - {ADSdata?.campaignOverview?.maxAge}{' '}
                    {t(`quickAds.years`)}
                  </span>
                </div>
                <div className="inline-block lg:ml-48 ml-20">
                  <span className=" inline-block align-middle text-grey-700">
                    {t(`quickAds.gender`)}
                  </span>
                  <span className="inline-block align-middle mx-4">:</span>
                  <span className="inline-block align-middle text-black">
                    {ADSdata?.campaignOverview?.genderSelected}
                  </span>
                </div>
              </div>
            </div>
            <div className="text-18 font-semibold  mb-20">
              <div className="block">
                <span className=" inline-block align-middle text-grey-700">
                  {t(`business.searchBusiness.labels.location`)}
                </span>
                <span className="inline-block align-middle mx-4">:</span>
                <span className="inline-block align-middle text-black">
                  {ADSdata?.campaignOverview?.geoLocation?.regions?.map((item, index) => (
                    <div key={index}>
                      {' '}
                      {index > 0 && <span>/</span>}
                      {item?.name}
                    </div>
                  ))}
                  {ADSdata?.campaignOverview?.geoLocation?.cities?.map((item, index) => (
                    <div key={index}>
                      {' '}
                      {index > 0 && <span>/</span>}
                      {item?.name}
                    </div>
                  ))}
                  {ADSdata?.campaignOverview?.geoLocation?.countries?.map((item, index) => (
                    <div key={index}>
                      {' '}
                      {index > 0 && <span>/</span>}
                      {item}
                    </div>
                  ))}
                </span>
              </div>
            </div>
            <div className="text-18 font-semibold  mb-20">
              <div className="block">
                <span className=" inline-block align-middle text-grey-700">
                  {t(`quickAds.interest`)}
                </span>
                <span className="inline-block align-middle mx-4">:</span>
                <span className="inline-block align-middle text-black">
                  {ADSdata?.campaignOverview?.interest?.map((item, index) => (
                    <div key={index}>{item?.name ? item.name : '-'}</div>
                  ))}{' '}
                </span>
              </div>
            </div>

            <TableContainer
              component={Paper}
              className="rounded-md border-1 border-solid border-grey-A500 shadow"
            >
              <div className="flex items-center justify-between mt-10 mb-5 px-16">
                <h3 className="text-18 font-medium flex items-center">
                  {t(`quickCampaign.campaign.title`)} {t(`quickAds.leads`)}
                  <span className="ml-10 px-6 inline-block border-1 border-grey-400 rounded-full min-w-48 py-3 text-14 font-medium text-center">
                    {lead?.length}
                  </span>
                </h3>
                <Button
                  className="text-16 font-medium px-0 hover:bg-transparent hover:text-black"
                  variant="text"
                  color="quickSocial"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/quick-ads/ads-leads');
                  }}
                >
                  {t(`businessDashboard.seeAll`)}{' '}
                  <Icon className="text-14 ml-3">arrow_forward_ios</Icon>
                </Button>
              </div>

              <Table>
                <TableHead className="border-b-2 border-grey-400">
                  <TableRow>
                    <TableCell className="font-semibold text-18">{t(`common.srNo`)}</TableCell>
                    <TableCell className="font-semibold text-18">
                      {t(`quickAds.lead`)} {t(`common.name`)}
                    </TableCell>
                    <TableCell className="font-semibold text-18">
                      {t(`business.selectedBusinessDetail.labels.email`)}
                    </TableCell>
                    <TableCell className="font-semibold text-18">{t(`common.phone`)}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lead?.length > 0 &&
                    lead?.map((data, index) => (
                      <TableRow
                        key={index}
                        className="cursor-pointer hover:bg-quick-social-100 hover:bg-opacity-30 border-b-2"
                      >
                        <TableCell className="font-medium text-16 p-12 pr-0">{index + 1}</TableCell>
                        <TableCell className="font-medium text-16 p-12">
                          {data?.formData?.find((item) => item?.name === 'full_name')?.value}
                        </TableCell>
                        <TableCell className="font-medium text-16 p-12">
                          {data?.formData?.find((item) => item?.name === 'email')?.value}
                        </TableCell>

                        <TableCell className="font-medium text-16 p-12">
                          {data?.formData?.find((item) => item?.name === 'phone_number')?.value}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="sm:col-span-3 border-l-2 border-grey-400 pl-28 ml-28">
            <div className="sm:flex w-full items-center justify-between mb-20">
              <Typography className="text-20 md:text-24 font-semibold mb-16 md:mb-0">
                {t(`quickCampaign.campaign.title`)} {t(`quickHubDashboard.matrices`)}
              </Typography>
            </div>
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 mb-24 bg-white rounded-md p-24 border-1 border-solid border-grey-A500 shadow">
              {overAllMetrics?.brandHealthData?.metrics &&
                overAllMetrics?.brandHealthData?.metrics?.length > 0 &&
                overAllMetrics?.brandHealthData?.metrics?.map((metricsValue, i) => (
                  <div key={i}>
                    <div className="flex items-center">
                      <img
                        src={metricsValue?.icon}
                        width={50}
                        height={50}
                        className="h-auto max-w-full mr-10"
                        alt="..."
                      />
                      <div>
                        <span className="block font-semibold text-28 mb-3">
                          {metricsValue?.value}
                        </span>
                        <span className="block font-medium text-18 text-grey-600">
                          {metricsValue?.viewName}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 md:gap-20 gap-10 mb-24">
              <div className="bg-white rounded-md p-24 border-1 border-solid border-grey-A500 shadow justify-center flex items-center">
                <div className="w-full">
                  <h3 className="text-18 font-semibold mb-16 mt-0">{t(`quickAds.gender`)}</h3>
                  {overAllMetrics?.brandHealthData?.gender && (
                    <GenderMetrics data={overAllMetrics?.brandHealthData?.gender} />
                  )}
                </div>
              </div>
              <div className="bg-white rounded-md p-24 border-1 border-solid border-grey-A500 shadow">
                <Typography className="text-18   font-medium mb-16 md:mb-0">
                  {t(`quickAds.Budget`)}
                </Typography>
                <div>
                  {overAllMetrics?.brandHealthData?.budget &&
                  overAllMetrics?.brandHealthData?.budget?.length > 0 ? (
                    <>
                      {getChartSimple(
                        overAllMetrics?.brandHealthData?.budget
                          ?.filter((channel) => channel?.key === 'percent')
                          ?.map((channel, index) => channel?.value),
                        4,
                        ['#67df9d'],
                        'radialBar'
                      )}
                      <Typography className="font-bold text-16 max-w-256 text-center mx-auto mb-0 mt-40">
                        {overAllMetrics?.brandHealthData?.budget
                          ?.filter(
                            (channel) => channel?.key === 'spend' || channel?.key === 'remaining'
                          )
                          ?.map((channel, index) => (
                            <div
                              key={index}
                              className="flex justify-between last:border-none border-b-2 border-dashed border-gray-A500"
                            >
                              <div className="flex justify-center items-center gap-16">
                                <div
                                  className={` rounded-full h-16 w-16 ${
                                    channel?.key === 'spend' ? '!bg-[#67df9d]' : '!bg-gray'
                                  }`}
                                >
                                  {' '}
                                </div>
                                <p className="text-16 font-medium text-gray">
                                  {channel?.viewName}{' '}
                                </p>
                              </div>
                              <p className="text-16 font-medium">
                                {countryId === 2 ? '₹' : '$'} {channel?.value}
                              </p>
                            </div>
                          ))}
                      </Typography>
                    </>
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            </div>
            <div className="bg-white rounded-md p-12 border-1 border-solid border-grey-A500 shadow">
              <h3 className="text-18 font-medium mb-16 mt-0">{t(`quickAds.topChannel`)}</h3>
              {overAllMetrics?.brandHealthData?.topPlacement &&
                overAllMetrics?.brandHealthData?.topPlacement?.length > 0 &&
                overAllMetrics?.brandHealthData?.topPlacement
                  ?.filter(
                    (channel) =>
                      channel?.key === 'Instagram Video' || channel?.key === 'Facebook Video'
                  )
                  ?.map((channel, index) => (
                    <div key={index} className="flex items-center mt-32">
                      <div
                        className="flex-grow h-32 rounded-md bg-quick-social"
                        style={{
                          width: `${(channel.values / 2) * 100}%`,
                          maxWidth: 'calc(100% - 80px)',
                        }}
                      />
                      <div className="flex items-center ml-4">
                        <div className="w-44 h-44 border-2 -ml-20 bg-white border-white rounded-full">
                          <div className="w-40 h-40 rounded-full flex items-center justify-center border-2 border-quick-social">
                            <img
                              src={
                                channel?.key === 'Facebook Video'
                                  ? 'assets/images/quick-hub/quick-ads/facebook.png'
                                  : 'assets/images/quick-hub/quick-ads/instagram.png'
                              }
                              alt={channel?.name}
                              className="w-20 h-20"
                            />
                          </div>
                        </div>
                        <span className="ml-2 font-semibold">
                          {formatNumber(Number(channel?.value))}
                        </span>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdsCampaignDetails;
