import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import Collapse from '@mui/material/Collapse';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getAllUserBusinessSubscription,
  getCountryData,
  getUserBusinesses,
  setUserCurrentBusinessDetailsValue,
} from 'app/store/userSlice';
import userData from '../../main/query/user';
import queryData from '../../main/query/common';
import { getParamsId, handleApiRequest } from '../../main/common/common';
import userService from '../../services';
import CheckPlanForAddNewBusiness from '../../main/business/manage-business/checkPlanForAddNewBusiness/CheckPlanForAddNewBusiness';

function UserBusiness() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [userBusiness, setUserBusiness] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [businessList, setBusinessList] = useState([]);
  const [businessListCount, setBusinessListCount] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [stateList, setStateList] = useState();
  const [stateId, setStateId] = useState(0);
  const [countryId, setCountryId] = useState(0);
  const [countryName, setCountryName] = useState();
  const [type, setType] = useState('');
  const [stateName, setStateName] = useState();
  const [applyFilter, setApplyFilter] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [userCurrentBusinessDetails, setUserCurrentBusinessDetails] = useState();
  const loginUserData = userService.getUserData();
  const [openDialogue, setOpenDialogue] = useState(false);
  const countriesData = useSelector(getCountryData);
  const [openSelectProductDialog, setOpenSelectProductDialog] = useState(false);
  const [planAvailableDialog, setPlanAvailableDialog] = useState(false);
  const [userAvailablePlan, setUserAvailablePlan] = useState([]);
  const [businesses, setBusinesses] = useState();
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const location = useLocation();

  const userBusinessClick = (event) => {
    setUserBusiness(event.currentTarget);
    setShowOverlay(true);
  };

  useEffect(() => {
    if (localStorage.getItem('businesses')) {
      setBusinesses(JSON.parse(localStorage.getItem('businesses')));
    }
  }, []);

  useEffect(() => {
    const fetchBusinessList = async () => {
      try {
        const findStateValue = stateList?.find(
          (item) => parseInt(item.id, 10) === parseInt(stateId, 10)
        );
        const payload = {
          query: userData.getAllBusinessOnUserId,
          variables: {
            pageSize: rowsPerPage,
            pageNumber: page,
            businessName: searchValue,
            stateId,
            countryId,
            ...(stateId &&
              stateId > 0 && {
                state: findStateValue?.name,
              }),
          },
        };
        if (type === 'filter') {
          setFilterLoader(true);
          if (countryId > 0) {
            payload.variables.countryId = parseInt(countryId, 10);
          }
        }
        const res = await handleApiRequest(payload);
        if (res?.getAllBusinessOnUserId?.status === 200) {
          setBusinessList(res.getAllBusinessOnUserId.data);
          setBusinessListCount(res.getAllBusinessOnUserId.totalCount);
          setType('');
          if (type === 'filter') {
            setFilterLoader(false);
            setOpenFilterPanel(false);
            setApplyFilter(true);
          }
        }
        if (
          loginUserData?.roles?.type === 'agent' &&
          res?.getAllBusinessOnUserId?.data?.length === 0
        ) {
          sessionStorage.clear();
        }
      } catch (error) {
        console.error('Failed to fetch business list:', error);
      } finally {
        if (type === 'filter') {
          setFilterLoader(false);
        }
      }
    };
    fetchBusinessList();
  }, [
    countryId,
    loginUserData?.roles?.type,
    searchValue,
    stateId,
    stateList,
    page,
    rowsPerPage,
    type,
  ]);

  useEffect(() => {
    const id = getParamsId();
    const businessId = id ? sessionStorage.getItem(`selectedBusinessId_${id}`) : '';
    const findBusiness = businessList?.find(
      (business) => parseInt(business.id, 10) === parseInt(businessId, 10)
    );
    async function fetchData() {
      if (!findBusiness?.id && loginUserData?.roles?.type === 'agent') {
        sessionStorage.setItem(`selectedBusinessId_${id}`, businessList[0]?.id);
      }
      const payload = {
        query: userData.getDashboard,
        variables: {
          businessId: parseInt(findBusiness?.id, 10),
        },
      };

      const res = await handleApiRequest(payload);
      setUserCurrentBusinessDetails(res?.getDashboard?.data?.[0]);
      dispatch(setUserCurrentBusinessDetailsValue(res?.getDashboard?.data?.[0]));
    }
    if (
      sessionStorage.getItem(`selectedBusinessId_${getParamsId()}`) ||
      localStorage.getItem('selectedBusinessId') ||
      businessList?.[0]?.id
    ) {
      if (businessList?.length > 0 && businessId) {
        fetchData();
      }
    }
  }, [businessList, dispatch, loginUserData?.roles?.type]);

  useEffect(() => {
    if (businesses?.length > 0) {
      const findBusiness =
        businesses?.find(
          (item) => Number(item?.id) === Number(localStorage.getItem('selectedBusinessId'))
        ) || businesses?.[0];
      if (findBusiness) {
        setUserCurrentBusinessDetails(findBusiness);
        dispatch(setUserCurrentBusinessDetailsValue(findBusiness));
      }
    }
  }, [businesses, dispatch]);

  useEffect(() => {
    const countryValue = countriesData?.find((country) => country?.id === countryId);
    setCountryName(countryValue?.name);
  }, [countryId, countriesData]);

  useEffect(() => {
    const stateValue = stateList?.find((state) => state?.id === stateId);
    setStateName(stateValue?.name);
  }, [stateId, stateList]);

  useEffect(() => {
    async function fetchData() {
      if (countryId) {
        const payload = {
          query: queryData.getStates,
          variables: { countryId },
        };
        const result = await handleApiRequest(payload);
        if (result?.getStates?.status === 200) {
          setStateList(result?.getStates?.data);
        }
      }
    }
    fetchData();
  }, [countryId, dispatch]);

  const userBusinessClose = () => {
    setUserBusiness(null);
    setShowOverlay(false);
  };

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
  };

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    setSearchValue(searchText);
  }

  const handleFilter = async (state, country) => {
    setType('filter');
    setStateId(state);
    setCountryId(country);
  };

  function handleSelectCountry(event) {
    setCountryId(event.target.value);
    setStateId(0);
    setPage(1);
  }

  function handleSelectState(event) {
    setStateId(event.target.value);
  }

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
  };

  const handleOpenFilterPanel = () => {
    setOpenFilterPanel(!openFilterPanel);
  };

  const handleSelectBusiness = (id) => {
    localStorage.setItem('selectedBusinessId', id);
    userBusinessClose();
    window.location.reload();
  };

  const handleAddBusiness = () => {
    const findAvailablePlan = userSubscriptionData?.filter((item) => !item?.userBusinessId);
    if (findAvailablePlan?.length > 0) {
      setUserAvailablePlan(findAvailablePlan);
      setPlanAvailableDialog(true);
    } else {
      setOpenSelectProductDialog(true);
    }
    setShowOverlay(false);
    setUserBusiness(null);
  };

  return (
    <>
      {businessList &&
        (businessList?.length === 0 || businessList?.length > 0) &&
        (!location?.pathname?.includes('selectProduct') || location.state?.isBusinessAdded) && (
          <Button
            className="min-h-40 min-w-40 hover:bg-indigo-50 bg-indigo-50 hover:bg-opacity-60 bg-opacity-50 rounded-8 py-24 p-8"
            onClick={userBusinessClick}
            color="inherit"
          >
            <div className="md:flex flex-col mx-4 items-end">
              <Typography
                component="span"
                className="font-bold flex sm:text-16 text-14 text-black capitalize items-center"
              >
                <img
                  src={
                    userCurrentBusinessDetails?.profileURL ||
                    '/assets/images/business/businessprofile.png'
                  }
                  className="sm:w-40 sm:h-40 w-28 h-28 rounded-md "
                  alt="..."
                />
                <span className="sm:mx-16 mx-5 sm:max-w-320 max-w-60 text-ellipsis overflow-hidden	whitespace-nowrap	">
                  {userCurrentBusinessDetails?.name}
                </span>
                <Icon className="sm:text-16 text-12">expand_more</Icon>
              </Typography>
            </div>
          </Button>
        )}

      {showOverlay && (
        <div
          className="overlay fixed top-0 left-0 w-full h-full bg-black opacity-80 z-50"
          aria-hidden="true"
          onClick={userBusinessClose}
        />
      )}

      <Popover
        open={Boolean(userBusiness)}
        anchorEl={userBusiness}
        onClose={userBusinessClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: 'sm:p-32 p-16 sm:mt-16 mt-16 lg:w-1/2',
        }}
        PaperProps={{
          sx: {
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-20px',
              left: 'calc(50% - 10px)',
              width: '24px',
              height: '24px',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderBottom: '10px solid #fff',
            },
          },
        }}
      >
        <div>
          <div className="bg-indigo-50 bg-opacity-40 sm:p-20 p-10 rounded-md">
            <Icon
              className="text-24 text-black absolute right-8 top-10 cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                userBusinessClose(true);
              }}
            >
              close
            </Icon>
            <div className="flex items-center">
              <img
                src={
                  userCurrentBusinessDetails?.profileURL ||
                  '/assets/images/business/businessprofile.png'
                }
                className="max-w-96 min-w-96 rounded-md"
                alt="Business profile"
              />
              <div className="sm:mx-14 mx-10">
                <h5 className="sm:text-20 text-14 break-all font-semibold mb-6">
                  {userCurrentBusinessDetails?.name}
                </h5>
                <Typography className="font-medium sm:text-16 text-12">
                  {userCurrentBusinessDetails?.address || userCurrentBusinessDetails?.address2}
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between sm:my-32 my-16">
              <Paper className="flex items-center w-full border border-solid border-darkgreen-200 px-8 rounded-8 shadow-none">
                <Input
                  placeholder={t('agencyBusiness.search')}
                  className="flex flex-1 mx-8 text-black text-16"
                  disableUnderline
                  name="searchText"
                  fullWidth
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  onChange={handleSearchEvent}
                  value={searchValue}
                />
                {searchValue ? (
                  <Icon
                    color="action"
                    className="cursor-pointer"
                    onClick={() => {
                      clearSearch();
                    }}
                  >
                    close
                  </Icon>
                ) : (
                  <Icon color="secondary">search</Icon>
                )}
              </Paper>
              <Button
                onClick={() => handleOpenFilterPanel()}
                type="button"
                className="bg-darkgreen min-w-40 w-40 h-40 text-white hover:bg-darkgreen rounded-full flex items-center font-normal ml-16"
              >
                <Icon className="mx-auto">filter_alt</Icon>
              </Button>
            </div>
          </div>
          <Collapse in={openFilterPanel} timeout="auto" unmountOnExit>
            <div className="bg-indigo-50 bg-opacity-40 rounded-md p-16 sm:mb-32 mb-16 relative">
              <Icon
                className="text-20 text-black absolute right-10 top-10 cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                  setOpenFilterPanel(!openFilterPanel);
                }}
              >
                close
              </Icon>
              <div className="sm:flex items-end justify-between">
                <div className="w-2/4 sm:flex items-end">
                  <div className="w-1/2 inline-block sm:mb-0 mb-10">
                    <InputLabel id="country" className="text-16 font-medium mb-4 text-grey-900">
                      {t('common.country')}
                    </InputLabel>
                    <Select
                      className="w-full bg-white"
                      id="country"
                      value={countryId}
                      size="small"
                      displayEmpty
                      sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                      onChange={(e) => {
                        handleSelectCountry(e);
                      }}
                    >
                      <MenuItem value={0} disabled>
                        {t('signUpPage.formField.country.selectCountry')}
                      </MenuItem>
                      {countriesData?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {/* <div className="w-1/2 inline-block sm:mb-0 mb-10 md:mx-16 mx-5">
                    <InputLabel id="state" className="text-16 font-medium mb-4 text-grey-900">
                      {t('common.state')}
                    </InputLabel>

                    <Select
                      className="w-full bg-white"
                      id="state"
                      value={stateId}
                      sx={{ '& .MuiSelect-select': { display: 'flex', alignItems: 'center' } }}
                      size="small"
                      displayEmpty
                      onChange={(e) => {
                        handleSelectState(e);
                      }}
                    >
                      <MenuItem value={0} disabled>
                        {t('signUpPage.formField.state.selectState')}
                      </MenuItem>
                      {stateList?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div> */}
                </div>
                <div className="block sm:ml-20">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    disabled={filterLoader}
                    onClick={() => handleFilter(stateId, countryId)}
                    className="rounded-md font-medium text-14 min-w-112"
                  >
                    {t('userBusinessList.applyFilter')}
                    {filterLoader && (
                      <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Collapse>

          {applyFilter && (
            <div className="sm:mb-32 mb-16">
              {countryName && (
                <span className="bg-grey-100 p-10 inline-block min-w-208 rounded-md mr-16">
                  <span className="flex items-center justify-between text-16 font-medium">
                    {countryName}
                    <Icon
                      className="text-16 text-darkgreen cursor-pointer"
                      aria-hidden="true"
                      onClick={() => {
                        setCountryName('');
                        setCountryId(0);
                        handleFilter(stateId, 0);
                      }}
                    >
                      close
                    </Icon>
                  </span>
                </span>
              )}

              {stateName && (
                <span className="bg-grey-100 p-10 inline-block min-w-208 rounded-md mr-16">
                  <span className="flex items-center justify-between text-16 font-medium">
                    {stateName}
                    <Icon
                      className="text-16 text-darkgreen cursor-pointer"
                      aria-hidden="true"
                      onClick={async () => {
                        await setStateId(0);
                        setStateName('');
                        await handleFilter(0, countryId);
                      }}
                    >
                      close
                    </Icon>
                  </span>
                </span>
              )}
            </div>
          )}

          {businessList?.length > 0 ? (
            businessList?.map((item, index) => (
              <div
                className={`sm:flex items-center justify-between ${
                  index !== businessList.length - 1 &&
                  'border-b-1 border-solid border-grey-300 pb-12 mb-12'
                }`}
                key={index}
              >
                <div className="flex items-center">
                  <img
                    src={item?.profileURL || '/assets/images/business/businessprofile.png'}
                    className="w-112 h-68 rounded-md object-cover"
                    alt="..."
                  />
                  <div className="mx-14">
                    <h5 className="text-16 max-w-512 font-semibold break-all mb-6">{item?.name}</h5>
                    <Typography className="max-w-512 font-medium">
                      {item?.address?.length === 0 || item?.address2?.length === 0
                        ? ''
                        : 'Address - '}
                      {item?.address || item?.address2}
                    </Typography>
                  </div>
                </div>
                <Button
                  variant="outlined"
                  color="secondary"
                  type="button"
                  onClick={() => handleSelectBusiness(item?.id)}
                  className="rounded-full font-semibold text-16 xl:px-32 min-w-160 sm:mt-0 mt-16"
                >
                  {t('userBusinessList.selectBusiness')}
                </Button>
              </div>
            ))
          ) : (
            <Typography className="text-center font-bold text-16">
              {t('userBusinessList.noBusinessFound')}
            </Typography>
          )}
          <div className="sm:flex items-center justify-between sm:mt-32 mt-16">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              onClick={() => handleAddBusiness()}
              className="rounded-full font-semibold text-16 md:px-32"
            >
              <Icon>add</Icon>
              {t('agencyBusiness.addBusiness')}
            </Button>
            {businessList?.length > 0 && (
              <TablePagination
                sx={{
                  '.MuiInputBase-root': {
                    lineHeight: 'inherit',
                    padding: 0,
                  },
                }}
                component="div"
                count={businessListCount}
                page={page - 1}
                rowsPerPage={5}
                rowsPerPageOptions={[]}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onPageChange={handleChangePage}
              />
            )}
          </div>
        </div>
      </Popover>
      <Dialog
        classes={{
          paper: '-mt-12 p-28 rounded-md',
        }}
        open={openDialogue}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-16">
          <Icon
            onClick={() => setOpenDialogue(false)}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <DialogContent className="flex justify-center items-center">
            <div className="text-center">
              <img className="m-auto" src="assets/images/business/subscribe.svg" alt="" />
              <Typography className="text-18 font-bold text-black w-full py-12">
                {t('manageBusiness.subscribeToAddBusiness')}
              </Typography>
              <Typography className="text-14 font-semibold text-black max-w-288">
                {t('manageBusiness.subscribeToAddBusinessMsg')}
              </Typography>
              <Button className="bg-darkgreen hover:bg-darkgreen text-white rounded-md px-40 mt-16">
                {t('manageBusiness.button.selectProduct')}
              </Button>
            </div>
          </DialogContent>
        </DialogTitle>
      </Dialog>
      <CheckPlanForAddNewBusiness
        openSelectProductDialog={openSelectProductDialog}
        planAvailableDialog={planAvailableDialog}
        userAvailablePlan={userAvailablePlan}
        setOpenSelectProductDialog={setOpenSelectProductDialog}
        setPlanAvailableDialog={setPlanAvailableDialog}
        setUserAvailablePlan={setUserAvailablePlan}
      />
    </>
  );
}

export default UserBusiness;
